import React from 'react';
import { Box } from '@material-ui/core';
import { SearchBar } from './SearchBar';
import { SearchModal } from './SearchModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { MEDIA_QUERY_FIELD_ALWAYS_VISIBLE } from './GlobalSearch.constant';
import { useGlobalSearchController } from './GlobalSearch.controller';
import { useGlobalSearchStyles } from './GlobalSearch.style';

export const GlobalSearch = () => {
  const {
    history,
    onHistoryItemClick,
    showModal,
    inputText,
    inputTextLast,
    isOpenSearchTrayState,
    setInputTextLocal,
    openModal,
    closeModal,
    setIsOpenSearchTrayLocal,
    setHistory,
  } = useGlobalSearchController();
  const classes = useGlobalSearchStyles();
  const theme = useTheme();
  const lgDevice = useMediaQuery(theme.breakpoints.up(MEDIA_QUERY_FIELD_ALWAYS_VISIBLE));
  const mobileDevice = useMediaQuery('(max-width:750px)');

  const openSearchTrayAndFocus = isOpenSearchTrayState;
  const openSearchTray = lgDevice || mobileDevice;

  return (
    <Box className={classes.root} data-testid="global-search">
      <SearchBar
        inputText={inputText}
        setInputText={setInputTextLocal}
        openSearchTrayAndFocus={openSearchTrayAndFocus}
        openSearchTray={openSearchTray}
        setIsOpenSearchTray={setIsOpenSearchTrayLocal}
        openModal={openModal}
        showCloseIcon={showModal}
        history={history}
        onHistoryItemClick={onHistoryItemClick}
      />
      {!!showModal && (
        <SearchModal
          inputText={inputText}
          inputTextLast={inputTextLast}
          closeModal={closeModal}
          setHistory={setHistory}
        />
      )}
    </Box>
  );
};
