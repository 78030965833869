import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RoomIcon from '@material-ui/icons/Room';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

import { ShinyLink } from '../../../Shiny';
import { DATA_DOWNLOADS_PATH, OIL_AND_GAS_MAP_PATH } from '../../../../utils/constants';
import CurrentUserContext from '../../../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { isUserEntitled } = useContext(CurrentUserContext);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2} />
      <Grid container item spacing={2} xs={8}>
        <Grid item>
          <Button
            component={Link}
            to={OIL_AND_GAS_MAP_PATH}
            variant="contained"
            className={classes.button}
            underline="none"
            disabled={!isUserEntitled(OIL_AND_GAS_MAP_PATH)}
          >
            <RoomIcon fontSize="small" />
            &nbsp;oil and gas map
          </Button>
        </Grid>
        <Grid item>
          <ShinyLink
            startAdornment={<BubbleChartIcon fontSize="small" />}
            link={{ title: 'heat maps', page: 'map' }}
            styles={{ link: classes.button }}
            variant="contained"
            disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
