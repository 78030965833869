import React from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { useHistoryPopoverStyles } from './HistoryPopover.style';
import Typography from '@material-ui/core/Typography';

export const HistoryPopover = ({
  history,
  onHistoryItemClick,
  id,
  anchorEl,
  handleClose,
  open,
}) => {
  const classes = useHistoryPopoverStyles();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper className={classes.historyPopper} id={id} open={open} anchorEl={anchorEl}>
        <div>
          {history.map((item, index) => (
            <Typography
              variant="body1"
              key={index}
              className={classes.row}
              onClick={() => onHistoryItemClick(item)}
            >
              {item}
            </Typography>
          ))}
        </div>
      </Popper>
    </ClickAwayListener>
  );
};
