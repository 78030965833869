import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  BarGraphContextLeft,
  BarGraphContextRight,
  BarGraphProviderLeft,
  BarGraphProviderRight,
} from './BarGraphs';
import TreemapContext, { TreemapProvider } from './Treemap/TreemapContext';
import { LineGraphs, MonetaryLineGraphProvider, ProductionLineGraphProvider } from './LineGraphs';
import BarGraph from '../../Portfolio/Charts/BarGraph/barGraph';
import Treemap from '../../Portfolio/Charts/Treemap';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.primaryBackground,
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

export default ({ portfolios, consolidateAssetsProps }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={1} columns={{ xs: 2 }} justifyContent="space-evenly">
      <Grid id="portfolio-analysis-treemap" item xs={3}>
        <TreemapProvider portfolios={portfolios}>
          <Treemap context={TreemapContext} />
        </TreemapProvider>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <ProductionLineGraphProvider portfolios={portfolios}>
          <MonetaryLineGraphProvider portfolios={portfolios}>
            <LineGraphs portfolios={portfolios} consolidateAssetsProps={consolidateAssetsProps} />
          </MonetaryLineGraphProvider>
        </ProductionLineGraphProvider>
        <Grid id="portfolio-analysis-bar-graph" item xs={6}>
          <BarGraphProviderLeft portfolios={portfolios}>
            <BarGraph includeId context={BarGraphContextLeft} />
          </BarGraphProviderLeft>
        </Grid>
        <Grid item xs={6}>
          <BarGraphProviderRight portfolios={portfolios}>
            <BarGraph context={BarGraphContextRight} />
          </BarGraphProviderRight>
        </Grid>
      </Grid>
    </Grid>
  );
};
