import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.baseColors.gray.c100}`,
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
    '& > div': {
      width: '100%',
    },
  },
  buttonSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: '0.5rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '180px',
      minWidth: '58px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingBottom: '.5rem',
      rowGap: '1rem',
      minWidth: 'unset',
    },
    paddingRight: '13px',
  },
  clearButton: {
    ...theme.palette.altButton,
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  filterSection: {
    display: 'flex',
    flexGrow: '1',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
  sliderWrapper: {
    paddingRight: '1rem',
    paddingLeft: '1.5rem',
    flex: 1,
  },
  slider: {
    flexBasis: '16%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
  },
}));
