import { DealCountByTypeChart } from './components/DealCountByTypeChart';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { ValuationsChart } from './components/ValuationsChart';
import { useGetMaTransactionsSummary } from './hooks/useGetMaTransactionsSummary';
import { useStyles } from './styles';

const TransactionsSummarySkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.boxes}>
      {Array.from({ length: 5 }).map((_, i) => (
        <div className={classes.box} key={i}>
          <Skeleton
            animation="wave"
            variant="text"
            width={50}
            height={35}
            style={{ marginTop: '-5px' }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width={200}
            height={15}
            style={{ marginTop: '-5px' }}
          />
        </div>
      ))}
    </div>
  );
};

const TransactionsSummary = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetMaTransactionsSummary();

  if (error) return null;

  if (loading) return <TransactionsSummarySkeleton />;

  const boxes = [
    { value: data.numDeals, name: 'Number of Events' },
    { value: Math.round(data.medianValuation), name: 'Estimated Median Valuation (US$MM)' },
    { value: Math.round(data.medianReserves), name: 'Estimated Median Reserves (MMboe)' },
    {
      value: Math.round(data.medianReservesAcquisitionCost),
      name: 'Estimated Median Reserves Acquisition Cost ($/boe)',
    },
    {
      value: Math.round(data.medianProductionAcquisitionCost),
      name: 'Estimated Median Production Acquisition Cost ($/boe)',
    },
  ];

  return (
    <div className={classes.boxes}>
      {boxes.map(({ name, value }) => (
        <div className={classes.box} key={name}>
          <div className={classes.boxValue}>
            {new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(value)}
          </div>
          <div className={classes.boxName}>{name}</div>
        </div>
      ))}
    </div>
  );
};

export const Overview = () => {
  const classes = useStyles();

  return (
    <>
      <TransactionsSummary />
      <div className={classes.graphWrapper}>
        <ValuationsChart />
        <DealCountByTypeChart />
      </div>
    </>
  );
};
