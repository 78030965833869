import React, { useState } from 'react';
import { Slider as SliderMui, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginBottom: 0,
  },
  slider: {
    color: theme.palette.text.primary,
    '& .MuiSlider-valueLabel': {
      '& > *': {
        '& > *': {
          color: theme.palette.baseColors.offWhite.c100,
        },
      },
    },
  },
}));

export const Slider = ({
  className,
  classNameLabel,
  value: valueProps,
  onChange: onChangeProps,
  label,
  width = '193px',
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(valueProps);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography id={props.id} gutterBottom className={clsx(classes.label, classNameLabel)}>
        {label}
      </Typography>
      <SliderMui
        className={clsx(classes.slider, className)}
        sx={{ width }}
        value={value}
        onChange={handleChange}
        onChangeCommitted={onChangeProps}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        {...props}
      />
    </>
  );
};
