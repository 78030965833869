import React from 'react';
import { useLazyQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';

import { SHINY_URL } from './operations';
import { SHINY_APPS_DEACTIVATED_IN_ENV } from '../../utils/constants';
import clsx from 'clsx';

export default ({
  startAdornment,
  endAdornment,
  link,
  styles,
  component,
  variant,
  disabled,
  onClickCallback = () => {},
  className,
}) => {
  const [getShinyUrl, { data: shinyData }] = useLazyQuery(SHINY_URL, {
    onCompleted: () => {
      window.open(shinyData.shinyUrl.url, '_blank');
      onClickCallback();
    },
    fetchPolicy: 'network-only',
  });

  if (component === 'link') {
    return disabled || SHINY_APPS_DEACTIVATED_IN_ENV ? (
      <div className={styles.link} style={{ color: 'dimgrey', pointerEvents: 'none' }}>
        {link.title}
      </div>
    ) : (
      <div
        className={styles.link}
        onClick={() => {
          getShinyUrl({
            variables: { page: link.page },
          });
        }}
      >
        {link.title}
        {endAdornment ? <>&nbsp;{endAdornment}</> : null}
      </div>
    );
  }
  return (
    <Button
      key={link.title}
      color="inherit"
      underline="none"
      className={clsx(styles.link, className)}
      variant={variant ? variant : 'text'}
      disabled={disabled || SHINY_APPS_DEACTIVATED_IN_ENV}
      onClick={(e) => {
        onClickCallback(e);
        getShinyUrl({
          variables: { page: link.page },
        });
      }}
    >
      {startAdornment ? <>{startAdornment}&nbsp;</> : null}
      {link.title}
      {endAdornment ? <>&nbsp;{endAdornment}</> : null}
    </Button>
  );
};
