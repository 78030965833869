import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete } from '../../AutoComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
  },
  label: {
    ...theme.palette.secondaryAccentText,
    margin: '-3px',
  },
}));

export default ({ displayedItem, loading, data, onChange }) => {
  const classes = useStyles();
  const pipelines = data?.pipelineNetwork?.pipelines;

  /**
   * This componenet should only be rendered when:
   * - there is an item object to be displayed
   * - data is not loading
   * - data exists
   * - a list of pipelines exists
   * - count of dropdown options includes more than the item to be displayed
   */
  if (!displayedItem || loading || !data || !pipelines || !(pipelines.length > 1)) {
    return null;
  }

  // const menuItems = [{ name: '' }, ...pipelines];
  // This component should be showing for a pipeline network, so the displayed item should never be in the list of menu items
  // const pipeline = menuItems.find((pipeline) => pipeline.name === displayedItem.name);
  return (
    <div className={classes.root}>
      <AutoComplete
        getData={null}
        menuItems={pipelines}
        onChange={onChange}
        value={null}
        label="Pipelines"
        optionKey="name"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={false}
      />
    </div>
  );
};
