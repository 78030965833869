import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  download: {
    maxWidth: 'fit-content',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',
  },
  table: {
    '&.MuiDataGrid-root': {
      marginBottom: theme.spacing(2),
      borderTopLeftRadius: 0,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle':
      {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1,
      },
    '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
      backgroundColor: theme.palette.baseColors.iceBlue.c50,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.baseColors.skyBlue.c25,
    },
    '&.MuiDataGrid-root .MuiDataGrid-overlay .MuiCircularProgress-root': {
      color: theme.palette.accentText.color,
    },
  },
}));
