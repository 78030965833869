import { makeStyles } from '@material-ui/core/styles';

export const useGlobalSearchStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    margin: '0 8px',
  },
}));
