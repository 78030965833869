import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import React, { useEffect, useState } from 'react';
import { analytics, dimensions } from '../../utils/analytics';
import {
  defaultCheckBoxFilterTypes,
  defaultCheckListFilterTypes,
  defaultFilterTypes,
  defaultMinMaxFilterTypes,
} from './filterTypes';

import Button from '@material-ui/core/Button';
import CheckBoxItem from './checkBoxItem';
import CheckListGroup from './checkListGroup';
import CheckListItem from './checkListItem';
import Divider from '@material-ui/core/Divider';
import FilterItem from './filterItem';
import Grid from '@material-ui/core/Grid';
import InputList from '../InputList';
import Modal from '../Modals';
import SliderItem from './sliderItem';
import Typography from '@material-ui/core/Typography';
import { getUserId } from '../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';
import { preparePropVariables } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  button: {
    ...theme.palette.altButton,
  },
  editButton: {
    ...theme.palette.altButton,
    height: '28px',
    width: '50px',
    marginLeft: '5px',
  },
  apply: {
    ...theme.palette.button,
    height: '100%',
    width: '128px',
    marginRight: '10px',
  },
  buttonsContainer: {
    flex: '0 1 auto',
    padding: '10px 20px',
    borderTop: 'solid rgba(0, 0, 0, 0.12) thin',
  },
  filtersRoot: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) thin',
    height: 'calc(100% - 100px)',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 73.6px)',
    },
  },
  modal: {
    backgroundColor: 'white',
    height: '90vh',
    width: '90%',
    maxHeight: '1100px',
    maxWidth: '1400px',
  },
  chipItemContainer: {
    minHeight: '55px',
    padding: '10px 20px',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  topFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px',
    height: '40%',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  bottomFilters: {
    padding: '0 10px',
    backgroundColor: '#F5F5F5',
    height: '60%',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  checkListContainer: {
    height: '100%',
  },
  minMaxContainer: {
    ...theme.palette.altScrollbarBlue,
    overflow: 'auto',
    height: '100%',
    padding: '5px 15px 0',
    flexWrap: 'nowrap',
  },
  sliderContainer: {
    height: '100%',
    padding: '5px',
  },
  sliderScroll: {
    ...theme.palette.altScrollbarBlue,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 15px',
    overflow: 'auto',
    flexWrap: 'nowrap',
    width: '97%',
    height: 'calc(100% - 30.25px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 27.6px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 24.94px)',
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  consolidateAssetsButton: {
    ...theme.palette.button,
    minWidth: '128px',
  },
  filterItem: {
    flexBasis: '20%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      flexBasis: '33.33%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
}));

export default ({
  apply,
  checkBoxFilterTypes = defaultCheckBoxFilterTypes,
  checkListFilterTypes = defaultCheckListFilterTypes,
  checkListItems,
  clearFilters,
  context,
  defaultFilters,
  filterItemClass,
  filterTypes = defaultFilterTypes,
  googleAnalyticsTag,
  minMaxFilterTypes = defaultMinMaxFilterTypes,
  open,
  setOpen,
  sliderFiltersLabel = '',
  sliderFilterTypes = [],
  sliderFilterMaxValues = {},
  sliderFilterLoading,
  title,
  widths = {
    slider: {
      xs: 3,
    },
    checkList: {
      xs: 8,
    },
    minMax: {
      xs: 4,
    },
  },
}) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(defaultFilters);
  const propVariables = preparePropVariables(filters);

  const setFilter = (filter, name) => {
    const newFilters = {
      ...filters,
    };
    newFilters[name] = filter;
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [name]: newFilters[name] }),
      userId: getUserId(),
      ...dimensions(newFilters, context),
    });
    setFilters(newFilters);
  };

  const deleteFilter = (filterToDelete) => {
    const newFilters = { ...filters };
    const { parent, value } = filterToDelete;
    newFilters[parent] = filters[parent].filter((val) => val.name !== value.name);
    setFilters(newFilters);
  };

  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  return (
    <Modal
      handleClose={() => setOpen(false)}
      open={open}
      title={title}
      titleAlignedWithClose={true}
      styles={classes}
    >
      <Grid container className={classes.filtersRoot} direction="column">
        <Divider />
        <div className={classes.filtersContainer}>
          <div className={classes.topFilters}>
            {filterTypes.map((filter) => (
              <FilterItem
                key={`${context}-modal-${filter.name}`}
                context={`${context}-modal`}
                filter={filter}
                setFilter={setFilter}
                propVariables={
                  filter.propVariables && typeof filter.propVariables === 'function'
                    ? filter.propVariables(filters, context)
                    : propVariables
                }
                withChipList
                value={filters[filter.key]}
                deleteFilter={deleteFilter}
                disabled={filter.disabled ? filter.disabled(filters.assets) : false}
                controlValues={
                  filter.control?.getControlValuesFromFilters
                    ? filter.control.getControlValuesFromFilters(filters)
                    : {}
                }
                inModal
                className={filterItemClass || classes.filterItem}
              />
            ))}
          </div>
          <Grid className={classes.bottomFilters} container item direction="row">
            {sliderFilterTypes.length > 0 && (
              <Grid
                className={classes.sliderContainer}
                container
                item
                xs={widths.slider.xs}
                direction="column"
              >
                {sliderFiltersLabel && (
                  <Typography
                    variant="overline"
                    className={classes.subHeader}
                    style={{ padding: '0 15px' }}
                  >
                    {sliderFiltersLabel}
                  </Typography>
                )}
                <Grid className={classes.sliderScroll} item>
                  {sliderFilterTypes.map((filter) => (
                    <SliderItem
                      key={`${context}-modal-${filter.label}`}
                      item={filter}
                      loading={sliderFilterLoading}
                      onChange={setFilter}
                      value={filters[filter.key]}
                      maxValue={sliderFilterMaxValues[filter.maxKey]}
                    />
                  ))}
                </Grid>
              </Grid>
            )}
            {checkListFilterTypes.length > 0 && (
              <Grid
                className={classes.checkListContainer}
                container
                item
                xs={widths.checkList.xs}
                justifyContent="space-evenly"
                direction="column"
              >
                {checkListFilterTypes.map((filter) =>
                  filter.group ? (
                    <CheckListGroup
                      key={filter.name}
                      checkListItems={checkListItems}
                      filter={filter}
                      filters={filters}
                      setFilter={setFilter}
                    />
                  ) : (
                    <CheckListItem
                      key={filter.name}
                      checkListItems={checkListItems}
                      filter={filter}
                      filters={filters}
                      setFilter={setFilter}
                    />
                  ),
                )}
              </Grid>
            )}
            {(minMaxFilterTypes.length > 0 || checkBoxFilterTypes.length > 0) && (
              <Grid
                className={classes.minMaxContainer}
                container
                item
                xs={widths.minMax.xs}
                direction="column"
              >
                <InputList values={filters} setInput={setFilter} items={minMaxFilterTypes} />
                <Divider style={{ marginTop: '10px' }} />
                {checkBoxFilterTypes.map((filter) => (
                  <CheckBoxItem
                    key={`${context}-modal-${filter.label}`}
                    item={filter}
                    onClick={setFilter}
                    value={filters[filter.key]}
                  />
                ))}
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
      <Grid container className={classes.buttonsContainer}>
        <Grid item xs={2} md={3} />
        <Grid container item xs={8} md={6} justifyContent="center">
          <Button
            variant="contained"
            className={classes.apply}
            onClick={() => {
              apply(filters);
              setOpen(false);
            }}
          >
            Apply
          </Button>
        </Grid>
        <Grid container item xs={2} md={3} justifyContent="flex-end">
          <Button variant="contained" className={classes.button} onClick={clearFilters}>
            Clear
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
