import { gql } from '@apollo/client';

const ASSET_QUERY = gql`
  query asset($legacyId: ID!, $countryIsoCode: String!) {
    asset(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      activeWellCount
      baseName
      currentDate
      developmentWellCount
      displayName
      estimatedCurrentDate
      firstProductionDate
      fiscalRegime
      formattedAcreage
      formattedApi
      formattedNpv
      formattedEstimatedRecentProdGasMmcfd
      formattedEstimatedRecentProdLiqBbld
      formattedRecentProdLiquidsBbld
      formattedRecentProdGasMmcfd
      formattedVerticalDepth
      formattedWaterDepth
      formattedWeaProdLiquidsBbld
      formattedWeaProdGasMmcfd
      formattedXFactor
      geom
      hydrocarbonType
      isHpht
      licenseAwardDate
      licenseExpirationDate
      licensingRound
      name
      pipelineNetwork
      pipelineNetworkSegment
      remainingCapex
      reportUpdatedDate
      status
      transportationMethod
      truckingDestination
      updatedAt
      verticalDepthM
      waterDepthM
      country {
        id
        name
        displayName
        isoCode
        haveLiquidsReserves
      }
      basin {
        name
      }
      geologies {
        formation {
          name
        }
        age {
          name
        }
        period {
          name
        }
      }
      participations {
        id
        effectiveDate
        formattedPercentStake
        percentStake
        isCompanyOperator
        company {
          name
        }
      }
      reserve {
        formattedTotalRemainingReservesLiquidsMmbbl
        formattedTotalRemainingReservesOilMmbbl
        formattedTotalRemainingReservesGasBcf
      }
      shoreStatus {
        name
      }
      assetModels {
        id
        legacyId
        displayName
        name
        geom
        country {
          id
          isoCode
        }
      }
      relatedExplorations {
        assets {
          id
          legacyId
          displayName
          name
          geom
          country {
            id
            isoCode
          }
          type
        }
        blocks {
          id
          legacyId
          displayName
          name
          geom
          country {
            id
            isoCode
          }
          type
        }
      }
      pipelineNetworks {
        id
        name
        type
      }
      assetNpvs {
        id
        formattedPercent
        formattedValue
      }
    }
  }
`;

const BLOCK_QUERY = gql`
  query block($legacyId: ID!, $countryIsoCode: String!) {
    block(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      commitedWellsDrilled
      formattedAcreage
      formattedGovernmentProfitSharePct
      formattedRoyaltyRatePct
      formattedSignatureBonus
      formattedVerticalDepth
      formattedWaterDepth
      formattedXFactor
      geom
      licenseAwardDate
      licenseExpirationDate
      displayName
      name
      verticalDepthM
      waterDepthM
      wellCommitmentCount
      basin {
        name
      }
      blockStatus {
        name
      }
      country {
        id
        displayName
        isoCode
      }
      fiscalRegime {
        name
      }
      licensingRound {
        name
      }
      participations {
        id
        effectiveDate
        formattedPercentStake
        percentStake
        isCompanyOperator
        company {
          name
        }
        eventType
      }
      shoreStatus {
        name
      }
      relatedExplorations {
        assets {
          id
          legacyId
          displayName
          name
          geom
          country {
            id
            isoCode
          }
          type
        }
        blocks {
          id
          legacyId
          displayName
          name
          geom
          country {
            id
            isoCode
          }
          type
        }
      }
    }
  }
`;

const FACILITY_QUERY = gql`
  query facility($legacyId: ID!, $countryIsoCode: String!) {
    facility(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      alternativeTypeName
      contractType
      crudeStorageBbl
      derivedCapacity
      commonFacilityProperties
      formattedGasCapacity
      formattedGasThroughputRecentMmcfd
      formattedLiquidsThroughputRecentBbld
      formattedOilCapacity
      formattedOilThroughputRecentBbld
      formattedPowerPlantCapacityMw
      formattedWaterDepth
      formattedWaterInjectionCapacity
      formattedWaterThroughputRecentBbld
      gasCapacity
      gasThroughputRecentMmcfd
      installDate
      liquidsThroughputRecentBbld
      name
      oilCapacity
      shoreStatus
      startUp
      status
      throughputRecentDate
      typeName
      waterDepth
      waterTreatingProcessingCapacity
      assets {
        id
        legacyId
        activeWellCount
        baseName
        currentDate
        developmentWellCount
        displayName
        firstProductionDate
        fiscalRegime
        formattedAcreage
        formattedApi
        formattedNpv
        formattedRecentProdLiquidsBbld
        formattedRecentProdGasMmcfd
        formattedVerticalDepth
        formattedWaterDepth
        formattedWeaProdLiquidsBbld
        formattedWeaProdGasMmcfd
        formattedXFactor
        geom
        hydrocarbonType
        isHpht
        licenseAwardDate
        licenseExpirationDate
        licensingRound
        name
        pipelineNetwork
        pipelineNetworkSegment
        remainingCapex
        reportUpdatedDate
        status
        transportationMethod
        truckingDestination
        updatedAt
        verticalDepthM
        waterDepthM
        geologies {
          formation {
            name
          }
          age {
            name
          }
          period {
            name
          }
        }
        participations {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        reserve {
          formattedTotalRemainingReservesLiquidsMmbbl
          formattedTotalRemainingReservesOilMmbbl
          formattedTotalRemainingReservesGasBcf
        }
        shoreStatus {
          name
        }
        pipelineNetworks {
          id
          name
          type
        }
        assetNpvs {
          id
          formattedPercent
          formattedValue
        }
      }
      basin {
        id
        name
      }
      company {
        id
        name
      }
      country {
        id
        displayName
      }
    }
  }
`;

const EA_WELL_QUERY = gql`
  query eaWell($id: ID!) {
    eaWell(id: $id) {
      id
      name
      drillStart
      estimatedCoordinates
      expectedSpudQuarter
      shoreStatus
      formattedShoreStatus
      wellType
      formattedWellType
      fieldName
      prospect
      blockName
      leaseName
      basinName
      geologicalTarget
      isCommitment
      prospectSize
      formattedProspectSize
      impact
      formattedImpact
      hydrocarbonTarget
      formattedHydrocarbonTarget
      rigName
      waterDepthM
      targetDepthM
      geom
      formattedEstimatedCost
      hostFacilityName
      region
      country {
        id
        name
        displayName
      }
      flexParticipations {
        id
        companyName
        percentStake
        formattedPercentStake
        isCompanyOperator
        comment
      }
    }
  }
`;

const FIELD_QUERY = gql`
  query field($legacyId: ID!, $countryIsoCode: String!) {
    field(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      displayName
      discoveryDate
      firstProductionDate
      formattedWaterDepth
      hydrocarbonType
      licensingRound
      name
      productionCessationDate
      projectSanctionDate
      waterDepthM
      basin {
        name
      }
      country {
        id
        displayName
      }
      fieldStatus {
        name
      }
      developmentStatus {
        name
      }
      developmentType {
        name
      }
      resourceTheme {
        name
      }
      shoreStatus {
        name
      }
      assets {
        displayName
      }
      blocks {
        displayName
      }
      participations {
        id
        effectiveDate
        formattedPercentStake
        percentStake
        isCompanyOperator
        company {
          name
        }
      }
    }
  }
`;

const FORMATION_QUERY = gql`
  query formation($legacyId: ID!, $countryIsoCode: String!) {
    formation(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      name
      country {
        id
        displayName
      }
    }
  }
`;

const LEASE_QUERY = gql`
  query lease($legacyId: ID!, $countryIsoCode: String!) {
    lease(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      areaCode
      blockNumber
      currentParticipationDates
      effectiveDate
      expirationDate
      formattedBidAmountMmusd
      formattedLiabilityMmusd
      formattedRecentProductionGasMmcfd
      formattedRecentProductionLiquidsBbld
      formattedWaterDepth
      geom
      displayName
      name
      groupedParticipations {
        A {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        B {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        C {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        D {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        E {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        O {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        S {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        noGroup {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
      }
      primaryTermYears
      recentProductionDate
      waterDepthM
      leaseStatus {
        name
      }
    }
  }
`;

const LNG_PROJECT_QUERY = gql`
  query lngProject($id: ID!) {
    lngProject(id: $id) {
      id
      name
      lngType
      lngStatus
      lngShoreStatus
      waterDepthM
      startDate
      storageCapacityM3
      formattedStorageCapacityM3
      numberOfStorageTanks
      country {
        id
        displayName
      }
      lngLiquefaction {
        id
        capacityMmtpa
        numberOfTrains
        lngTrains {
          id
          name
          startDate
          costMmusd
          nominalLiquefactionCapacity
          feedGasSupplyAssets
          fetchedFeedGasSupplyAssets {
            id
            legacyId
            displayName
            name
            country {
              id
              isoCode
            }
            type
          }
          status
          lngLiquefactionTechnology {
            name
          }
          flexParticipations {
            id
            companyName
            percentStake
            formattedPercentStake
            isCompanyOperator
            comment
          }
        }
      }
      lngRegas {
        id
        costMmusd
        nominalSendOutCapacity
        flexParticipations {
          id
          companyName
          percentStake
          formattedPercentStake
          isCompanyOperator
          comment
        }
      }
    }
  }
`;

const NOOP_QUERY = gql`
  query noop($id: ID!) {
    noop(id: $id) {
      message
    }
  }
`;

const PIPELINE_QUERY = gql`
  query pipeline($legacyId: ID!, $countryIsoCode: String!) {
    pipeline(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      destination
      formattedCost
      formattedDiameterInches
      formattedGasCapacityMmcfd
      formattedLengthKm
      formattedLiquidsCapacityBbld
      geom
      name
      network
      operator
      origin
      participation
      segment
      sponsor
      startDate
      country {
        id
        displayName
      }
      pipelineNetworks {
        id
        name
        type
      }
      product {
        name
      }
      shoreStatus {
        name
      }
      status {
        name
      }
    }
  }
`;

const PIPELINE_NETWORK_QUERY = gql`
  query pipelineNetwork($id: ID!) {
    pipelineNetwork(id: $id) {
      id
      name
      aggregatedGeojson
      formattedCapacity
      formattedDiameterInches
      formattedHydrocarbon
      formattedStatus
      geom
      hasThroughputs
      operator
      type
      pipelines {
        id
        legacyId
        name
        geom
        type
        country {
          id
          isoCode
        }
        isDisabled
      }
      assets {
        id
        legacyId
        displayName
        name
        geom
        type
        country {
          id
          isoCode
        }
        isDisabled
      }
    }
  }
`;

const PIPELINE_SEGMENT_QUERY = gql`
  query pipelineSegment($legacyId: ID!, $countryIsoCode: String!) {
    pipelineSegment(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      destination
      hasThroughputs
      hydrocarbon
      mostRecentPdp
      mostRecentProductionDate
      origin
      assets {
        id
        displayName
      }
      pipeline {
        id
        name
        formattedDiameterInches
        operator
        status {
          name
        }
      }
    }
  }
`;

const PRESALT_POLYGON_QUERY = gql`
  query presaltPolygon($legacyId: ID!, $countryIsoCode: String!) {
    presaltPolygon(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      name
      country {
        id
        displayName
      }
    }
  }
`;

const RESTRICTED_AREA_QUERY = gql`
  query restrictedArea($legacyId: ID!, $countryIsoCode: String!) {
    restrictedArea(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      formattedAcreage
      formattedVerticalDepth
      name
      verticalDepthM
      country {
        id
        displayName
      }
      fiscalRegime {
        name
      }
      shoreStatus {
        name
      }
    }
  }
`;

const WELLS_QUERY = gql`
  query well($legacyId: ID!, $countryIsoCode: String!) {
    well(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      id
      legacyId
      cnhDataAvailability
      depthMeasure
      depthVertical
      displayName
      drillDays
      drillingStartDate
      drillingEndDate
      drillingRig
      drillingYear
      eurGasBcf
      eurLiquidsMmbbl
      formattedDepthMeasure
      formattedDepthVertical
      formattedEurGasBcf
      formattedEurLiquidsMmbbl
      formattedFracStageLateralLengthM
      formattedFracStageSandPumped
      formattedFracStageWaterInjected
      formattedGorScfBbl
      formattedIpGasMmcfd
      formattedIpLiquidsBbld
      formattedRecent3MonthWaterCutPct
      formattedRecentProductionGasMmcfd
      formattedRecentProductionLiquidsBbld
      formattedWaterBlade
      gorScfBbl
      ipGasMmcfd
      ipLiquidsBbld
      mostRecentFracStageCompletionType
      name
      operator
      prospect
      primaryHydrocarbon
      recent3MonthWaterCutPct
      recentProductionDate
      recentProductionGasMmcfd
      recentProductionLiquidsBbld
      waterBlade
      weaClassification
      wellFormation
      wellResult
      wellStatus
      asset {
        id
        legacyId
        activeWellCount
        baseName
        currentDate
        developmentWellCount
        displayName
        firstProductionDate
        fiscalRegime
        formattedAcreage
        formattedApi
        formattedNpv
        formattedRecentProdLiquidsBbld
        formattedRecentProdGasMmcfd
        formattedVerticalDepth
        formattedWaterDepth
        formattedWeaProdLiquidsBbld
        formattedWeaProdGasMmcfd
        formattedXFactor
        geom
        hydrocarbonType
        isHpht
        licenseAwardDate
        licenseExpirationDate
        licensingRound
        name
        pipelineNetwork
        pipelineNetworkSegment
        remainingCapex
        reportUpdatedDate
        status
        transportationMethod
        truckingDestination
        updatedAt
        verticalDepthM
        waterDepthM
        geologies {
          formation {
            name
          }
          age {
            name
          }
          period {
            name
          }
        }
        participations {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        reserve {
          formattedTotalRemainingReservesLiquidsMmbbl
          formattedTotalRemainingReservesOilMmbbl
          formattedTotalRemainingReservesGasBcf
        }
        shoreStatus {
          name
        }
        pipelineNetworks {
          id
          name
          type
        }
        assetNpvs {
          id
          formattedPercent
          formattedValue
        }
      }
      basin {
        id
        name
      }
      block {
        id
        legacyId
        commitedWellsDrilled
        formattedAcreage
        formattedGovernmentProfitSharePct
        formattedRoyaltyRatePct
        formattedSignatureBonus
        formattedVerticalDepth
        formattedWaterDepth
        formattedXFactor
        geom
        licenseAwardDate
        licenseExpirationDate
        displayName
        name
        verticalDepthM
        waterDepthM
        wellCommitmentCount
        blockStatus {
          name
        }
        fiscalRegime {
          name
        }
        licensingRound {
          name
        }
        participations {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        shoreStatus {
          name
        }
      }
      country {
        id
        displayName
        isoCode
      }
      facility {
        id
        legacyId
        alternativeTypeName
        contractType
        crudeStorageBbl
        derivedCapacity
        commonFacilityProperties
        formattedGasCapacity
        formattedGasThroughputRecentMmcfd
        formattedLiquidsThroughputRecentBbld
        formattedOilCapacity
        formattedOilThroughputRecentBbld
        formattedPowerPlantCapacityMw
        formattedWaterDepth
        formattedWaterInjectionCapacity
        formattedWaterThroughputRecentBbld
        gasCapacity
        gasThroughputRecentMmcfd
        installDate
        liquidsThroughputRecentBbld
        name
        oilCapacity
        shoreStatus
        startUp
        status
        throughputRecentDate
        typeName
        waterDepth
        waterTreatingProcessingCapacity
      }
      field {
        id
        legacyId
        displayName
        discoveryDate
        firstProductionDate
        hydrocarbonType
        licensingRound
        name
        productionCessationDate
        projectSanctionDate
        waterDepthM
        fieldStatus {
          name
        }
        developmentStatus {
          name
        }
        developmentType {
          name
        }
        resourceTheme {
          name
        }
        shoreStatus {
          name
        }
        participations {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
      }
      fracStages {
        id
      }
      shoreStatus {
        name
      }
      wellDesign {
        name
      }
    }
  }
`;

export const getQuery = (entityType) => {
  switch (entityType) {
    case 'asset':
      return ASSET_QUERY;
    case 'block':
      return BLOCK_QUERY;
    case 'ea_well':
      return EA_WELL_QUERY;
    case 'facility':
      return FACILITY_QUERY;
    case 'field':
      return FIELD_QUERY;
    case 'formation':
      return FORMATION_QUERY;
    case 'lease':
      return LEASE_QUERY;
    case 'lng_project':
      return LNG_PROJECT_QUERY;
    case 'pipeline':
      return PIPELINE_QUERY;
    case 'pipeline_network':
      return PIPELINE_NETWORK_QUERY;
    case 'pipeline_segment':
      return PIPELINE_SEGMENT_QUERY;
    case 'presalt_polygon':
      return PRESALT_POLYGON_QUERY;
    case 'restricted_area':
      return RESTRICTED_AREA_QUERY;
    case 'well':
      return WELLS_QUERY;
    default:
      return NOOP_QUERY;
  }
};

export const SECURE_REPORT_DOWNLOAD_QUERY = gql`
  query secureReportDownload($assetLegacyId: ID!, $isoCode: String!, $reportType: String!) {
    secureReportDownload(
      assetLegacyId: $assetLegacyId
      isoCode: $isoCode
      reportType: $reportType
    ) {
      url
      filename
      downloadMessage
    }
  }
`;
