import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  notes: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.baseColors.cloudGray.c25,
    borderLeft: '1px solid ' + theme.palette.baseColors.cloudGray.c100,
    marginLeft: '3rem',
  },
  title: {
    fontWeight: 500,
    fontSize: theme.fontSizes.smaller,
    textAlign: 'left',
    paddingLeft: '1rem',
  },
  noteTitle: {
    fontSize: theme.fontSizes.smaller,
    fontWeight: 500,
  },
  noteBox: {
    paddingBottom: '1.5rem',
  },
  date: {
    color: theme.palette.baseColors.deepSeaNavy.c75,
    fontSize: theme.fontSizes.smallest,
    fontWeight: 500,
  },
  link: {
    fontSize: theme.fontSizes.smaller,
    color: theme.palette.baseColors.fireOrange.c100,
    '&:hover': {
      color: theme.palette.baseColors.fireOrange.c75,
    },
  },
  contentOpened: {
    height: '100%',
    overflow: 'hidden',
    width: '150px',
  },
  contentClosed: {
    height: '100%',
    overflow: 'hidden',
    width: '0',
  },
  button: {
    position: 'absolute',
    right: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  boxes: {
    overflowY: 'auto',
    height: 'calc(100% - 3.5rem)',
    paddingLeft: '1rem',
  },
}));
