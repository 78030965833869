import React, { Suspense } from 'react';

// Allowing this large component to be lazy loaded and built into separate bundle
const SignUp = React.lazy(() => import(/* webpackChunkName: "sign-up" */ '../SignUp')); // eslint-disable-line

export default () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SignUp />
    </Suspense>
  );
};
