import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 48px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 36px)',
    },
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '800px',
    maxWidth: '1400px',
  },
  button: {
    ...theme.palette.button,
    width: '128px',
  },
  controls: {
    ...theme.palette.altScrollbarBlue,
    height: '100%',
    overflow: 'auto',
    padding: '20px',
    backgroundColor: '#f4f4f4f4',
  },
  controlsContainer: {
    height: 'calc(100% - 54.8px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 48.8px)',
    },
  },
  stackByWrapper: {
    width: '100%',
  },
  warning: {
    lineHeight: 1.5,
  },
}));
