import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { FEATURE_NOT_AVAILABLE_TOOLTIP, M_A_OVERVIEW_PATH } from '../../../../utils/constants';
import CurrentUserContext from '../../../CurrentUserContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { isUserEntitled } = useContext(CurrentUserContext);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2} />
      <Grid container item spacing={2} xs={8}>
        <Grid item>
          <Tooltip
            title={
              isUserEntitled(M_A_OVERVIEW_PATH)
                ? 'Merger & Acquisitions Overview'
                : FEATURE_NOT_AVAILABLE_TOOLTIP
            }
          >
            <span>
              <Button
                component={Link}
                to={M_A_OVERVIEW_PATH}
                variant="contained"
                className={classes.button}
                underline="none"
                disabled={!isUserEntitled(M_A_OVERVIEW_PATH)}
              >
                <WorkOutlineIcon fontSize="small" />
                &nbsp;M&A Analytics
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() =>
              window.open(
                'https://welligence.com/intelligence/?_post_content=ma&_category=deep-dives',
                '_blank',
              )
            }
          >
            <LibraryBooksIcon fontSize="small" />
            &nbsp;m&a reports
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
