import React, { useCallback, useState, useMemo } from 'react';
import { useStyles } from './Notes.style';
import { SESSIONS_STORAGE_KEY_NOTES_VISIBLE_PREFERENCE } from '../../../../utils/constants';
import { getNotes } from '../../../WordPress';
import { formatDate } from '../../../../utils/helpers';
import { SidePageButton } from '../../../SidePageButton';

export const NoteBox = ({ dateString, title, url }) => {
  const classes = useStyles();

  const { decodedString, formattedDate } = useMemo(() => {
    const div = document.createElement('div');
    div.innerHTML = title;
    const decodedString = div.firstChild.nodeValue;
    const formattedDate = formatDate(dateString, false);

    return { decodedString, formattedDate };
  }, [dateString, title]);

  return (
    <div className={classes.noteBox}>
      <div className={classes.date}>{formattedDate}</div>
      <div className={classes.noteTitle}>{decodedString}</div>
      <a className={classes.link} href={url} target="_blank" rel="noreferrer">
        Read More
      </a>
    </div>
  );
};

let notes;

getNotes().then((response) => {
  notes = response;
});

export const Notes = () => {
  const [isOpen, setIsOpen] = useState(() => {
    const sessionPreference = sessionStorage.getItem(SESSIONS_STORAGE_KEY_NOTES_VISIBLE_PREFERENCE);
    return sessionPreference ? JSON.parse(sessionPreference) : true;
  });
  const classes = useStyles();

  const setIsOpenLocal = useCallback((v) => {
    setIsOpen(v);
    sessionStorage.setItem(SESSIONS_STORAGE_KEY_NOTES_VISIBLE_PREFERENCE, v);
  }, []);

  return (
    <div className={classes.notes}>
      <SidePageButton
        onClick={() => setIsOpenLocal(!isOpen)}
        isOpen={isOpen}
        label="HIDE NOTES"
        labelClosed="SHOW NOTES"
        className={classes.button}
        showIcon
      />
      <div className={isOpen ? classes.contentOpened : classes.contentClosed}>
        <h4 className={classes.title}>Latest Deal Notes</h4>
        <div className={classes.boxes}>
          {notes.map(({ date, title, link }) => (
            <NoteBox key={link} dateString={date} title={title.rendered} url={link} />
          ))}
        </div>
      </div>
    </div>
  );
};
