import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete } from '../AutoComplete';
import VerticalChipList from '../ChipList/verticalChipList';
import { AutoCompleteExcelLike } from '../AutoComplete';
import clsx from 'clsx';
import { identifyChipLabel } from './utils';

const useStyles = makeStyles(() => ({
  filterItemContainer: {
    height: '100%',
  },
  selects: {
    height: '36px',
    backgroundColor: '#fafafa',
    boxSizing: 'border-box',
    borderRadius: '5px',
    paddingLeft: 0,
  },
  selectLabel: {
    top: '-9px',
  },
}));

export default ({
  context,
  filter,
  setFilter,
  propVariables,
  withChipList,
  value = [],
  deleteFilter = () => {},
  disabled,
  controlValues = {},
  inModal,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, { [classes.filterItemContainer]: withChipList })}>
      {filter.excelLike ? (
        <AutoCompleteExcelLike
          getData={filter.query}
          menuItems={filter.menuItems}
          mapQueryData={filter.mapQueryData}
          groupOptionKey={filter.groupOptionKey}
          groupByName={filter.groupByName}
          onChange={(value) => setFilter(value, filter.key)}
          value={value}
          label={filter.name}
          optionKey={filter.optionKey}
          propVariables={propVariables}
          runQueryAfterOnChange={filter.runQueryAfterOnChange}
          queryVariable={filter.queryVariable}
          multiple={filter.multiple}
          styles={{ label: classes.selectLabel, input: classes.selects }}
          control={{
            ...filter.control,
            ...controlValues,
            onChange: (value, key) => setFilter(value, key),
          }}
          context={context}
          popoverHeightReduction={inModal ? '8vh' : undefined}
          inModal={inModal}
        />
      ) : (
        <AutoComplete
          getData={filter.query}
          menuItems={filter.menuItems}
          menuItemsCallback={filter.menuItemsCallback}
          onChange={(value) => setFilter(value, filter.key)}
          value={value}
          label={filter.name}
          optionKey={filter.optionKey}
          groupOptionKey={filter.groupOptionKey}
          searchKey={filter.searchKey}
          propVariables={propVariables}
          runQueryAfterOnChange={filter.runQueryAfterOnChange}
          queryVariable={filter.queryVariable}
          multiple={filter.multiple}
          styles={{
            label: classes.selectLabel,
            input: classes.selects,
          }}
          disabled={disabled}
          note={filter.note}
          responseCallback={filter.responseCallback}
          hideItem={filter.hideItem}
          control={{
            ...filter.control,
            ...controlValues,
            onChange: (value, key) => setFilter(value, key),
          }}
          context={context}
        />
      )}
      {withChipList && (
        <VerticalChipList
          chips={value.map((v) => ({
            parent: filter.key,
            label: identifyChipLabel(filter, v),
            value: v,
          }))}
          deleteChip={deleteFilter}
        />
      )}
    </div>
  );
};
