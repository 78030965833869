import Graph from '../graph';
import React, { useState, useCallback, useMemo } from 'react';
import { LegendInChart } from './LegendInChart';
import { Box } from '@material-ui/core';

export const GraphWithCustomLegend = ({
  data,
  layout,
  onInitialized = () => {},
  onUpdate = () => {},
  isInModal = false,
  ...props
}) => {
  const [legendItems, setLegendItems] = useState([]);
  const [hideTracesFromChart, setHideTracesFromChart] = useState({});
  const [doubleClickActivated, setDoubleClickActivated] = useState(false);

  const showCustomLegend = layout.showlegend;

  const toggleTrace = useCallback(
    (traceName, hideTrace, isDoubleClick) => {
      if (isDoubleClick) {
        setHideTracesFromChart(
          doubleClickActivated
            ? {}
            : legendItems.reduce((acc, item) => {
                acc[item.title] = item.title !== traceName;
                return acc;
              }, []),
        );
        setDoubleClickActivated(!doubleClickActivated);
      } else {
        const tracesHidden = Object.keys(hideTracesFromChart);
        const newTracesHidden = hideTrace
          ? [...tracesHidden, traceName]
          : tracesHidden.filter((t) => t !== traceName);

        const newTracesHiddenReduced = newTracesHidden.reduce((acc, t) => {
          acc[t] = true;
          return acc;
        }, {});
        setHideTracesFromChart(newTracesHiddenReduced);
      }
    },
    [hideTracesFromChart, doubleClickActivated, legendItems],
  );

  const legendItemsUpdated = useMemo(
    () =>
      legendItems.map((item) => ({
        ...item,
        isHiddenInChart: !!hideTracesFromChart[item.title],
      })),
    [legendItems, hideTracesFromChart],
  );

  const onGraphUpdate = useCallback(
    (figure, graphDiv) => {
      let shouldUpdate = false;
      const newLegend = graphDiv._fullData.map((data, idx) => {
        const currentItem = legendItems[idx];
        const newItem = {
          color: data.marker.color,
          title: data?.name,
          visible: data?.visible,
        };

        if (
          !currentItem ||
          currentItem.color !== newItem.color ||
          currentItem.title !== newItem.title ||
          currentItem.visible !== newItem.visible
        ) {
          shouldUpdate = true;
        }
        return newItem;
      });

      if (shouldUpdate) {
        setLegendItems(newLegend);
      }
    },
    [legendItems],
  );

  const onUpdateLocal = useCallback(
    (...args) => {
      if (layout.showlegend) {
        onGraphUpdate(...args);
        onUpdate && onUpdate();
      } else {
        onUpdate && onUpdate();
      }
    },
    [layout.showlegend, onGraphUpdate, onUpdate],
  );

  const onInitializedLocal = useCallback(
    (...args) => {
      if (layout.showlegend) {
        onGraphUpdate(...args);
        onInitialized && onInitialized();
      } else {
        onInitialized && onInitialized();
      }
    },
    [layout.showlegend, onGraphUpdate, onInitialized],
  );

  data = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        visible: hideTracesFromChart[d.name] ? 'legendonly' : true,
      })),
    [data, hideTracesFromChart],
  );

  return (
    <Box position="relative" width="100%" height="100%" display={isInModal ? 'flex' : undefined}>
      <Graph
        data={data}
        layout={{
          ...layout,
          showlegend: layout.showlegend && !showCustomLegend,
        }}
        {...props}
        onUpdate={onUpdateLocal}
        onInitialized={onInitializedLocal}
        isNarrow={isInModal && showCustomLegend}
        customLegend={showCustomLegend}
      />
      {showCustomLegend && legendItemsUpdated.length > 0 && (
        <LegendInChart
          legendItems={legendItemsUpdated}
          toggleTrace={toggleTrace}
          isNextToChart={isInModal}
          offsetFromRight={!!layout.yaxis2 && !isInModal}
        />
      )}
    </Box>
  );
};
