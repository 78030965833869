import React, { useCallback, useContext, useState } from 'react';

import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import SnackbarContext from '../../../SnackbarContext';
import { TABLE_COLUMNS } from './types';
import { useDownloadMaTransactions } from './hooks/useDownloadMaTransactions';
import { useGetMaTransactions } from './hooks/useGetMaTransactions';
import { useStyles } from './styles';

export const TransactionTable = () => {
  const classes = useStyles();
  const pageSizeOptions = [10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const { setSnack } = useContext(SnackbarContext);
  const [downloading, setDownloading] = useState(false);
  const [pageSize, setPageSize] = useState(pageSizeOptions[1]);
  const [sortModel, setSortModel] = useState([{ field: 'effectiveDate', sort: 'desc' }]);

  const { downloadMaTransactions } = useDownloadMaTransactions();
  const { data, totalCount, loading, error } = useGetMaTransactions(
    page,
    pageSize,
    sortModel[0]?.sort,
    sortModel[0]?.field,
  );

  const onDownload = useCallback(async () => {
    setDownloading(true);
    setSnack({
      open: true,
      message: 'Your download should begin shortly.',
      severity: 'success',
    });

    const { ok, errors } = await downloadMaTransactions();

    if (!ok) {
      setSnack({
        open: true,
        message: errors[0]?.message || "We're sorry, but something went wrong.",
        severity: 'error',
      });
    }
    setDownloading(false);
  }, [downloadMaTransactions, setDownloading, setSnack]);

  if (error) return null;

  return (
    <>
      <Button
        className={classes.download}
        variant="contained"
        onClick={onDownload}
        disabled={downloading}
      >
        Download Data
      </Button>
      <DataGrid
        className={classes.table}
        rows={data}
        rowCount={totalCount}
        columns={TABLE_COLUMNS}
        loading={loading}
        // TODO: once MUI is updated to v7, we need the use the new pagination model props
        pagination
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowsPerPageOptions={pageSizeOptions}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(sortModel) => setSortModel(sortModel)}
      />
    </>
  );
};
