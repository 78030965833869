import { makeStyles } from '@material-ui/core/styles';

export const useHistoryPopoverStyles = makeStyles((theme) => ({
  historyIcon: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: '1.8rem',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  historyPopper: {
    zIndex: 10000,
    padding: '.5rem',
    borderRadius: '5px',
    boxShadow: '0px 2px 5px 0px ' + theme.palette.baseColors.deepSeaNavy.c100,
    background: 'white',
  },
  row: {
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    fontSize: theme.fontSizes.base,
    '&:hover': {
      backgroundColor: '#e6ebee',
    },
  },
}));
