import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AutoComplete } from '../../AutoComplete';

const useStyles = makeStyles(() => ({
  countryOverviewSelectInput: {
    backgroundColor: '#fafafa',
    boxSizing: 'border-box',
    paddingLeft: 0,
    display: 'flex',
  },
  countryOverviewChips: {
    margin: '0',
  },
  select: {
    padding: '5px',
  },
  text: {
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

export default ({ filter, propVariables, setFilter, value, xs }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classes.select}
      xs={xs}
      justifyContent={xs === 7 ? 'flex-end' : 'flex-start'}
    >
      <div style={{ width: '300px' }}>
        <AutoComplete
          getData={filter.query}
          menuItems={filter.menuItems}
          onChange={(value) => setFilter(value, filter.name)}
          value={value}
          label={filter.label || filter.name}
          optionKey={filter.optionKey}
          propVariables={propVariables}
          runQueryAfterOnChange={filter.runQueryAfterOnChange}
          queryVariable={filter.queryVariable}
          multiple={filter.multiple}
          styles={{
            text: classes.text,
            input: clsx({
              [classes.countryOverviewSelectInput]: true,
            }),
            root: classes.countryOverviewChips,
          }}
          disableLimitTags
          responseCallback={filter.responseCallback}
        />
      </div>
    </Grid>
  );
};
