import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
  <SvgIcon {...props}>
    <path
      d="M 18.005903,1.6534992 C 17.835553,1.2169784 17.348836,1.1995175 17.145025,1.611593 16.202013,3.4938708 14.513715,4.6672388 12.585102,4.6672388 h -2.43358 c -3.2244947,0 -5.8405941,3.0032631 -5.8405941,6.7049602 0,0.244452 0.024337,0.478426 0.04563,0.715894 1.940781,-1.59592 4.7424404,-2.9508813 8.7152611,-2.9508813 0.267694,0 0.486716,0.2514361 0.486716,0.5587466 0,0.3073107 -0.219022,0.5587467 -0.486716,0.5587467 -7.6475276,0 -10.8902739,5.381428 -11.6081803,7.403392 -0.2007704,0.569224 0.036504,1.218767 0.5323459,1.452743 0.4988841,0.237466 1.0646916,-0.03841 1.2715459,-0.604144 0.04563,-0.125724 0.6357729,-1.672749 2.1871811,-3.163904 0.9856001,1.53306 2.859457,2.996279 5.3204154,2.695952 4.775903,-0.398106 8.137287,-5.315076 8.137287,-11.3355723 0,-1.7530676 -0.328536,-3.5689941 -0.906511,-5.0496725 z"
      id="Fill-1"
      fill="#ffffff"
      mask="url(#mask-4)"
    ></path>
  </SvgIcon>
);
