import React, { useState } from 'react';
import { ModalContentWithTabs } from '../ModalContentWithTabs';
import { LoadPriceDeck } from './components/LoadPriceDeck';
import { SavePriceDeck } from './components/SavePriceDeck';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import Modal from '../index';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

const useStylesModalWithButtonTrigger = makeStyles((theme) => ({
  altButton: {
    ...theme.palette.altButton,
  },
  button: {
    ...theme.palette.button,
    margin: '10px 0 20px',
  },
  modal: {
    height: 'fit-content',
    width: '500px',
  },
}));

export const ModalWithButtonTrigger = ({
  id,
  children,
  buttonTitle,
  handleOpen,
  handleClose,
  open,
  title,
  startIcon,
  styles = {},
}) => {
  const classes = useStylesModalWithButtonTrigger();

  return (
    <>
      <Button
        startIcon={startIcon}
        id={id}
        variant="contained"
        className={clsx({ [classes.altButton]: true, [styles.altButton]: !!styles.altButton })}
        onClick={handleOpen}
      >
        {buttonTitle}
      </Button>
      <Modal
        title={title}
        open={open}
        handleClose={handleClose}
        styles={styles ? { ...classes, ...styles } : classes}
      >
        {children}
      </Modal>
    </>
  );
};

const useStyles = makeStyles(() => ({
  altButton: {
    marginRight: '10px',
  },
}));

export const PriceDecks = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <ModalWithButtonTrigger
      id={props.id}
      startIcon={<SaveIcon />}
      buttonTitle="Save / Load"
      styles={classes}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    >
      <ModalContentWithTabs
        tabLabels={['Save Price Deck', 'Load Price Deck']}
        tabPanels={[
          <SavePriceDeck key="Save" priceDeck={props.priceDeck} handleClose={handleClose} />,
          <LoadPriceDeck
            key="Load"
            setPriceDecks={props.setPriceDecks}
            handleClose={handleClose}
          />,
        ]}
      />
    </ModalWithButtonTrigger>
  );
};
