import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from './button';

export default ({ list = [], title }) => {
  const ButtonListItemsContainer = () =>
    list.map((button, idx) => {
      return (
        <Button
          key={`button-list-button-${title}-${button.title}-${idx}`}
          header={button.header}
          Icon={button.Icon}
          onClickCallback={button.onClick}
          title={button.title}
        />
      );
    });

  return (
    <Grid container>
      <Typography variant="overline">{title}</Typography>
      <Grid container style={{ padding: '5px 0' }}>
        <ButtonListItemsContainer />
      </Grid>
    </Grid>
  );
};
