import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import DataDownloadInfo from '../DataDownloadInfo';
import DataDownloadPreview from '../DataDownloadPreview';
import DataDownloadSummary from '../DataDownloadSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    borderTop: `solid ${theme.palette.baseColors.iceBlue.c100} thin`,
  },
}));

export default ({ filters }) => {
  const classes = useStyles();

  const downloadType = filters?.downloadType?.name;

  return (
    <ErrorBoundary>
      {downloadType ? (
        <Grid className={classes.root} container>
          <DataDownloadSummary filters={filters} />
          <DataDownloadPreview filters={filters} />
        </Grid>
      ) : (
        <DataDownloadInfo />
      )}
    </ErrorBoundary>
  );
};
