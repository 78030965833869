import * as Sentry from '@sentry/react';

import {
  CCUS_PATH,
  COUNTRY_OVERVIEWS_PATH,
  CUSTOMER_PATH,
  DATA_DOWNLOADS_PATH,
  ENGINEER_DEVELOPMENT_FLAG,
  FORGOT_PASSWORD_PATH,
  GHG_PATH,
  IN_DEVELOPMENT_FLAG,
  M_A_BASIC_PATH,
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_TRANSACTION_TABLE_PATH,
  OIL_AND_GAS_MAP_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  RESERVES_DEFINITION_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  VALUATIONS_PATH,
  WEA_ASSUMPTIONS_PATH,
} from '../utils/constants';
import { ResetPassword, SendResetLink } from '../components/ForgotPassword';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { AppBar } from '../components/AppBar';
import AssetTemplate from '../components/Valuations/AssetTemplate';
import CCUS from '../components/CCUS';
import CountryOverviews from '../components/CountryOverviews';
import { CurrentUserProvider } from '../components/CurrentUserContext';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from '../components/Footer';
import GHG from '../components/GHG';
import GuidanceDocumentation from '../components/GuidanceDocumentation';
import { Integrations } from '@sentry/tracing';
import LandingPage from '../components/LandingPage';
import LazySignUp from '../components/LazySignUp';
import Login from '../components/Login';
import { MaPage } from '../components/M&A';
import { MaPage as MaPageNew } from '../components/M&A_NEW';
import { MuiThemeProvider } from '@material-ui/core/styles';
import OilAndGasMap from '../components/OilAndGasMap';
import PortfolioAnalysis from '../components/PortfolioAnalysis';
import ProtectedRoute from '../components/ProtectedRoute';
import React from 'react';
import Settings from '../components/Settings';
import ShinyDownloads from '../components/ShinyDownloads';
import { SnackbarProvider } from '../components/SnackbarContext';
import SubscriberDownload from '../components/SubscriberDownload';
import Valuations from '../components/Valuations';
import { WalkthroughProvider } from '../components/WalkthroughContext';
import { createClient } from '../utils/apollo';
import { render } from 'react-dom';
import theme from '../utils/theme';

if (
  process.env.APP_ENV === 'stg' ||
  process.env.APP_ENV === 'pre' ||
  process.env.APP_ENV === 'prd'
) {
  Sentry.init({
    dsn: 'https://bebe9ddb38fc4e56971bd65f3b76f5b3@o1101134.ingest.sentry.io/6126882',
    environment: process.env.APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

render(
  <ApolloProvider client={createClient()}>
    <MuiThemeProvider theme={theme}>
      <Router>
        <SnackbarProvider>
          <CurrentUserProvider>
            <WalkthroughProvider>
              <Switch>
                <Route exact path={SIGN_IN_PATH} component={Login} />
                <Route exact path={SIGN_UP_PATH} component={LazySignUp} />
                <Route exact path={FORGOT_PASSWORD_PATH} component={SendResetLink} />
                <Route exact path={RESET_PASSWORD_PATH} component={ResetPassword} />
                <ProtectedRoute exact path="/">
                  <ErrorBoundary>
                    <AppBar />
                    <LandingPage />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={CCUS_PATH}>
                  <ErrorBoundary>
                    <AppBar forceNewTab />
                    <CCUS />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={GHG_PATH}>
                  <ErrorBoundary>
                    <AppBar forceNewTab />
                    <GHG />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={PORTFOLIO_ANALYSIS_PATH}>
                  <ErrorBoundary>
                    <AppBar forceNewTab />
                    <PortfolioAnalysis />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={OIL_AND_GAS_MAP_PATH}>
                  <ErrorBoundary>
                    <AppBar forceNewTab />
                    <OilAndGasMap />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={`${OIL_AND_GAS_MAP_PATH}/:latLong`}>
                  <ErrorBoundary>
                    <AppBar />
                    <OilAndGasMap />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={`${OIL_AND_GAS_MAP_PATH}/:latLong/:zoom`}>
                  <ErrorBoundary>
                    <AppBar />
                    <OilAndGasMap />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={VALUATIONS_PATH}>
                  <ErrorBoundary>
                    <AppBar forceNewTab />
                    <Valuations />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={`${VALUATIONS_PATH}/:countryIsoCode/:legacyId`}>
                  <ErrorBoundary>
                    <AppBar />
                    <AssetTemplate />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={`${VALUATIONS_PATH}/:countryIsoCode/:legacyId/:report`}>
                  <ErrorBoundary>
                    <AppBar />
                    <AssetTemplate />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={COUNTRY_OVERVIEWS_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <CountryOverviews />
                  </ErrorBoundary>
                </ProtectedRoute>
                {ENGINEER_DEVELOPMENT_FLAG && (
                  <ProtectedRoute exact path={DATA_DOWNLOADS_PATH}>
                    <ErrorBoundary>
                      <AppBar />
                      <ShinyDownloads />
                    </ErrorBoundary>
                  </ProtectedRoute>
                )}
                <ProtectedRoute exact path={M_A_OVERVIEW_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <MaPage />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={M_A_TRANSACTION_TABLE_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <MaPage />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={M_A_TRANSACTION_ANALYTICS_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <MaPage />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={M_A_COMPANY_BENCH_MARKING_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <MaPage />
                  </ErrorBoundary>
                </ProtectedRoute>
                {IN_DEVELOPMENT_FLAG && (
                  <ProtectedRoute exact path={`${M_A_BASIC_PATH}/v2`}>
                    <ErrorBoundary>
                      <AppBar />
                      <MaPageNew />
                    </ErrorBoundary>
                  </ProtectedRoute>
                )}
                <ProtectedRoute exact path={`${CUSTOMER_PATH}/proprietary-downloads/:slug`}>
                  <ErrorBoundary>
                    <AppBar />
                    <SubscriberDownload />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={RESERVES_DEFINITION_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <GuidanceDocumentation />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute exact path={WEA_ASSUMPTIONS_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <GuidanceDocumentation />
                  </ErrorBoundary>
                </ProtectedRoute>
                {/* TODO #1059 waiting for Research team to provide this content */}
                {/* <ProtectedRoute exact path={PRICE_ASSUMPTIONS_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <GuidanceDocumentation />
                  </ErrorBoundary>
                </ProtectedRoute> */}
                <ProtectedRoute exact path={SETTINGS_PATH}>
                  <ErrorBoundary>
                    <AppBar />
                    <Settings />
                  </ErrorBoundary>
                </ProtectedRoute>
                <ProtectedRoute path="*">
                  <ErrorBoundary>
                    <AppBar />
                    <LandingPage />
                  </ErrorBoundary>
                </ProtectedRoute>
              </Switch>
            </WalkthroughProvider>
          </CurrentUserProvider>
        </SnackbarProvider>
        <Footer />
      </Router>
    </MuiThemeProvider>
  </ApolloProvider>,
  document.querySelector('#root'),
);
