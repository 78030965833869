import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PublicIcon from '@material-ui/icons/Public';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {
  CCUS_PATH,
  GHG_PATH,
  COUNTRY_OVERVIEWS_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  FEATURE_NOT_AVAILABLE_TOOLTIP,
} from '../../../../utils/constants';
import LeafIcon from '../../../Icons/LeafIcon';
import CurrentUserContext from '../../../CurrentUserContext';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { isUserEntitled } = useContext(CurrentUserContext);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2} />
      <Grid container item spacing={2} xs={8}>
        <Grid item>
          <Button
            id="landing-page-country-overviews-button"
            component={Link}
            to={COUNTRY_OVERVIEWS_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(COUNTRY_OVERVIEWS_PATH)}
          >
            <PublicIcon fontSize="small" />
            &nbsp;country overviews
          </Button>
        </Grid>
        <Grid item>
          <Button
            id="landing-page-portfolio-analysis-button"
            component={Link}
            to={PORTFOLIO_ANALYSIS_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(PORTFOLIO_ANALYSIS_PATH)}
          >
            <MenuBookIcon fontSize="small" />
            &nbsp;portfolio analysis
          </Button>
        </Grid>
        <Grid item>
          <Button
            id="landing-page-ghg-button"
            component={Link}
            to={GHG_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(GHG_PATH)}
          >
            <LeafIcon fontSize="small" />
            &nbsp;greenhouse gas analytics
          </Button>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              isUserEntitled(CCUS_PATH)
                ? 'Carbon capture, utilization, and storage (CCUS)'
                : FEATURE_NOT_AVAILABLE_TOOLTIP
            }
          >
            <span>
              <Button
                id="landing-page-ccus-button"
                component={Link}
                to={CCUS_PATH}
                color="inherit"
                underline="none"
                variant="contained"
                className={classes.button}
                disabled={!isUserEntitled(CCUS_PATH)}
              >
                <MenuBookIcon fontSize="small" />
                &nbsp;ccus
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
