import { makeStyles } from '@material-ui/core/styles';

export const useDropDownStyles = makeStyles((theme) => ({
  menu: {
    ...theme.appBarDropDown,
    boxShadow: '0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)',
  },
  menuCurrent: {
    borderTop: `solid ${theme.palette.baseColors.fireOrange.c100} 3px`,
  },
  selectedLink: {
    ...theme.palette.accentText,
    ...theme.menuButtonFontStyles,
  },
  menuButton: {
    ...theme.menuButtonFontStyles,
    '&:hover': {
      backgroundColor: theme.palette.baseColors.iceBlue.c25,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  openedLink: {
    backgroundColor: theme.palette.baseColors.iceBlue.c25,
  },
  circle: {
    backgroundColor: theme.palette.baseColors.deepSeaNavy.c100,
    color: theme.palette.baseColors.deepSeaNavy.c25,
    width: '40px',
    minWidth: 'unset',
    height: '40px',
    borderRadius: '50px',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: theme.palette.baseColors.deepSeaNavy.c75,
    },
  },
  button: {
    ...theme.menuButtonFontStyles,
    fontWeight: 400,
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
  expandIcon: {
    transition: 'transform 0.2s ease',
  },
}));
