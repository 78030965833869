import React, { useContext } from 'react';

import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../../utils/auth';
import { SIGN_IN_PATH } from '../../utils/constants';
import CurrentUserContext from '../CurrentUserContext';

export default ({ children, ...rest }) => {
  const { isUserEntitled } = useContext(CurrentUserContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() === true ? (
          isUserEntitled(location.pathname) ? (
            children
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: SIGN_IN_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
