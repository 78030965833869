import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    margin: '0 5px',
    aspectRatio: 1,
  },
  clickable: {
    cursor: 'pointer',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '10px',
    alignItems: 'center',
  },
  invisible: {
    opacity: 0.5,
  },
}));

export default ({
  border = 'solid thin black',
  color,
  styles = {},
  title,
  clickCallback = () => {},
  visible = true,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.legendItem]: true,
        [classes.clickable]: !!clickCallback,
        [styles?.legendItem]: styles?.legendItem,
        [classes.invisible]: visible !== true, // plotly uses values that are truthy when it should be seen as false
      })}
      onClick={() => clickCallback(title)}
    >
      <div className={classes.circle} style={{ backgroundColor: color, border }} />
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};
