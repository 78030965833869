import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import { ShinyLink } from '../../../Shiny';
import { DATA_DOWNLOADS_PATH, VALUATIONS_PATH } from '../../../../utils/constants';
import CurrentUserContext from '../../../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { isUserEntitled } = useContext(CurrentUserContext);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2} />
      <Grid container item spacing={2} xs={8}>
        <Grid item>
          <Button
            id="landing-page-valuations-button"
            component={Link}
            to={VALUATIONS_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(VALUATIONS_PATH)}
          >
            <AssessmentIcon fontSize="small" />
            &nbsp;valuations and reports
          </Button>
        </Grid>
        <Grid item>
          <ShinyLink
            startAdornment={<WhatshotIcon fontSize="small" />}
            link={{ title: 'technical analysis', page: 'fields' }}
            styles={{ link: classes.button }}
            variant="contained"
            disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
