import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LegendItem from './legendItem';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.icedBackground,
    display: 'flex',
    flexDirection: 'row',
    color: '#2a2e49',
    padding: '3px',
    minHeight: '29px',
    height: 'fit-content',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  keepMounted: {
    ...theme.palette.keepMounted,
  },
  keepMountedEnabled: {
    ...theme.palette.keepMountedEnabled,
  },
  itemsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    ...theme.palette.scrollbar,
  },
  title: {
    fontWeight: 'bold',
  },
}));

export default ({
  enabled,
  height,
  items,
  keepMounted,
  styles,
  title,
  vertical,
  clickCallback,
  multiline,
  itemBorder,
}) => {
  const classes = useStyles();

  const renderItems = () =>
    items
      ? items.map((item) => (
          <LegendItem
            key={`legend-item-${item.title}-${item.color}`}
            border={itemBorder}
            color={item.color}
            clickCallback={clickCallback}
            styles={styles}
            title={item.title}
            visible={item.visible}
          />
        ))
      : null;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [styles?.legendRoot]: !!styles?.legendRoot,
        [classes.keepMounted]: !!keepMounted,
        [classes.keepMountedEnabled]: !!keepMounted && !!enabled,
      })}
    >
      {title && (
        <Typography
          className={clsx({
            [classes.title]: true,
            [styles?.legendTitle]: !!styles?.legendTitle,
          })}
          variant="body1"
          style={multiline ? { width: '100%' } : {}}
        >
          {title}
        </Typography>
      )}
      {vertical ? (
        <div className={classes.itemsContainer} style={{ height }}>
          {renderItems()}
        </div>
      ) : (
        renderItems()
      )}
    </div>
  );
};
