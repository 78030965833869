import {
  COUNTRY_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import { NAME_BASIN_SUMMARY_ITEM } from '../../../SummaryList/SummaryItems/basin';
import {
  BASIN_WELL_SUMMARY_ITEM,
  BLOCK_WELL_SUMMARY_ITEM,
  CNH_WELL_SUMMARY_ITEM,
  DATA_WELL_SUMMARY_ITEM,
  DESIGN_WELL_SUMMARY_ITEM,
  DRILL_START_WELL_SUMMARY_ITEM,
  DRILL_END_WELL_SUMMARY_ITEM,
  DRILL_DAYS_WELL_SUMMARY_ITEM,
  DRILL_YEAR_WELL_SUMMARY_ITEM,
  RIG_NAME_WELL_SUMMARY_ITEM,
  FIELD_WELL_SUMMARY_ITEM,
  FORMATION_WELL_SUMMARY_ITEM,
  HYDROCARBON_WELL_SUMMARY_ITEM,
  NAME_WELL_SUMMARY_ITEM,
  PROSPECT_WELL_SUMMARY_ITEM,
  RESULT_WELL_SUMMARY_ITEM,
  SHORE_STATUS_WELL_SUMMARY_ITEM,
  STATUS_WELL_SUMMARY_ITEM,
  WEA_WELL_SUMMARY_ITEM,
  LATERAL_LENGTH_WELL_SUMMARY_ITEM,
  FRAC_STAGES_WELL_SUMMARY_ITEM,
  COMPLETION_TYPE_WELL_SUMMARY_ITEM,
  SAND_PUMPED_WELL_SUMMARY_ITEM,
  WATER_INJECTED_WELL_SUMMARY_ITEM,
  PRODUCTION_FACILITY_WELL_SUMMARY_ITEM,
  IP_OIL_WELL_SUMMARY_ITEM,
  IP_GAS_WELL_SUMMARY_ITEM,
  EUR_OIL_WELL_SUMMARY_ITEM,
  EUR_GAS_WELL_SUMMARY_ITEM,
  GOR_WELL_SUMMARY_ITEM,
  WATER_CUT_WELL_SUMMARY_ITEM,
  LIQUID_PRODUCTION_WELL_SUMMARY_ITEM,
  GAS_PRODUCTION_WELL_SUMMARY_ITEM,
  VERTICAL_DEPTH_WELL_SUMMARY_ITEM,
  MEASURED_DEPTH_WELL_SUMMARY_ITEM,
  WATER_DEPTH_WELL_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/well';
import asset from './asset';
import block from './block';
import facility from './facility';
import field from './field';

const getInformation = (layer) => {
  let mexicoCNH = [];
  let nigeria = [];

  switch (layer) {
    case 'MEX':
      mexicoCNH = {
        type: 'group',
        template: [CNH_WELL_SUMMARY_ITEM],
      };
      break;
    case 'NGA':
      nigeria = {
        type: 'group',
        template: [DATA_WELL_SUMMARY_ITEM],
      };
      break;
  }

  let information = [
    {
      label: 'Well',
      type: 'group',
      template: [
        {
          type: 'group',
          template: [
            NAME_WELL_SUMMARY_ITEM,
            COUNTRY_SUMMARY_ITEM,
            BLOCK_WELL_SUMMARY_ITEM,
            FIELD_WELL_SUMMARY_ITEM,
            PROSPECT_WELL_SUMMARY_ITEM,
            OPERATOR_SUMMARY_ITEM,
            BASIN_WELL_SUMMARY_ITEM,
            SHORE_STATUS_WELL_SUMMARY_ITEM,
          ],
        },
        {
          type: 'group',
          template: [
            WEA_WELL_SUMMARY_ITEM,
            RESULT_WELL_SUMMARY_ITEM,
            DESIGN_WELL_SUMMARY_ITEM,
            STATUS_WELL_SUMMARY_ITEM,
            HYDROCARBON_WELL_SUMMARY_ITEM,
            FORMATION_WELL_SUMMARY_ITEM,
          ],
        },
        {
          type: 'group',
          template: [
            VERTICAL_DEPTH_WELL_SUMMARY_ITEM,
            MEASURED_DEPTH_WELL_SUMMARY_ITEM,
            WATER_DEPTH_WELL_SUMMARY_ITEM,
          ],
        },
        {
          type: 'group',
          template: [
            DRILL_START_WELL_SUMMARY_ITEM,
            DRILL_END_WELL_SUMMARY_ITEM,
            DRILL_DAYS_WELL_SUMMARY_ITEM,
            DRILL_YEAR_WELL_SUMMARY_ITEM,
            RIG_NAME_WELL_SUMMARY_ITEM,
          ],
        },
        mexicoCNH,
        nigeria,
      ],
    },

    {
      label: 'Asset',
      type: 'group',
      template: asset(layer),
      getEntity: (w) => w.asset,
    },
    {
      label: 'Block',
      type: 'group',
      template: block(layer),
      getEntity: (w) => w.block,
    },
    {
      label: 'Field',
      type: 'group',
      template: field(),
      getEntity: (w) => w.field,
    },
    {
      label: 'Facility',
      type: 'group',
      template: facility(layer),
      getEntity: (w) => w.facility,
    },
    {
      label: 'Basin',
      type: 'group',
      template: [NAME_BASIN_SUMMARY_ITEM],
      getEntity: (w) => w.basin,
    },
  ];

  if (layer === 'PER') {
    information = information.filter((tag) => !tag.exceptions);
  }

  return information;
};

const getProductionData = (layer) => {
  let argentina = [];

  if (layer === 'ARG') {
    argentina = {
      type: 'group',
      template: [
        LATERAL_LENGTH_WELL_SUMMARY_ITEM,
        FRAC_STAGES_WELL_SUMMARY_ITEM,
        COMPLETION_TYPE_WELL_SUMMARY_ITEM,
        SAND_PUMPED_WELL_SUMMARY_ITEM,
        WATER_INJECTED_WELL_SUMMARY_ITEM,
      ],
    };
  }

  return [
    {
      type: 'group',
      template: [
        NAME_WELL_SUMMARY_ITEM,
        BLOCK_WELL_SUMMARY_ITEM,
        FIELD_WELL_SUMMARY_ITEM,
        PRODUCTION_FACILITY_WELL_SUMMARY_ITEM,
      ],
    },

    {
      type: 'group',
      template: [
        IP_OIL_WELL_SUMMARY_ITEM,
        IP_GAS_WELL_SUMMARY_ITEM,
        EUR_OIL_WELL_SUMMARY_ITEM,
        EUR_GAS_WELL_SUMMARY_ITEM,
      ],
    },

    {
      type: 'group',
      template: [
        GOR_WELL_SUMMARY_ITEM,
        WATER_CUT_WELL_SUMMARY_ITEM,
        LIQUID_PRODUCTION_WELL_SUMMARY_ITEM,
        GAS_PRODUCTION_WELL_SUMMARY_ITEM,
      ],
    },

    argentina,
  ];
};

export default (layer, tab) => {
  switch (tab) {
    case 'information':
      return getInformation(layer);
    case 'productionData':
      return getProductionData(layer);
    default:
      return getInformation(layer);
  }
};
