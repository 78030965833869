import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  UnfoldLess as UnfoldLessIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { Tooltip, Typography } from '@material-ui/core';

import { BasicFiltersWrapper } from '../BasicFiltersWrapper';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useFilterSetsContext } from './FilterBarWrapper.context';

const useStyles = makeStyles((theme) => ({
  filterBarWrapper: {
    display: 'flex',
    gap: '1.5rem',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) thin',
    marginRight: '1.5rem',
    padding: '0.5rem 0',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '0.5rem',
      alignItems: 'flex-end',
    },
  },
  filters: {
    display: 'flex',
    flexGrow: 1,
  },
  expandButtonWrapper: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  expandButton: {
    marginTop: '0.5rem',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55px',
  },
  button: { padding: 0 },
  icon: {
    fontSize: '.7rem',
    transform: 'scale(2.5)',
  },
  label: {
    fontSize: '12px',
    lineHeight: '19px',
  },
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const FilterBarWrapper = ({ children, filters }) => {
  const { currentFilterSetIndex, nextFilterSet, previousFilterSet, filterSetsCount } =
    useFilterSetsContext();

  const [minimize, setMinimize] = useState(false);
  const classes = useStyles();

  const minimizeMaximize = useCallback(() => setMinimize((v) => !v), []);

  return (
    <div className={classes.filterBarWrapper}>
      <div className={classes.filters}>
        <div className={classes.expandButtonWrapper}>
          <Tooltip title={`${minimize ? 'Expand' : 'Collapse'} filter bar`}>
            <IconButton className={classes.expandButton} onClick={minimizeMaximize}>
              {minimize ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            </IconButton>
          </Tooltip>
        </div>
        {!!filters && <BasicFiltersWrapper minimize={minimize}>{filters}</BasicFiltersWrapper>}
        {filterSetsCount > 1 && (
          <div className={classes.controlsWrapper}>
            <Tooltip title="Move between your filter sets.">
              <div className={classes.controls}>
                <IconButton
                  className={classes.button}
                  onClick={previousFilterSet}
                  disabled={currentFilterSetIndex === 0}
                >
                  <ExpandLessIcon className={classes.icon} />
                </IconButton>
                <Typography className={classes.label} variant="caption">
                  {currentFilterSetIndex + 1}/{filterSetsCount}
                </Typography>
                <IconButton
                  className={classes.button}
                  onClick={nextFilterSet}
                  disabled={currentFilterSetIndex === filterSetsCount - 1}
                >
                  <ExpandMoreIcon className={classes.icon} />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
