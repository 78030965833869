import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '../../RadioGroup';
import { mapStyleItems } from './constants';

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: 'dimgrey',
    width: 'calc(100% - 10px)',
  },
  radioItemLabel: {
    width: '50%',
  },
}));

export default ({ divider, mapStyle, toggleMapStyle }) => {
  const classes = useStyles();
  const [style, setStyle] = useState(mapStyle);

  const handleToggle = (event) => {
    const newStyle = event.target.value;
    setStyle(newStyle);
    toggleMapStyle(newStyle);
  };

  return (
    <>
      <RadioGroup
        handleToggle={handleToggle}
        items={mapStyleItems}
        label="MAP STYLE"
        styles={classes}
        value={style}
      />
      {divider && <Divider className={classes.divider} />}
    </>
  );
};
