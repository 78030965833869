import { useSidePageButtonStyles } from './SidePageButton.style';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

export const SidePageButton = ({
  isOpen,
  showIcon,
  label,
  labelClosed,
  onClick,
  className,
  closeAble = false,
  onClose,
  boxy = false,
  left = false,
}) => {
  const classes = useSidePageButtonStyles();

  return (
    <Grid
      container
      justifyContent="center"
      className={clsx(classes.button, className, {
        [classes.buttonWithClose]: !!closeAble,
        [classes.boxy]: boxy,
        [classes.left]: left,
      })}
      onClick={onClick}
    >
      <Typography className={classes.buttonLabel} variant="caption" noWrap>
        {showIcon ? isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon /> : null}
        {isOpen ? label : labelClosed || label}
      </Typography>
      {!!closeAble && (
        <IconButton
          className={classes.close}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
  );
};
