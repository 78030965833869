import React, { useState } from 'react';
import { ModalWithButtonTrigger } from '../ModalWithButtonTrigger';
import { ModalContentWithTabs } from '../ModalContentWithTabs';
import { LoadFilter } from './components/LoadFilter';
import { SaveFilter } from './components/SaveFilter';
import { ShareFilter } from './components/ShareFilter';
import SaveIcon from '@material-ui/icons/Save';

export const UserFilters = ({
  label = 'Filter',
  SaveComponent = (props) => <SaveFilter {...props} />,
  LoadComponent = (props) => <LoadFilter {...props} />,
  ShareComponent = (props) => <ShareFilter {...props} />,
  ...props
}) => {
  const share = props.includeShare ? ['Share ' + label] : [];
  const shareComponent = props.includeShare ? [<ShareComponent {...props} key="Share" />] : [];
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <ModalWithButtonTrigger
      id={props.id}
      startIcon={<SaveIcon />}
      buttonTitle="Presets"
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      iconClass={props.iconClass}
      iconButtonClass={props.iconButtonClass}
    >
      <ModalContentWithTabs
        tabLabels={['Save ' + label, 'Load ' + label, ...share]}
        tabPanels={[
          <SaveComponent {...props} key="Save" handleClose={handleClose} />,
          <LoadComponent {...props} key="Load" handleClose={handleClose} />,
          ...shareComponent, // TODO: Do we want to enable this feature for valuations?
        ]}
      />
    </ModalWithButtonTrigger>
  );
};
