import React, { useState } from 'react';

import Graph from '../../../Graphs/graph';
import GraphContainer from '../../../Graphs/graphContainer';
import GraphControls from '../../../Graphs/GraphControls';
import Grid from '@material-ui/core/Grid';
import Modal from '../../../Modals';
import { truncate } from '../../helpers';
import { useStyles } from './styles';

const ChartGraph = ({
  data,
  title,
  loading,
  xConfig,
  yConfig,
  stackByConfig,
  graphLayout,
  open = false,
  openModal = () => null,
  closeModal = () => null,
  isInModal = false,
}) => {
  const enableChartSettingsButton = xConfig?.options?.length > 1 || yConfig?.options?.length > 1;
  const [showLegend, setShowLegend] = useState(true);
  const [openControlsModal, setOpenControlsModal] = useState(false);
  const graphConfig = {
    modeBarButtonsToRemove: [
      'pan2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'lasso2d',
      'select2d',
    ],
  };

  const layout = {
    showlegend: showLegend,
    ...graphLayout,
    ...(isInModal && {
      // place legend outside of the graph
      legend: {
        x: 1.05,
        y: 0.5,
      },
    }),
  };

  if (isInModal && showLegend) {
    // show legend for all traces when in modal
    data.forEach((trace) => (trace.showlegend = true));
  }

  if (data[0]?.x?.length === 1) {
    // if there is only one x-axis value, set x-axis type to category to avoid showing time on x-axis ticks
    layout.xaxis.type = 'category';
  }

  return (
    <GraphContainer
      open={open}
      setOpen={openModal}
      setClose={closeModal}
      setOpenControlsModal={setOpenControlsModal}
      showToggle={!isInModal}
      title={title}
      loading={loading}
      enableChartSettingsButton={enableChartSettingsButton}
      showPlotlyLegendToggle
      showPlotlyLegend={showLegend}
      setShowPlotlyLegend={setShowLegend}
      disableDownload
    >
      <Graph data={data} layout={layout} config={graphConfig} loading={loading} />
      {enableChartSettingsButton && (
        <GraphControls
          typesLabel="Aggregate By"
          types={xConfig?.options}
          graphType={xConfig?.value || ''}
          setGraphType={xConfig?.setValue}
          compareTo={yConfig?.value}
          setCompareTo={yConfig?.setValue}
          compareToOptions={yConfig?.options}
          stackType={stackByConfig?.value}
          setStackType={stackByConfig?.setValue}
          stackTypes={stackByConfig?.options}
          open={openControlsModal}
          setOpen={setOpenControlsModal}
        />
      )}
    </GraphContainer>
  );
};

export const MaGraph = ({ data, title, xConfig, yConfig, stackByConfig, loading, graphLayout }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  let offModalLayout = graphLayout;
  if (graphLayout?.xaxis?.type === 'category') {
    // if x-axis is categorical, we need to truncate the x-axis values
    const maxXAxisTickChars = 20;
    const maxXAxisTickValues = 50;
    let xAxisTickValues = data[0]?.x || [];
    // truncate x-axis values to `maxXAxisTickChars`` characters
    let xAxisTickTexts = xAxisTickValues.map((value) => truncate(value, maxXAxisTickChars));
    // if there are more than `maxXAxisTickValues`` x-axis values, we only show every other value
    if (xAxisTickTexts.length > maxXAxisTickValues) {
      const step = Math.ceil(xAxisTickTexts.length / maxXAxisTickValues);
      xAxisTickValues = xAxisTickValues.filter((_, index) => index % step === 0);
      xAxisTickTexts = xAxisTickTexts.filter((_, index) => index % step === 0);
    }

    offModalLayout = {
      ...graphLayout,
      xaxis: {
        ...graphLayout.xaxis,
        tickvals: xAxisTickValues,
        ticktext: xAxisTickTexts,
      },
    };
  }

  return (
    <div>
      <ChartGraph
        data={data}
        title={title}
        loading={loading}
        xConfig={xConfig}
        yConfig={yConfig}
        stackByConfig={stackByConfig}
        graphLayout={offModalLayout}
        openModal={openModal}
      />
      <Modal
        open={open}
        handleClose={closeModal}
        title="Chart Expansion"
        titleAlignedWithClose
        styles={classes}
      >
        <Grid container className={classes?.root} style={{ height: '100%' }}>
          <ChartGraph
            data={data}
            title={title}
            loading={loading}
            xConfig={xConfig}
            yConfig={yConfig}
            stackByConfig={stackByConfig}
            graphLayout={graphLayout}
            isInModal
            open={open}
            openModal={openModal}
            closeModal={closeModal}
          />
        </Grid>
      </Modal>
    </div>
  );
};
