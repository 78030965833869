/* eslint-disable no-use-before-define */
// ignore the above rule in favor of grouping the types together
// GRAPH TYPES
const AGGREGATE_DISABLES_AREA_OPTION = (aggregate) => {
  switch (aggregate) {
    case ASSET_AGGREGATE.name:
    case COMPANY_BY_ASSET_AGGREGATE.name:
    case DEVELOPMENT_STATUS_AGGREGATE.name:
      return true;
    default:
      return false;
  }
};
const AREA_OPTIONS_DISABLED = ({ aggregate, graphType }) => {
  // Option is disabled if the selected graph type is not the parent
  if (AREA.value !== graphType.value) return true;
  // If aggregate is string (coming from bar charts) check if aggregate is compatible with option
  if (typeof aggregate === 'string') {
    return AGGREGATE_DISABLES_AREA_OPTION(aggregate);
  } else {
    // If aggregate is object (coming from tree maps) check if any of the aggregate layers are compatible with option
    return !!Object.values(aggregate).find((agg) => AGGREGATE_DISABLES_AREA_OPTION(agg.name));
  }
};
const AREA = {
  label: 'Area',
  value: 'Area',
  optionIsDisabled: AREA_OPTIONS_DISABLED,
  onSelectedCallback: ({ handleSetState }) =>
    handleSetState(graphTypeChildren.Area, 'graphTypeOptions'),
  resetGraphTypeOptions: ({ aggregate, graphType, graphTypeOptions }) => {
    let options = graphTypeOptions.filter((option) =>
      graphTypeChildren.Area.find((child) => child.name === option.name),
    );
    if (graphType?.value === AREA.value) {
      options = graphTypeChildren.Area;
    }
    if (AGGREGATE_DISABLES_AREA_OPTION(aggregate)) {
      options = [];
    }
    return options;
  },
};
const ABEX = { label: 'Abandonment Cost', value: 'Abandonment Cost' };
const CAPEX = { label: 'Capex (excl. Abandonment)', value: 'Capex' };
const CUMULATIVE_CAPEX = {
  label: 'Cumulative capex (excl. Abandonment)',
  value: 'Cumulative capex',
};
export const CUMULATIVE_DISCOUNTED_CASH_FLOW = {
  label: 'Cumulative Discounted Cash Flow',
  value: 'Cumulative Discounted Cash Flow',
};

export const DISCOUNTED_CASH_FLOW = {
  label: 'Discounted Cash Flow',
  value: 'Discounted Cash Flow',
};
const ESTIMATED_CURRENT_PRODUCTION = {
  label: 'Estimated Current Production',
  value: 'Estimated Current Production',
};
export const NPV10 = {
  label: 'NPV10',
  optionLabel: 'NPV10',
  value: 'NPV10',
};
const NPV15 = {
  label: 'NPV15',
  optionLabel: 'NPV15',
  value: 'NPV15',
};
const NPV20 = {
  label: 'NPV20',
  optionLabel: 'NPV20',
  value: 'NPV20',
};
const NPV25 = {
  label: 'NPV25',
  optionLabel: 'NPV25',
  value: 'NPV25',
};
export const VALUATIONS = {
  label: 'Valuations',
  options: [NPV10, NPV15, NPV20, NPV25],
};
const OPEX = { label: 'Opex', value: 'Opex' };
const PROVED_DEVELOPED_PRODUCTION = {
  label: 'Proved Developed Production',
  value: 'Proved Developed Production',
  isDisabled: ({ checkboxAggregates }) => checkboxAggregates[PDP_AND_PUD_CHECKBOX_AGGREGATE.name],
};
const PRE_TAX_CASH_FLOW = { label: 'Pre-Tax Cash Flow', value: 'Pre-Tax Cash Flow' };
const REMAINING_CAPEX = { label: 'Remaining CAPEX', value: 'Remaining CAPEX' };
export const TOTAL_PRODUCTION = { label: 'Total Production', value: 'Total Production' };
const UNDEVELOPED_PRODUCTION = {
  label: 'Undeveloped Production',
  value: 'Undeveloped Production',
  isDisabled: ({ checkboxAggregates }) => checkboxAggregates[PDP_AND_PUD_CHECKBOX_AGGREGATE.name],
};
export const REMAINING_RESERVES = {
  label: 'Reserves',
  value: 'Reserves',
  optionIsDisabled: ({ graphType }) => 'Reserves' !== graphType.value,
  resetGraphTypeOptions: ({ graphTypeOptions }) =>
    graphTypeOptions.filter((option) =>
      graphTypeChildren['Reserves'].find((child) => child.name === option.name),
    ),
};
const REMAINING_OIL_AND_CONDENSATE_RESERVES = {
  label: 'Oil and Condensate Reserves',
  value: 'Oil and Condensate Reserves',
};
const REMAINING_GAS_RESERVES = {
  label: 'Gas Reserves',
  options: [
    {
      label: 'Gas Reserves',
      optionLabel: 'Bcf',
      value: 'Gas Reserves Bcf',
    },
    {
      label: 'Gas Reserves',
      optionLabel: 'Bm³',
      value: 'Gas Reserves Bcm',
    },
  ],
};

export const monetaryGraphTypes = [
  OPEX,
  CAPEX,
  CUMULATIVE_CAPEX,
  ABEX,
  DISCOUNTED_CASH_FLOW,
  CUMULATIVE_DISCOUNTED_CASH_FLOW,
  PRE_TAX_CASH_FLOW,
];

export const productionGraphTypes = [
  TOTAL_PRODUCTION,
  PROVED_DEVELOPED_PRODUCTION,
  UNDEVELOPED_PRODUCTION,
];

export const treeMapTypes = [
  ESTIMATED_CURRENT_PRODUCTION,
  REMAINING_CAPEX,
  REMAINING_RESERVES,
  REMAINING_OIL_AND_CONDENSATE_RESERVES,
  REMAINING_GAS_RESERVES,
  AREA,
  // TODO: Do we want these for the treemap eventually?
  // https://trello.com/c/Bz2pElIO/2248-portfolio-analysis-remaining-reserves
  // 'Remaining PDP Oil and Condensate Reserves',
  // 'Remaining PDP Gas Reserves',
  // 'Remaining PUD Oil and Condensate Reserves',
  // 'Remaining PUD Gas Reserves',
];

export const barGraphTypes = [
  ESTIMATED_CURRENT_PRODUCTION,
  VALUATIONS,
  REMAINING_CAPEX,
  REMAINING_RESERVES,
  AREA,
];

const HYDROCARBON_GRAPH_TYPE_OPTION = {
  name: 'Hydrocarbon',
};
const PRODUCTION_GRAPH_TYPE_OPTION = {
  name: 'PDP / Undeveloped',
};
const EXPLORATION_GRAPH_TYPE_OPTION = {
  name: 'Exploration',
  tooltip: 'Can only be selected with country and/or company aggregate.',
};
const DEVELOPMENT_GRAPH_TYPE_OPTION = {
  name: 'Development',
  tooltip: 'Can only be selected with country and/or company aggregate.',
};
export const graphTypeChildren = {
  [REMAINING_RESERVES.value]: [HYDROCARBON_GRAPH_TYPE_OPTION, PRODUCTION_GRAPH_TYPE_OPTION],
  [AREA.value]: [EXPLORATION_GRAPH_TYPE_OPTION, DEVELOPMENT_GRAPH_TYPE_OPTION],
};
export const treeGraphTypeChildren = {
  [AREA.value]: [EXPLORATION_GRAPH_TYPE_OPTION, DEVELOPMENT_GRAPH_TYPE_OPTION],
};

// HYDROCARBON TYPES
export const ALL = { label: 'All', value: 'All' };
export const OIL = {
  label: 'Oil',
  value: 'Oil',
  isDisabled: (checkboxAggregates) => checkboxAggregates[HYDROCARBON_CHECKBOX_AGGREGATE.name],
};
export const GAS = {
  label: 'Gas (MMcf/d)',
  value: 'Gas',
  isDisabled: (checkboxAggregates) => checkboxAggregates[HYDROCARBON_CHECKBOX_AGGREGATE.name],
};
export const GAS_MMCM = {
  label: 'Gas (MMm³/d)',
  value: 'Gas MMCF',
  isDisabled: (checkboxAggregates) => checkboxAggregates[HYDROCARBON_CHECKBOX_AGGREGATE.name],
};
export const CONDENSATE = {
  label: 'Condensate',
  value: 'Condensate',
  isDisabled: (checkboxAggregates) => checkboxAggregates[HYDROCARBON_CHECKBOX_AGGREGATE.name],
};
export const OIL_AND_CONDENSATE = {
  label: 'Oil and Condensate',
  value: 'Oil and Condensate',
  isDisabled: (checkboxAggregates) => checkboxAggregates[HYDROCARBON_CHECKBOX_AGGREGATE.name],
};
export const hydrocarbonTypes = [ALL, OIL, GAS, GAS_MMCM, CONDENSATE, OIL_AND_CONDENSATE];

export const graphTitles = {
  [ESTIMATED_CURRENT_PRODUCTION.value]: '(boe/d)',
  [NPV10.value]: '(US$MM)',
  [NPV15.value]: '(US$MM)',
  [NPV20.value]: '(US$MM)',
  [NPV25.value]: '(US$MM)',
  [REMAINING_CAPEX.value]: '(US$MM)',
  [REMAINING_RESERVES.value]: '(MMboe)',
  [REMAINING_OIL_AND_CONDENSATE_RESERVES.value]: '(MMbbl)',
  [REMAINING_GAS_RESERVES.options[0].value]: '(Bcf)',
  [REMAINING_GAS_RESERVES.options[1].value]: '(Bm³)',
  [ALL.value]: '(boe/d)',
  [OIL.value]: 'Oil (bbl/d)',
  [GAS.value]: 'Gas (MMcf/d)',
  [GAS_MMCM.value]: 'Gas (MMm³/d)',
  [CONDENSATE.value]: 'Condensate (bbl/d)',
  [OIL_AND_CONDENSATE.value]: 'Oil and Condensate (bbl/d)',
  [AREA.value]: '(km²)',
};

const checkboxAggregateSelected = (checkboxAggregates = {}) =>
  !!Object.values(checkboxAggregates).find((agg) => agg);

// AGGREGATE TYPES
export const COUNTRY_AGGREGATE = {
  name: 'Country',
  options: true,
  isDisabled: ({ checkboxAggregates = {} }) => checkboxAggregateSelected(checkboxAggregates),
};

export const ASSET_AGGREGATE = {
  name: 'Asset',
  options: true,
  isDisabled: ({ checkboxAggregates = {}, graphTypeOptions = [] }) =>
    !!graphTypeOptions.find((option) =>
      graphTypeChildren.Area.find((child) => child.name === option.name),
    ) || checkboxAggregateSelected(checkboxAggregates),
};
export const COMPANY_AGGREGATE = {
  name: 'Company',
  options: true,
  isDisabled: ({ checkboxAggregates = {} }) => checkboxAggregateSelected(checkboxAggregates),
};

const COMPANY_BY_ASSET_AGGREGATE = {
  name: 'Company Participation by Asset',
  options: true,
  isDisabled: ({ checkboxAggregates = {}, graphTypeOptions = [] }) =>
    !!graphTypeOptions.find((option) =>
      graphTypeChildren.Area.find((child) => child.name === option.name),
    ) || checkboxAggregateSelected(checkboxAggregates),
};

const DEVELOPMENT_STATUS_AGGREGATE = {
  name: 'Development Status',
  aggregate: 'Development Status',
  isDisabled: ({ checkboxAggregates = {}, graphTypeOptions = [] }) =>
    !!graphTypeOptions.find((option) =>
      graphTypeChildren.Area.find((child) => child.name === option.name),
    ) || checkboxAggregateSelected(checkboxAggregates),
};

export const PDP_AND_PUD_CHECKBOX_AGGREGATE = {
  name: 'PDP / Undeveloped',
  aggregate: 'PDP / Undeveloped',
  isDisabled: ({ graphType }) => graphType.value !== TOTAL_PRODUCTION.value,
  tooltip: `Can only be selected when '${TOTAL_PRODUCTION.label}' metric is selected`,
  type: 'checkbox',
};

export const HYDROCARBON_CHECKBOX_AGGREGATE = {
  name: 'Hydrocarbon',
  aggregate: 'Hydrocarbon',
  isDisabled: ({ hydrocarbonType }) => hydrocarbonType !== ALL.label,
  tooltip: `Can only be selected when '${ALL.label}' hydrocarbon types is selected`,
  type: 'checkbox',
};

export const defaultAggregateTypes = [
  COUNTRY_AGGREGATE,
  ASSET_AGGREGATE,
  COMPANY_AGGREGATE,
  DEVELOPMENT_STATUS_AGGREGATE,
];

export const barChartAggregateTypes = [
  COUNTRY_AGGREGATE,
  ASSET_AGGREGATE,
  COMPANY_AGGREGATE,
  COMPANY_BY_ASSET_AGGREGATE,
  DEVELOPMENT_STATUS_AGGREGATE,
];

export const productionAggregateTypes = [
  ...defaultAggregateTypes,
  HYDROCARBON_CHECKBOX_AGGREGATE,
  PDP_AND_PUD_CHECKBOX_AGGREGATE,
];

export const getTodaysDate = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth());
};
