import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AutoComplete } from '../../AutoComplete';

const useStyles = makeStyles(() => ({
  dataDownloadSelectInput: {
    backgroundColor: '#fafafa',
    boxSizing: 'border-box',
    paddingLeft: 0,
    borderRadius: 0,
    display: 'flex',
  },
  dataDownloadChips: {
    margin: '0',
  },
  select: {
    width: 'calc(calc(100% / 3) - calc(64px /3))',
  },
  text: {
    boxShadow: 'none',
    borderRadius: 0,
  },
  firstItem: {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
}));

export default ({ filter, propVariables, setFilter, value, firstItem }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.select}>
      <AutoComplete
        getData={filter.query}
        menuItems={filter.menuItems}
        onChange={(value) => setFilter(value, filter.name)}
        value={value}
        label={filter.label || filter.name}
        optionKey={filter.optionKey}
        propVariables={propVariables}
        runQueryAfterOnChange={filter.runQueryAfterOnChange}
        queryVariable={filter.queryVariable}
        multiple={filter.multiple}
        styles={{
          text: classes.text,
          input: clsx({
            [classes.dataDownloadSelectInput]: true,
            [classes.firstItem]: firstItem,
          }),
          root: classes.dataDownloadChips,
        }}
        disableLimitTags
      />
    </Grid>
  );
};
