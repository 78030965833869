import { gql } from '@apollo/client';

export const MA_ASSETS_BY_NAME_WITH_FILTER_QUERY = gql`
  query maAssetsByNameWithFilter(
    $search: String
    $buyers: [Int!]
    $sellers: [Int!]
    $countries: [String!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
  ) {
    maAssetsByNameWithFilter(
      search: $search
      buyers: $buyers
      sellers: $sellers
      countries: $countries
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      assets {
        id
        legacyId
        name
        displayName
        entityId
        entityType
        country {
          id
          name
          displayName
          isoCode
        }
      }
      totalCount
    }
  }
`;

export const MA_EVENT_TYPES_QUERY = gql`
  {
    maDealTypes {
      id
      name
      group
    }
  }
`;
