import { IN_DEVELOPMENT_FLAG } from '../../../utils/constants';
import { getMapLabelTextColors, getMapOptions } from './mapStyles';
import { mapLayerMatch } from './utils';
import { MAP_LAYER_IDS, MAP_LAYER_OPTIONS, MAP_LAYER_TYPES, MAP_SOURCE_IDS } from './constants';
import { MAP_RULER_COLORS } from './colors';

/* LAYER LABELS */
// List of layers that require labels
export const layerNeedsLabels = (layerName) =>
  mapLayerMatch(layerName, [
    MAP_LAYER_TYPES.blocks,
    MAP_LAYER_TYPES.assets,
    MAP_LAYER_TYPES.leases,
    MAP_LAYER_TYPES.fields,
    MAP_LAYER_TYPES.storageSites,
    MAP_LAYER_TYPES.wells,
  ]);

// Build ID for entity type labels layer
export const buildLabelsLayerId = (layerName) => {
  return `${layerName}-labels`;
};

// Add labels layer to map
const addLabelsLayer = (map, layerId, source, sourceLayer, layerIsHidden = false) => {
  // The well labels have different properties than the other labels:
  const isWellLayer = mapLayerMatch(sourceLayer, [MAP_LAYER_TYPES.wells, MAP_LAYER_TYPES.eaWells]);
  const opts = isWellLayer ? { ...MAP_LAYER_OPTIONS.wellLabel } : { ...MAP_LAYER_OPTIONS.label };

  if (layerIsHidden) {
    opts.layout = { ...opts.layout, visibility: 'none' };
  }

  const textColor = getMapLabelTextColors(sourceLayer);
  const layer = {
    ...opts,
    id: layerId,
    source: source,
    paint: {
      ...opts.paint,
      'text-color': textColor,
    },
  };

  // Don't add a source-layer property for the E&A wells, since
  // it's not needed and it prevents the labels from showing up:
  if (!mapLayerMatch(sourceLayer, [MAP_LAYER_TYPES.eaWells])) {
    layer['source-layer'] = sourceLayer;
  }

  map.addLayer(layer);
};

// Hide basins layer
export const hideBasinsLayer = (map) => {
  map.setLayoutProperty(MAP_LAYER_IDS.basins, 'visibility', 'none');
  map.setLayoutProperty(MAP_LAYER_IDS.basinsLabels, 'visibility', 'none');
};

/* ADDITIONAL LAYERS */
// Country boundaries layer
const addCountryBoundariesLayer = (map) => {
  // use mapbox layer for country boundaries

  // Fill layer is used to color in the country:
  map.addLayer(MAP_LAYER_OPTIONS.countryFillBoundary, 'country-label');

  // Line layer is used to highlight countries:
  map.addLayer(MAP_LAYER_OPTIONS.countryLineBoundary);

  // Highlight layer is used to highlight the selected country
  map.addLayer(MAP_LAYER_OPTIONS.countrySelectedBoundary);

  // add our custom layer for custom regions
  map.addLayer(MAP_LAYER_OPTIONS.customRegionFillBoundary);
  map.addLayer(MAP_LAYER_OPTIONS.customRegionLineBoundary);
  map.addLayer(MAP_LAYER_OPTIONS.customRegionSelectedBoundary);
  // add labels for our custom regions
  map.addLayer(MAP_LAYER_OPTIONS.customRegionLabel);
};

// LNG Facilities layer
const addLngFacilitiesLayer = (map) => {
  // LNG Facilities - icons layer:
  map.addSource(MAP_SOURCE_IDS.lngFacilities, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer(MAP_LAYER_OPTIONS.lngFacility);
};

// Pipeline Networks layer
const addPipelineNetworksLayer = (map) => {
  // Pipeline Networks
  map.addSource(MAP_SOURCE_IDS.pipelineNetworks, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer(MAP_LAYER_OPTIONS.pipelineNetwork);
};

// Screening layer
const addScreeningLayers = (map) => {
  // Screening - E&A wells
  map.addSource(MAP_SOURCE_IDS.eaWells, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer(MAP_LAYER_OPTIONS.eaWell);
  addLabelsLayer(map, MAP_LAYER_IDS.eaWellLabels, MAP_SOURCE_IDS.eaWells, 'label_eawells');

  // add screening source outline
  map.addSource(MAP_SOURCE_IDS.screening, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer(MAP_LAYER_OPTIONS.screeningOutline);

  // TODO: [M&A opportunities clustering] remove the else block when clustering is ready to be released
  if (IN_DEVELOPMENT_FLAG) {
    // screening source cluster:
    map.addSource(MAP_SOURCE_IDS.screeningCluster, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      cluster: true,
      clusterMaxZoom: 4,
      clusterRadius: 50,
    });

    map.addLayer(MAP_LAYER_OPTIONS.screeningCluster);
    map.addLayer(MAP_LAYER_OPTIONS.screeningClusterCount);

    // Zoom to cluster on click:
    map.on('click', MAP_LAYER_IDS.screeningClusters, (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [MAP_LAYER_IDS.screeningClusters],
      });

      // Only one cluster is selected at a time, so we can just use the first feature
      // in order to get the cluster id:
      const selectedFeature = features[0];
      const clusterId = selectedFeature.properties.cluster_id;
      map
        .getSource(MAP_SOURCE_IDS.screeningCluster)
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: selectedFeature.geometry.coordinates,
            zoom,
          });
        });
    });
  } else {
    // screening source flags:
    map.addSource(MAP_SOURCE_IDS.screeningCluster, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id: MAP_LAYER_IDS.screeningClusters,
      type: 'symbol',
      source: MAP_SOURCE_IDS.screeningCluster,
      layout: {
        'icon-anchor': 'bottom-left',
        'icon-image': 'screening',
        'icon-size': 0.2,
      },
    });
  }
};

// Add additional layers to map
export const addAdditionalLayers = (map, layers) => {
  if (layers.includes(MAP_LAYER_TYPES.countryBoundaries)) addCountryBoundariesLayer(map);
  if (layers.includes(MAP_LAYER_TYPES.lng)) addLngFacilitiesLayer(map);
  if (layers.includes(MAP_LAYER_TYPES.screening)) addScreeningLayers(map);
  if (layers.includes(MAP_LAYER_TYPES.pipelineNetworks)) addPipelineNetworksLayer(map);
};

/* TILESET LAYERS */

/* LAYER HIGHLIGHTING */
// List of layers that require highlighting
const layerNeedsHighlight = (layerName) =>
  mapLayerMatch(layerName, [
    MAP_LAYER_TYPES.blocks,
    MAP_LAYER_TYPES.assets,
    MAP_LAYER_TYPES.leases,
    MAP_LAYER_TYPES.fields,
    MAP_LAYER_TYPES.formations,
    MAP_LAYER_TYPES.pipelines,
    MAP_LAYER_TYPES.pipelineSegments,
  ]);

// Build ID for entity type highlight layer
export const buildHighlightLayerId = (layerName) => {
  return `${layerName}-highlight`;
};

// Add highlight layer to map
const addHighlightLayer = (map, layer, source) => {
  map.addLayer({
    ...MAP_LAYER_OPTIONS.highlight,
    id: buildHighlightLayerId(layer),
    source,
    'source-layer': layer,
  });
};

// Add tileset layers to map
export const addLayers = (
  map,
  mapTilesetSources,
  layers,
  initialMetric,
  initialSizeType,
  hideLayers,
  enableEntityHighlight,
) => {
  hideBasinsLayer(map);

  mapTilesetSources?.forEach((source) => {
    const { country, sourceId, layers: sourceLayers } = source;
    if (!sourceId) {
      return;
    }

    map.addSource(country, {
      type: 'vector',
      url: `mapbox://${sourceId}`,
    });

    const labelLayers = [];
    const highlightLayers = [];
    sourceLayers.forEach((layer) => {
      if (mapLayerMatch(layer, layers)) {
        const opts = { ...getMapOptions(layer, initialMetric, initialSizeType) };

        if (opts) {
          // If layer is meant to be hidden (is toggled off but can be toggled on),
          // add visiblity 'none' to layout
          if (mapLayerMatch(layer, hideLayers)) {
            opts.layout = { ...opts.layout, visibility: 'none' };
          }

          map.addLayer({
            id: layer,
            source: country,
            'source-layer': layer,
            ...opts,
          });

          if (enableEntityHighlight && layerNeedsHighlight(layer)) {
            highlightLayers.push({ layer, country });
          }

          if (layerNeedsLabels(layer)) {
            labelLayers.push({ layer, country });
          }

          map.on('mouseenter', `${layer}`, () => {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', `${layer}`, () => {
            map.getCanvas().style.cursor = '';
          });
        }
      }
    });

    highlightLayers.forEach(({ layer, country }) => {
      addHighlightLayer(map, layer, country);
    });

    labelLayers.forEach(({ layer, country }) => {
      const layerId = buildLabelsLayerId(layer);
      const layerIsHidden = mapLayerMatch(layer, hideLayers);
      addLabelsLayer(map, layerId, country, layer, layerIsHidden);
    });
  });
};

/* RULER LAYER */
// Add ruler context to map
export const addRulerContext = (map, geojson, style) => {
  map.addSource(MAP_SOURCE_IDS.geojson, {
    type: 'geojson',
    data: geojson,
  });
  // Add styles to the map
  map.addLayer({
    ...MAP_LAYER_OPTIONS.measureLine,
    paint: {
      ...MAP_LAYER_OPTIONS.measureLine.paint,
      'line-color': MAP_RULER_COLORS[style],
    },
  });
  map.addLayer(MAP_LAYER_OPTIONS.measurePoint);
  map.addLayer(MAP_LAYER_OPTIONS.distanceLabel);
};
