import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ShinyLink } from '../../Shiny';
import { SignOutButton } from '../SignOutButton';
import links from '../links';
import {
  DATA_DOWNLOADS_PATH,
  RESERVES_DEFINITION_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../../../utils/constants';
import CurrentUserContext from '../../CurrentUserContext';
import { ButtonList } from '../ButtonList';
import { useMobileTrayStyles } from './MobileTray.style';
import FullLogo from '../../../../assets/images/FullLogo.svg';
import CloseIcon from '@material-ui/icons/Close';

const SPACER = 'SPACER';

export const MobileTray = ({ onButtonClick, onCloseTray }) => {
  const classes = useMobileTrayStyles();
  const location = useLocation();
  const { isUserEntitled } = useContext(CurrentUserContext);

  const path = location.pathname;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link to={ROOT_PATH}>
          <img id="logo" src={FullLogo} alt="Welligence-Logo" width={'172px'} height={'45px'} />
        </Link>
        <Button onClick={onCloseTray} color="inherit" underline="none" className={classes.link}>
          <CloseIcon />
        </Button>
      </div>
      <div className={classes.content}>
        {[
          ...links,
          SPACER,
          {
            title: 'SETTINGS',
            link: SETTINGS_PATH,
          },
          {
            title: 'HELP',
            link: RESERVES_DEFINITION_PATH,
          },
        ].map((link, i) =>
          link === SPACER ? (
            <div key="spacer" style={{ flexGrow: 1 }} />
          ) : link.type === 'menu' ? (
            <ButtonList key={`${i}-${link.title}`} link={link} onButtonClick={onButtonClick} />
          ) : link.type === 'shiny' ? (
            <ShinyLink
              key={`${i}-${link.title}`}
              link={link}
              styles={{ link: classes.link }}
              disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
              onClickCallback={onButtonClick}
            />
          ) : link.type === 'href' ? (
            <Button
              key={`${i}-${link.title}`}
              color="inherit"
              underline="none"
              className={classes.link}
              onClick={() => window.open(link.link, '_blank')}
            >
              {link.title}
            </Button>
          ) : (
            <Button
              key={`${i}-${link.title}`}
              component={Link}
              onClick={onButtonClick}
              to={`${link.link}`}
              color="inherit"
              underline="none"
              disabled={!isUserEntitled(link.link)}
              className={path.indexOf(link.link) !== -1 ? classes.selectedLink : classes.link}
            >
              {link.title}
            </Button>
          ),
        )}
        <SignOutButton onClick={onButtonClick} />
      </div>
    </div>
  );
};
