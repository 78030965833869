import { formatDateShort, formatTimestamp, titleize } from '../../../utils/helpers';

// Exceptions
const hidePeakProductionDateCountries = ['AZE'];

// ITEMS
export const OPERATORS_ASSET_SUMMARY_ITEM = {
  label: 'Operator',
  accessor: (asset) => {
    const latestEffectiveDate =
      asset?.participations && asset?.participations.length > 0
        ? asset?.participations[0].effectiveDate
        : null;
    const latestParticipations = latestEffectiveDate
      ? asset?.participations.filter(
          (participation) =>
            participation.effectiveDate === latestEffectiveDate && participation.company,
        )
      : null;

    if (!latestParticipations) return null;

    const latestOperator = [
      ...new Set(
        latestParticipations
          .filter((participation) => participation.isCompanyOperator)
          .map((participation) => ({
            text: `${participation.company.name} (${participation.formattedPercentStake})`,
            needsIcon: participation.mAndAOpportunity,
          })),
      ),
    ];

    return latestOperator?.length > 0 ? latestOperator : '-';
  },
};

// Differs from participation as it EXCLUDES the operator
export const PARTNERS_ASSET_SUMMARY_ITEM = {
  label: 'Partners',
  accessor: (asset) => {
    const latestEffectiveDate =
      asset?.participations && asset?.participations.length > 0
        ? asset?.participations[0].effectiveDate
        : null;
    const latestParticipations = latestEffectiveDate
      ? asset?.participations.filter(
          (participation) =>
            participation.effectiveDate === latestEffectiveDate &&
            !participation.isCompanyOperator &&
            participation.company,
        )
      : null;

    if (!latestParticipations) return null;

    const latestPartners = [
      ...new Set(
        latestParticipations
          .sort(
            (a, b) =>
              b.percentStake - a.percentStake || a.company?.name?.localeCompare(b.company?.name),
          )
          .map((participation) => ({
            text: `${participation.company.name} (${participation.formattedPercentStake})`,
            needsIcon: participation.mAndAOpportunity,
          })),
      ),
    ];

    return latestPartners?.length > 0 ? latestPartners : '-';
  },
};

export const PRIMARY_HYDROCARBON_ASSET_SUMMARY_ITEM = {
  label: 'Primary Hydrocarbon',
  accessor: (asset) => (asset?.hydrocarbonType ? titleize(asset?.hydrocarbonType) : null),
};

export const HPHT_ASSET_SUMMARY_ITEM = {
  label: 'HPHT',
  accessor: (asset) => (asset?.isHpht ? 'Yes' : asset?.isHpht === false ? 'No' : null),
};

export const FIRST_PRODUCTION_ASSET_SUMMARY_ITEM = {
  label: 'First Production',
  accessor: (asset) => formatDateShort(asset?.firstProductionDate),
};

export const PEARK_PRODUCTION_ASSET_SUMMARY_ITEM = {
  label: 'Peak Production',
  accessor: (asset) => {
    if (
      hidePeakProductionDateCountries.includes(asset?.country?.isoCode) &&
      asset?.peakProductionDate
    ) {
      return formatDateShort(asset?.peakProductionDate);
    } else {
      return null;
    }
  },
};

export const LICENSE_EXPIRATION_ASSET_SUMMARY_ITEM = {
  label: 'License Expiration',
  accessor: (asset) => formatDateShort(asset?.licenseExpirationDate),
};

export const LIQUIDS_PRODUCTION_ASSET_SUMMARY_ITEM = {
  label: 'Most Recent Oil/Condensate Production',
  unit: (asset) => {
    const date = formatDateShort(asset?.estimatedCurrentDate);
    return `(${date}) (bbl/d)`;
  },
  accessor: (asset) => {
    const estimatedRecentProdLiquids = asset?.formattedEstimatedRecentProdLiqBbld;
    if (!estimatedRecentProdLiquids) {
      return null;
    }
    return `${estimatedRecentProdLiquids} *`;
  },
};

export const GAS_PRODUCTION_ASSET_SUMMARY_ITEM = {
  label: 'Most Recent Gas Production',
  unit: (asset) => {
    const date = formatDateShort(asset?.estimatedCurrentDate);
    return `(${date}) (MMcf/d)`;
  },
  accessor: (asset) => {
    const estimatedRecentProdGas = asset?.formattedEstimatedRecentProdGasMmcfd;
    if (!estimatedRecentProdGas) {
      return null;
    }
    return `${estimatedRecentProdGas} *`;
  },
};

export const EMISSIONS_ASSET_SUMMARY_ITEM = {
  label: `Current Emissions Intensity`,
  unit: '(kgCO2/boe)',
  accessor: (asset) => asset?.formattedCurYrTotalProdIntensityKgco2ePerBoe,
};

export const REMAINING_LIQUIDS_RESERVES_ASSET_SUMMARY_ITEM = {
  label: (a) => (a?.country?.haveLiquidsReserves ? 'Oil/Condensate Reserves' : 'Oil Reserves'),
  unit: '(MMbbl)',
  accessor: (a) => {
    return a && a.reserve && !a?.country?.haveLiquidsReserves
      ? a.reserve.formattedTotalRemainingReservesOilMmbbl
      : a && a.reserve && a?.country?.haveLiquidsReserves
      ? a.reserve.formattedTotalRemainingReservesLiquidsMmbbl
      : null;
  },
};

export const REMAINING_GAS_RESERVES_ASSET_SUMMARY_ITEM = {
  label: `Gas Reserves`,
  unit: '(Bcf)',
  accessor: (asset) => asset?.reserve?.formattedTotalRemainingReservesGasBcf,
};

export const WELL_EURS_ASSET_SUMMARY_ITEM = {
  label: (asset) => (asset?.hydrocarbonType === 'oil' ? 'Oil Well EURs' : 'Gas Well EURs'),
  unit: (asset) => (asset?.hydrocarbonType === 'oil' ? '(MMbbl)' : '(Bcf)'),
  accessor: (asset) =>
    asset?.hydrocarbonType === 'oil' ? asset?.formattedLiquidsEurMmbbl : asset?.formattedGasEurBcf,
};

export const WELL_RATES_ASSET_SUMMARY_ITEM = {
  label: (asset) => (asset?.hydrocarbonType === 'oil' ? 'Oil Well IP Rates' : 'Gas Well IP Rates'),
  unit: (asset) => (asset?.hydrocarbonType === 'oil' ? '(bbl/d)' : '(MMcf/d)'),
  accessor: (asset) =>
    asset?.hydrocarbonType === 'oil' ? asset?.formattedLiquidsIpBbld : asset?.formattedGasIpMmcfd,
};

export const NOTES_ASSET_SUMMARY_ITEM = {
  label: ``,
  accessor: (asset) =>
    asset?.formattedRecentProdLiquidsBbld || asset?.formattedRecentProdGasMmcfd
      ? `(*) The production shown is actual if reported within the last 6 months; otherwise we show our most recent estimate.`
      : null,
};

export const PV_10_ASSET_SUMMARY_ITEM = {
  label: 'Valuation (NPV10)',
  unit: '(US$MM)',
  accessor: (asset) => asset?.formattedNpv,
};

export const PV_REMAINING_CAPEX_ASSET_SUMMARY_ITEM = {
  label: `Remaining Capex + Abex`,
  unit: '(US$MM)',
  accessor: (asset) => asset?.formattedRemainingCapex,
};

export const TRANSPORTATION_METHOD_ASSET_SUMMARY_ITEM = {
  label: 'Transportation method',
  accessor: 'transportationMethod',
};

export const TRUCKING_DESTINATION_ASSET_SUMMARY_ITEM = {
  label: 'Trucking destination',
  accessor: 'truckingDestination',
};

export const PIPELINE_NETWORK_ASSET_SUMMARY_ITEM = {
  label: 'Pipeline network',
  accessor: 'pipelineNetwork',
};

export const PIPELINE_NETWORK_SEGMENT_ASSET_SUMMARY_ITEM = {
  label: 'Pipeline network segment',
  accessor: 'pipelineNetworkSegment',
};

export const DISPLAY_NAME_ASSET_SUMMARY_ITEM = {
  label: 'Asset',
  accessor: 'displayName',
};

export const GEOLOGY_ASSET_SUMMARY_ITEM = {
  label: 'Geology',
  accessor: (a) => {
    let formations = [];
    let ages = [];
    let periods = [];
    if (a?.geologies?.length === 0) {
      return null;
    }
    a?.geologies?.forEach((g) => {
      if (g.formation) formations.push(g.formation.name);
      if (g.age) ages.push(g.age.name);
      if (g.period) periods.push(g.period.name);
    });
    formations = formations.filter((g, index, self) => self.indexOf(g) === index).join('; ');
    ages = ages.filter((g, index, self) => self.indexOf(g) === index).join('; ');
    periods = periods.filter((g, index, self) => self.indexOf(g) === index).join('; ');
    return `Formation(s): ${formations}; Age(s): ${ages}; Period(s): ${periods};`;
  },
};

export const API_ASSET_SUMMARY_ITEM = {
  label: 'API',
  accessor: (a) => a?.formattedApi,
};

export const LICENSING_ROUND_ASSET_SUMMARY_ITEM = {
  label: 'Round',
  accessor: 'licensingRound',
};

export const NPVS_ASSET_SUMMARY_ITEM = {
  label: 'NPVs',
  unit: '(US$MM)',
  accessor: (a) => {
    if (!a?.assetNpvs?.length && !a?.formattedNpv) {
      return null;
    }

    // Combine all NPVs into one array if NPV10 > 0
    let allNpvs = [{ formattedPercent: '10', formattedValue: a.formattedNpv }, ...a.assetNpvs];
    // Filter out NPVs with no value:
    allNpvs = allNpvs.filter((npv) => npv.formattedValue);
    // Sort by percent, ascending:
    allNpvs.sort((a, b) => a.formattedPercent - b.formattedPercent);
    const npvs = allNpvs.map((npv) => `NPV${npv.formattedPercent}: ${npv.formattedValue}`);
    return npvs.join('\n');
  },
};

export const ACTIVE_WELLS_ASSET_SUMMARY_ITEM = {
  label: 'Active Wells',
  accessor: (a) => (a?.activeWellCount > 0 ? a?.activeWellCount : null),
};

export const TOTAL_DEVELOPMENT_WELLS_DRILLED_ASSET_SUMMARY_ITEM = {
  label: 'Total Development Wells Drilled',
  accessor: (a) => (a?.developmentWellCount > 0 ? a?.developmentWellCount : null),
};

export const OIL_PRICE_BREAKEVEN_SUMMARY_ITEM = {
  label: 'Brent Oil Price Breakeven',
  unit: '(US$/bbl)',
  accessor: (a) => a?.breakevenOil?.formattedBreakevenOilValue,
  onHover: (a) => `Last updated: ${formatTimestamp(a?.breakevenOil?.updatedAt) || 'Unknown'}`,
};
