import React, { useContext } from 'react';
import clsx from 'clsx';

import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { LEAD_ANALYST_QUERY } from './operations';
import SnackbarContext from '../SnackbarContext';
import { getUserId } from '../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.transparentBackgroundAccent,
    flex: '1 1 auto',
    borderRadius: '5px',
    padding: '30px 10px 10px',
  },
  rootHorizontal: {
    padding: '10px',
  },
  iconButton: {
    ...theme.palette.greyButton,
  },
  analystIcon: {
    padding: '10px 0',
  },
  email: {
    ...theme.palette.baseColors.iceBlue.c100,
    fontWeight: '100',
    paddingLeft: '10px',
    cursor: 'pointer',
  },
  analystContainer: {
    width: 'auto',
    flex: '1 1 auto',
    justifyContent: 'space-evenly',
  },
  analystInfo: {
    paddingLeft: '10px',
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

// country - country to get lead analyst for
// context - page the component is being used in (for GA)
// horizontal - flag to use horizontal version of component
// includeTitle - flag to include the component title
export default ({ country, context, horizontal, includeTitle }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const dimensions = country ? { country: country.name } : {};
  const isoCode = country ? country.isoCode : null;

  const { data } = useQuery(LEAD_ANALYST_QUERY, {
    variables: {
      countryIsoCode: isoCode,
    },
  });

  const copyEmail = (email) => {
    const el = document.createElement('input');
    el.value = email;
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
    setSnack({
      open: true,
      severity: 'success',
      message: 'Copied URL to clipboard!',
    });
  };

  return (
    <Grid container direction="column" style={{ height: '100%', padding: '10px' }}>
      {includeTitle && <Typography variant="overline">LEAD ANALYST</Typography>}
      <Grid
        container
        item
        direction={horizontal ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems="center"
        className={clsx({ [classes.root]: true, [classes.rootHorizontal]: horizontal })}
      >
        <Grid
          container
          item
          direction={horizontal ? 'row' : 'column'}
          alignItems="center"
          className={clsx({ [classes.analystContainer]: horizontal })}
        >
          <Grid item>
            <AccountCircleIcon fontSize="large" className={classes.analystIcon} />
          </Grid>
          <Grid item className={clsx({ [classes.analystInfo]: horizontal })}>
            <Typography display="inline">{data?.leadAnalyst?.name}</Typography>
            <Grid item className={classes.emailContainer}>
              <Tooltip title="Open in Email" aria-label="open email">
                <Typography
                  variant="body2"
                  style={{ fontWeight: '100', cursor: 'pointer' }}
                  onClick={() => {
                    gtag(
                      'event',
                      ANALYTICS_EVENTS.event, {
                        'event_category': context,
                        'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
                        'event_label': 'contact_analyst',
                        'userId': getUserId(),
                        ...dimensions,
                      }
                    );
                    window.location.href = `mailto:${data?.leadAnalyst?.email}`;
                  }}
                >
                  {data?.leadAnalyst?.email}
                </Typography>
              </Tooltip>
              <Tooltip title="Copy Email to Clipboard" aria-label="copy email">
                <IconButton
                  className={classes.iconButton}
                  onClick={() => copyEmail(data?.leadAnalyst?.email)}
                >
                  <FileCopyOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
