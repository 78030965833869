import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  root: { width: '95%', padding: '10px 0' },
  slider: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    '& .MuiSlider-valueLabel': {
      '& > *': {
        '& > *': {
          color: theme.palette.baseColors.offWhite.c100,
        },
      },
    },
  },
}));

export default ({ item, loading, onChange, maxValue, value }) => {
  const classes = useStyles();

  const displayedMarks = useMemo(() => {
    const { marks } = item;
    if (Array.isArray(marks)) {
      marks[1] = {
        value: maxValue,
        label: `${maxValue}`,
      };

      return marks;
    }
  }, [item, maxValue]);

  const onSliderChange = (e, value) => {
    // if the slider is at the min and max value,
    // we can set the value to an empty array
    // this accounts for null values in the database
    const min = displayedMarks[0].value;
    if (value[0] === min && value[1] === maxValue) {
      onChange([], item.key);
    } else {
      onChange(value, item.key);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Grid className={classes.root} container item>
      <Typography variant="body2">{item.label}</Typography>
      <Slider
        className={classes.slider}
        value={
          value.length !== 2
            ? [displayedMarks[0].value, displayedMarks[displayedMarks.length - 1].value]
            : value
        }
        onChange={onSliderChange}
        valueLabelDisplay="auto"
        marks={displayedMarks}
        max={maxValue}
      />
    </Grid>
  );
};
