import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useLazyQuery } from '@apollo/client';
import { SECURE_COUNTRY_DOWNLOAD_QUERY } from '../Downloads/operations';
import { CORE_DOWNLOAD_TYPE, GA_PAGE } from './constants';
import { openDownloadUrl } from '../Downloads/helpers';
import SnackbarContext from '../SnackbarContext';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import { getUserId } from '../../utils/auth';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.actionCustom,
    height: 'fit-content',
    padding: '10px',
    alignSelf: 'center',
  },
}));

export default ({ country }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const dimensions = country
    ? { asset: 'NULL', country: country.name, company: 'NULL' }
    : {};
  const countryIsoCode = country ? country.isoCode : null;

  const [getReport] = useLazyQuery(SECURE_COUNTRY_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureCountryDownload;
      if (url) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        openDownloadUrl(url, filename);
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  return (
    <Button
      onClick={() => {
        gtag('event', ANALYTICS_EVENTS.event, { 
          'event_category': GA_PAGE, 'event_action': ANALYTICS_EVENT_ACTIONS.download, 'event_label': 'country_report', 'userId': getUserId(), ...dimensions,
        });
        getReport({ variables: { countryIsoCode, downloadType: CORE_DOWNLOAD_TYPE } });
      }}
      disabled={!country}
      className={classes.button}
      variant="contained"
    >
      <GetAppIcon fontSize="small" />
      &nbsp;country overview
    </Button>
  );
};
