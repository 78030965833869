import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
  <SvgIcon {...props}>
    <path
      d="M20,15H4v-2h16V15z M20,17H4v2h16V17z M15,11l5-3.55L20,5l-5,3.55L10,5L4,8.66L4,11l5.92-3.61L15,11z"
      id="Fill-1"
      mask="url(#mask-4)"
    ></path>
  </SvgIcon>
);
