import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import {
  BASINS_WITH_MAP_FILTER_QUERY,
  COMPANIES_WITH_MAP_FILTER_QUERY,
  MAP_SCREENING_QUERY,
  SHORE_STATUS_QUERY,
} from './operations';
import React, { useContext, useEffect } from 'react';
import { analytics, defaultDimensions, dimensions } from '../../../utils/analytics';

import { AutoComplete } from '../../AutoComplete';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { MAP_SOURCE_IDS } from '../../Map/Utils/constants';
import MapFilterContext from './filterContext';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { getLatLongFromPoint } from '../../../utils/helpers';
import { getUserId } from '../../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { COUNTRIES } from '../../Filters/filterTypes';

const useStyles = makeStyles((theme) => ({
  screening: {
    ...theme.palette.mapTab,
    ...theme.palette.scrollbar,
  },
  select: {
    margin: theme.spacing(1),
  },
  minMaxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  minMax: {
    width: '45%',
  },
  reset: {
    ...theme.palette.button,
    margin: '18px 8px',
  },
  text: {
    '& root': {
      boxShadow:
        '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    },
    '& input': {
      backgroundColor: '#fafafa',
      fontSize: '14px',
      borderRadius: '5px',
    },
  },
  input: {
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    '&.MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
  radio: {
    paddingRight: '3px',
    color: '#2a2e49',
    '&.Mui-checked': {
      color: '#2a2e49',
    },
  },
  formGroup: {
    padding: '10px 5px',
    rowGap: '0.5rem',
  },
  switch: {
    '&.MuiSwitch-track': {
      backgroundColor: 'darkgrey',
      opacity: '0.5',
      '&.Mui-checked': {
        backgroundColor: '#292E48',
        opacity: '0.7',
      },
    },
    '&.MuiSwitch-thumb': {
      color: 'white',
      opacity: '0.7',
      backgroundColor: 'white',
    },
  },
  formControlRoot: {
    margin: theme.spacing(1),
  },
  formLabel: {
    marginRight: 0,
    textAlign: 'left',
    lineHeight: 1,
  },
}));

export default ({ map, hidden }) => {
  const classes = useStyles();
  const {
    filters,
    isFirstFilter,
    propVariables,
    operatorOnly,
    opportunities,
    resetState,
    setFilters,
    setOpportunities,
    toggleOperatorOnly,
    variables,
    showEaWells,
    setShowEaWells,
  } = useContext(MapFilterContext);
  const [screeningFeatures, setScreeningFeatures] = useState([]);
  const [screeningPointFeatures, setScreeningPointFeatures] = useState([]);

  const resetScreening = () => {
    resetState();
    map?.flyTo({
      center: [0, 0],
      zoom: 1.5,
    });
  };

  const handleFilter = (key, value) => {
    // if first screening filter zoom map
    if (isFirstFilter()) {
      key === 'country'
        ? map?.flyTo({
            center: getLatLongFromPoint(value[0].geom),
            zoom: 4,
          })
        : map?.flyTo({
            center: [0, 0],
            zoom: 1.5,
          });
    }
    const newFilters = { ...filters, [key]: value };
    const singleFilterDimensions = {
      ...dimensions(newFilters, 'maps'),
      event_category: 'Maps',
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [key]: value }),
      userId: getUserId(),
    };
    gtag('event', ANALYTICS_EVENTS.event, singleFilterDimensions);
    const filterDimensions = {
      ...dimensions(newFilters, 'maps'),
      event_category: 'Maps',
      event_action: ANALYTICS_EVENT_ACTIONS.filter,
      event_label: analytics(newFilters),
      userId: getUserId(),
    };
    gtag('event', ANALYTICS_EVENTS.event, filterDimensions);
    setFilters(newFilters);
  };

  const { loading, data, refetch } = useQuery(MAP_SCREENING_QUERY, { variables });

  useEffect(() => {
    refetch(variables);
  }, [filters]);

  const loadData = (screeningFeatures, screeningPointFeatures) => {
    if (loading) return;

    // add screening data
    const screeningSource = map?.getSource(MAP_SOURCE_IDS.screening);
    screeningSource?.setData({
      type: 'FeatureCollection',
      features: screeningFeatures,
    });

    // add screening cluster data
    const screeningClusterSource = map?.getSource(MAP_SOURCE_IDS.screeningCluster);
    screeningClusterSource?.setData({
      type: 'FeatureCollection',
      features: screeningPointFeatures,
    });
  };

  map?.on('style.load', () => {
    loadData(screeningFeatures, screeningPointFeatures);
  });

  useEffect(() => {
    if (!data) return;

    const features = [];
    const pointFeatures = [];
    data.mapScreening.forEach((a) => {
      if (!a.sectionGeomCoordinates?.length) {
        return;
      }

      const properties = {
        name: a.displayName,
        id: a.id,
        legacyId: a.legacyId,
        countryIsoCode: a.country.isoCode,
        country: a.country.name,
        entityType: a.entityType,
      };
      features.push({
        type: 'Feature',
        id: a.id,
        properties,
        geometry: {
          type: 'Polygon',
          coordinates: a.sectionGeomCoordinates,
        },
      });
      pointFeatures.push({
        type: 'Feature',
        id: a.id,
        properties,
        geometry: {
          type: 'Point',
          coordinates: getLatLongFromPoint(a.geom),
        },
      });
    });

    setScreeningFeatures(features);
    setScreeningPointFeatures(pointFeatures);
    loadData(features, pointFeatures);
  }, [data]);

  const handleShoreStatusChange = (selectedItems, { shoreStatus: allItems }) => {
    // Select/Deselect the 'Pre-salt' option if 'Deepwater' is selected/deselected:
    const preSalt = allItems.find((item) => item.name.toLowerCase() === 'pre-salt');
    const deepwater = allItems.find((item) => item.name.toLowerCase() === 'deepwater');
    const preSaltIndex = selectedItems.indexOf(preSalt);
    const deepwaterIndex = selectedItems.indexOf(deepwater);
    if (deepwaterIndex > -1 && preSaltIndex === -1) {
      selectedItems.push(preSalt);
    } else if (deepwaterIndex === -1 && preSaltIndex > -1) {
      selectedItems.splice(preSaltIndex, 1);
    }
    handleFilter('shoreStatus', selectedItems);
  };

  if (hidden) return null;

  return (
    <div className={classes.screening}>
      <Button variant="contained" className={classes.reset} onClick={resetScreening}>
        reset screening
      </Button>
      <Divider />
      <FormControl className={classes.formGroup} component="fieldset">
        <RadioGroup
          aria-label="m&aOpportunities"
          name="m&aOpportunities"
          value={opportunities}
          onClick={() => {
            if (!opportunities) {
              gtag('event', ANALYTICS_EVENTS.event, {
                event_category: 'Maps',
                event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                event_label: 'view_ma_opportunities',
                userId: getUserId(),
                ...defaultDimensions,
              });
            }
            setOpportunities(!opportunities);
          }}
        >
          <FormControlLabel
            className={classes.formLabel}
            value={true}
            control={<Radio className={classes.radio} />}
            label="View Potential M&A Opportunities"
          />
        </RadioGroup>
        <RadioGroup
          aria-label="e&aWells"
          name="e&aWells"
          value={showEaWells}
          onClick={() => {
            if (!showEaWells) {
              gtag('event', ANALYTICS_EVENTS.event, {
                event_category: 'Maps',
                event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                event_label: 'view_ea_wells',
                userId: getUserId(),
                ...defaultDimensions,
              });
            }
            setShowEaWells((eaWells) => !eaWells);
          }}
        >
          <FormControlLabel
            className={classes.formLabel}
            value={true}
            control={<Radio className={classes.radio} />}
            label="View E&A Wells to Watch"
          />
        </RadioGroup>
      </FormControl>
      <Divider style={{ marginBottom: '9px' }} />
      <AutoComplete
        styles={classes}
        getData={COUNTRIES.query}
        propVariables={COUNTRIES.propVariables(variables)}
        menuItems={null}
        onChange={(value) => handleFilter('country', value)}
        value={filters.country}
        label="Country/Area"
        optionKey="displayName"
        runQueryAfterOnChange={false}
        queryVariable={true}
        multiple={true}
      />
      <AutoComplete
        styles={classes}
        getData={BASINS_WITH_MAP_FILTER_QUERY}
        propVariables={propVariables}
        menuItems={null}
        onChange={(value) => handleFilter('basin', value)}
        value={filters.basin}
        label="Basin"
        optionKey="name"
        runQueryAfterOnChange={false}
        queryVariable={true}
        multiple={true}
      />
      <AutoComplete
        styles={classes}
        getData={COMPANIES_WITH_MAP_FILTER_QUERY}
        propVariables={propVariables}
        menuItems={null}
        onChange={(value) => handleFilter('company', value)}
        value={filters.company}
        label="Company"
        optionKey="name"
        runQueryAfterOnChange={false}
        queryVariable={true}
        multiple={true}
      />
      <AutoComplete
        styles={classes}
        getData={null}
        menuItems={[{ name: 'Oil' }, { name: 'Gas' }]}
        onChange={(value) => handleFilter('hydrocarbonType', value)}
        value={filters.hydrocarbonType}
        label="Hydrocarbon"
        optionKey="name"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={true}
      />
      <AutoComplete
        styles={classes}
        getData={SHORE_STATUS_QUERY}
        menuItems={null}
        onChange={handleShoreStatusChange}
        value={filters.shoreStatus}
        label="Shore Status"
        optionKey="name"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={true}
        hideItem={(item) => item.name.toLowerCase() === 'pre-salt'}
      />
      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={operatorOnly}
              onChange={toggleOperatorOnly}
              name="operatorOnly"
            />
          }
          label="Operators Only"
        />
      </FormGroup>
      <Divider style={{ margin: '10px 0 10px' }} />
      <FormControl className={classes.formControlRoot}>
        <Typography>Current Production (BOE/D)</Typography>
        <div className={classes.minMaxContainer}>
          <div className={classes.minMax}>
            <TextField
              className={classes.text}
              label="Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth={true}
              onChange={(e) => handleFilter('prodMin', e.currentTarget.value)}
              value={filters.prodMin}
            />
          </div>
          <div className={classes.minMax}>
            <TextField
              className={classes.text}
              label="Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth={true}
              onChange={(e) => handleFilter('prodMax', e.currentTarget.value)}
              value={filters.prodMax}
            />
          </div>
        </div>
      </FormControl>
      <FormControl className={classes.formControlRoot}>
        <Typography>NPV (US$MM)</Typography>
        <div className={classes.minMaxContainer}>
          <div className={classes.minMax}>
            <TextField
              className={classes.text}
              label="Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth={true}
              onChange={(e) => handleFilter('npvMin', e.currentTarget.value)}
              value={filters.npvMin}
            />
          </div>
          <div className={classes.minMax}>
            <TextField
              className={classes.text}
              label="Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth={true}
              onChange={(e) => handleFilter('npvMax', e.currentTarget.value)}
              value={filters.npvMax}
            />
          </div>
        </div>
      </FormControl>
      <FormControl className={classes.formControlRoot}>
        <Typography>Reserves (MMBOE)</Typography>
        <div className={classes.minMaxContainer}>
          <div className={classes.minMax}>
            <TextField
              className={classes.text}
              label="Min"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth={true}
              onChange={(e) => handleFilter('reservesMin', e.currentTarget.value)}
              value={filters.reservesMin}
            />
          </div>
          <div className={classes.minMax}>
            <TextField
              className={classes.text}
              label="Max"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth={true}
              onChange={(e) => handleFilter('reservesMax', e.currentTarget.value)}
              value={filters.reservesMax}
            />
          </div>
        </div>
      </FormControl>
    </div>
  );
};
