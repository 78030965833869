import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '../../RadioGroup';
import { layerItems } from './constants';
import { getQuery } from './operations';
import { layerProperties } from '../Utils/constants';
import { getUserPreferenceMapLayer, setUserPreferenceMapLayer } from './utils';
import CheckList from '../../CheckList';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: 'dimgrey',
    width: 'calc(100% - 10px)',
  },
  check: {
    color: 'white',
    '&.Mui-checked': {
      ...theme.palette.accentText,
    },
  },
  checkListForm: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  checkListRoot: {
    height: 'fit-content',
  },
  radioItemLabel: {
    width: '50%',
  },
  parentAndChildContainer: {
    width: '50%',
  },
}));

// defaultLayer - the default selected layer
// divider - flag to include the divider component
// items(optional) - array of map layer options to include (default baseLayerItems)
export default ({
  addLayer,
  defaultLayer,
  disableQuery,
  divider,
  filters,
  items = layerItems,
  label = 'HEAT MAPS',
  mapLoaded,
  multiple,
  removeLayer,
  setLegend,
  toggleLayer,
  toggleLayerCallback = () => {},
}) => {
  const classes = useStyles();
  const [layer, setLayer] = useState(getUserPreferenceMapLayer(defaultLayer, multiple));

  const { data } = useQuery(getQuery(layer), {
    variables: {
      filters,
      hydrocarbonType: layerProperties[layer]?.hydrocarbonType,
      layer,
    },
    skip: !layer || disableQuery,
  });

  const handleToggle = (event) => {
    // remove previous layer if exists
    if (layer) {
      removeLayer(layerProperties[layer]);
    }
    const newLayer = event.target.value;
    // if layer is same as previously selected, toggle off
    if (newLayer === layer) {
      setLayer('');
      setUserPreferenceMapLayer('');
      toggleLayerCallback(newLayer);
      setLegend({});
    } else {
      // set new layer
      setLayer(newLayer);
      setUserPreferenceMapLayer(newLayer);
      toggleLayerCallback(newLayer);
      setLegend(layerProperties[newLayer].legend);
    }
  };

  const handleMultipleToggle = (newLayer, toggledLayer) => {
    let visibility = '';

    // check if layer exists
    const layerIndex = newLayer.findIndex((l) => l.value === toggledLayer.value);
    // if layer does not exist, then toggle off
    if (layerIndex === -1) {
      visibility = 'none';
    } else {
      // if layer exists, then toggle on
      visibility = 'visible';
    }

    setLayer(newLayer);
    setUserPreferenceMapLayer(JSON.stringify(newLayer));
    toggleLayerCallback(newLayer);
    toggleLayer(layerProperties[toggledLayer.value], visibility);
  };

  useEffect(() => {
    if (mapLoaded && !multiple) {
      const dataKey = layerProperties[layer]?.dataKey;
      if (data && data[dataKey]?.layer === layer) {
        addLayer(layerProperties[layer], data[dataKey].feature);
      }
    }
  }, [data, mapLoaded, layer]);

  return (
    <>
      {multiple ? (
        <CheckList
          callback={handleMultipleToggle}
          checked={layer}
          label={label}
          list={items}
          nameKey="label"
          styles={classes}
        />
      ) : (
        <RadioGroup
          handleToggle={handleToggle}
          items={items}
          label={label}
          styles={classes}
          value={layer}
          disabled={!mapLoaded}
        />
      )}
      {divider && <Divider className={classes.divider} />}
    </>
  );
};
