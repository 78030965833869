import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import Divider from '@material-ui/core/Divider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SnackbarContext from '../SnackbarContext';

const useStyles = makeStyles((theme) => ({
  info: {
    ...theme.palette.secondaryAccentText,
    fontSize: '10px',
    overflowWrap: 'break-word',
    textTransform: 'none',
  },
  support: {
    color: '#2a2e49',
  },
  contact: {
    ...theme.palette.accentText,
    fontSize: '10px',
  },
  supportContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 20px',
    },
  },
  supportTextContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  supportButtonContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  chatButton: {
    ...theme.palette.actionCustom,
  },
}));

export default () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const copyEmail = () => {
    const el = document.createElement('input');
    el.value = 'info@welligence.com';
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
    setSnack({
      open: true,
      severity: 'success',
      message: 'Copied URL to clipboard!',
    });
  };

  return (
    <div>
      <Divider />
      <div className={classes.supportContainer}>
        <div className={classes.supportTextContainer}>
          <Typography variant="overline" className={classes.contact}>
            Customer Support
          </Typography>
          <Typography variant="overline" className={classes.info}>
            info@welligence.com
          </Typography>
        </div>
        <div className={classes.supportButtonContainer}>
          <Tooltip title="Open in Email" aria-label="open email">
            <IconButton
              aria-label="chat"
              onClick={() => (window.location.href = `mailto:info@welligence.com`)}
              className={classes.chatButton}
            >
              <ChatIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy Email to Clipboard" aria-label="copy email">
            <IconButton onClick={copyEmail}>
              <FileCopyIcon fontSize="small" color="secondary" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
