import React from 'react';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import { UserFilters } from '../Modals/UserFilters';
import { IconButton, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DropDown } from '../DropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  ADVANCED_FILTER_BUTTON_ID,
  OTHER_ACTIONS_BUTTON_ID,
  PAGE_ACTION_MAIN_ID,
  PRESET_BUTTON_ID,
} from './PageActions.constant';
import { usePageActionsStyles } from './PageActions.style';
import clsx from 'clsx';

export const PageActions = ({
  onOpenFilters,
  onClearFilters,
  presets,
  mainAction,
  actions,
  className,
  mainActionLabel = 'All Filters',
}) => {
  const classes = usePageActionsStyles();

  return (
    <div className={clsx(classes.buttonSection, className)}>
      <div className={classes.iconSection}>
        {onOpenFilters && (
          <Button
            id={ADVANCED_FILTER_BUTTON_ID}
            className={classes.button}
            onClick={onOpenFilters}
            variant={'contained'}
          >
            {mainActionLabel}
          </Button>
        )}
        <Tooltip title="Clear Filters">
          <IconButton onClick={onClearFilters} className={classes.iconButton}>
            <LayersClearIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        {presets && (
          <UserFilters
            id={PRESET_BUTTON_ID}
            filters={presets.filters}
            setFilters={presets.setFilters}
            context={presets.context}
            googleAnalyticsTag={presets.tag}
            includeShare={presets.includeShare}
            iconClass={classes.icon}
            iconButtonClass={classes.iconButton}
            label={presets.label}
            ShareComponent={presets.ShareComponent}
            LoadComponent={presets.LoadComponent}
            SaveComponent={presets.SaveComponent}
          />
        )}
      </div>
      {(!!mainAction || !!actions) && (
        <div className={classes.pageActions}>
          {!!mainAction && (
            <Tooltip title={mainAction.tooltip}>
              <span>
                <Button
                  id={PAGE_ACTION_MAIN_ID}
                  className={clsx(classes.buttonOutlined, {
                    [classes.buttonWithIcon]: mainAction.icon,
                  })}
                  startIcon={mainAction.icon}
                  variant="outlined"
                  onClick={mainAction.onClick}
                  disabled={mainAction.disabled}
                >
                  {mainAction.label}
                </Button>
              </span>
            </Tooltip>
          )}

          {!!actions && (
            <DropDown
              dropDownContent={
                <div style={{ color: 'white', padding: '.5rem' }}>
                  {actions.map((action) => (
                    <Button
                      key={action.label}
                      startIcon={action.icon}
                      variant="text"
                      onClick={action.onClick}
                      disabled={action.disabled}
                    >
                      {action.label}
                    </Button>
                  ))}
                </div>
              }
              iconButtonClass={classes.iconButton}
            >
              <MoreVertIcon id={OTHER_ACTIONS_BUTTON_ID} className={classes.icon} />
            </DropDown>
          )}
        </div>
      )}
    </div>
  );
};
