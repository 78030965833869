import CapexGuidanceTrackerImg from '../../../assets/images/welligence-assets/images/capex-guidance-tracker.svg';
import EnergyTransitionTargetsTrackerImg from '../../../assets/images/welligence-assets/images/energy-transition-targets-tracker.svg';
import LicensingImg from '../../../assets/images/welligence-assets/images/ma-licensing.svg';
import LicensingRoundImg from '../../../assets/images/welligence-assets/images/licensing-round-tracker.svg';
import LNGFacilitiesImg from '../../../assets/images/welligence-assets/images/lng-facilities.svg';
import OffshoreExplorationImg from '../../../assets/images/welligence-assets/images/offshore-exploration-tracker.svg';
import { IN_DEVELOPMENT_FLAG } from '../../utils/constants';

export const INDUSTRY_TRACKER_IMAGES = {
  'POTENTIAL M&A OPPORTUNITIES': LicensingImg,
  'EXPLORATION AND APPRAISAL WELL TRACKER': OffshoreExplorationImg,
  'LICENSING ROUND & DRO TRACKER': LicensingRoundImg,
  'CAPEX GUIDANCE TRACKER': CapexGuidanceTrackerImg,
  'ENERGY TRANSITION TARGETS TRACKER': EnergyTransitionTargetsTrackerImg,
  // TODO: [LNG Facilities] - remove the IN_DEVELOPMENT_FLAG when ready to go live
  'LNG FACILITIES': IN_DEVELOPMENT_FLAG ? LNGFacilitiesImg : null,
};
