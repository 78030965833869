import Grid from '@material-ui/core/Grid';
import React from 'react';
import SummaryListItem from './summaryListItem';
import Typography from '@material-ui/core/Typography';
import { getLabelUnitValueFromTemplate } from './SummaryItems/utils';

export default ({ data, group, label }) => {
  const SummaryListItemsContainer = () =>
    group.map((item) => {
      const { label: itemLabel, unit, value } = getLabelUnitValueFromTemplate(item, data);
      if (label === '' || value === '') return null;
      return (
        <SummaryListItem
          key={`summary-list-item-${label}-${itemLabel}`}
          label={itemLabel}
          value={value}
          unit={unit}
          variant="dotted"
        />
      );
    });

  return (
    <Grid container>
      <Typography variant="overline">{label}</Typography>
      <Grid container style={{ padding: '5px 0' }}>
        <SummaryListItemsContainer />
      </Grid>
    </Grid>
  );
};
