import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  assetListHeader: {
    ...theme.palette.secondaryBackground,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '30px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
    },
  },
  assetListHeaderText: {
    padding: '12px 36px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 10px',
    },
  },
  panelItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '35px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
    },
  },
  panelItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0',
    alignItems: 'center',
    cursor: 'pointer',
  },
  panelText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
  },
  panelSubHeader: {
    color: '#507180',
  },

  listIcon: {
    color: '#5f91cc',
  },
}));

export default ({ asset, panel, context }) => {
  const classes = useStyles();
  if (!asset) return null;
  const { Icon, title, list, id } = panel;
  const ListIcon = list.Icon;

  return (
    <div id={id}>
      <AppBar className={classes.assetListHeader} position="static">
        <Icon />
        <Typography className={classes.assetListHeaderText} variant="body1">
          {title}
        </Typography>
      </AppBar>
      <div className={classes.panelItemsContainer}>
        {list.titles.map((listTitle, i) => {
          return (
            <div
              className={classes.panelItem}
              key={`${context}-sidebar-${title}-${listTitle}-${i}`}
              onClick={list.onClick === undefined ? () => {} : list.onClick[i]}
            >
              <ListIcon className={classes.listIcon} />
              <div className={classes.panelText}>
                {list.headers !== undefined ? (
                  <Typography className={classes.panelSubHeader} variant="overline">
                    {list.headers[i]}
                  </Typography>
                ) : null}
                <Typography color="textPrimary" align="left">
                  {listTitle}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
