import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { analytics, dimensions } from '../../../utils/analytics';
import { filterTypes } from './filterTypes';
import FilterItem from './filterItem';
import ErrorBoundary from '../../ErrorBoundary';
import { GA_PAGE } from '../constants';
import { getUserId } from '../../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';


const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.altButton,
    height: 'calc(100% - 10px)',
    width: 'fit-content',
    minWidth: 'none',
  },
}));

export default ({ filters, setFilters, clearFilters }) => {
  const classes = useStyles();

  const setFilter = (value, key) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
    gtag(
      'event',
      ANALYTICS_EVENTS.event, {
        'event_category': GA_PAGE,
        'event_action': ANALYTICS_EVENT_ACTIONS.singleFilter,
        'event_label': analytics({ [key]: newFilters[key] }),
        'userId': getUserId(),
        ...dimensions(newFilters, 'countryOverviews'),
      }
    );
    gtag(
      'event',
      ANALYTICS_EVENTS.event, {
        'event_category': GA_PAGE,
        'event_action': ANALYTICS_EVENT_ACTIONS.filter,
        'event_label': analytics(newFilters),
        'userId': getUserId(),
        ...dimensions(newFilters, 'countryOverviews'),
      }
    );
  };

  return (
    <ErrorBoundary>
      <Grid container item xs={10} justifyContent="space-evenly">
        {filterTypes.map((filter, idx) => (
          <FilterItem
            key={`core-filter-${filter.name}`}
            filter={filter}
            propVariables={{ filters }}
            setFilter={setFilter}
            value={filters[filter.name]}
            xs={idx === 0 ? 7 : 5}
          />
        ))}
      </Grid>
      <Grid container item xs={2} direction="row" alignItems="center">
        <Button className={classes.button} variant="contained" onClick={clearFilters}>
          Clear
        </Button>
      </Grid>
    </ErrorBoundary>
  );
};
