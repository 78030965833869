import {
  COMPANIES_WITH_FILTER_QUERY,
  COUNTRIES_WITH_FILTER_QUERY,
} from '../../../Filters/operations';
import { FilterKeys, FilterLabels } from '../../../Filters';
import { MA_ASSETS_BY_NAME_WITH_FILTER_QUERY, MA_EVENT_TYPES_QUERY } from '../../operations';

export const GOOGLE_ANALYTICS_TAG = 'MA_Analytics';

const getMaPropVariables = (filters) => ({
  countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
  eventTypes: filters[FilterKeys.eventType]?.map((e) => e.id) || [],
  buyers: filters[FilterKeys.companiesBuyer]?.map((c) => Number(c.id)) || [],
  sellers: filters[FilterKeys.companiesSeller]?.map((c) => Number(c.id)) || [],
  startYear: filters.dateSlider?.[0] || null,
  endYear: filters.dateSlider?.[1] || null,
});

const getCompaniesPropVariables = (filters) => ({
  assets: filters[FilterKeys.assets] || [], // the query expects an array of asset objects instead of ids
  countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
  basins: filters[FilterKeys.basins]?.map((b) => b.id) || [],
  activeInBlocks: !!filters[FilterKeys.activeInBlocks],
  activeInLeases: !!filters[FilterKeys.activeInLeases],
  includeHistoricalParticipants: true,
  operator: false,
});

const getCountriesPropVariables = (filters) => ({
  // merge buyer and seller companies into one array
  companies:
    filters[FilterKeys.companiesBuyer]?.map((c) => c.name) +
      filters[FilterKeys.companiesSeller]?.map((c) => c.name) || [],
  assets: filters[FilterKeys.assets] || [], // the query expects an array of asset objects instead of ids
  basins: filters[FilterKeys.basins]?.map((b) => b.id) || [],
  operator: false,
  hasAssets: false,
  hasGhgEmissions: false,
  hasMaTransactions: true,
});

const SELLER_COMPANIES = {
  key: FilterKeys.companiesSeller,
  multiple: true,
  name: FilterLabels.companiesSeller,
  optionKey: 'name',
  propVariables: (filters) => ({
    ...getCompaniesPropVariables(filters),
    maBuyers: false,
    maSellers: true,
  }),
  query: COMPANIES_WITH_FILTER_QUERY,
  runQueryAfterOnChange: false,
};

const BUYER_COMPANIES = {
  key: FilterKeys.companiesBuyer,
  multiple: true,
  name: FilterLabels.companiesBuyer,
  optionKey: 'name',
  propVariables: (filters) => ({
    ...getCompaniesPropVariables(filters),
    maBuyers: true,
    maSellers: false,
  }),
  query: COMPANIES_WITH_FILTER_QUERY,
  runQueryAfterOnChange: false,
};

const EVENT_TYPES = {
  excelLike: true,
  groupByName: 'Type',
  groupOptionKey: 'group',
  key: FilterKeys.eventType,
  mapQueryData: (data) => data?.maDealTypes,
  multiple: true,
  name: FilterLabels.eventType,
  optionKey: 'name',
  propVariables: getMaPropVariables,
  query: MA_EVENT_TYPES_QUERY,
  runQueryAfterOnChange: false,
};

const ASSETS = {
  key: FilterKeys.assets,
  multiple: true,
  name: FilterLabels.assets,
  optionKey: 'menuName',
  propVariables: getMaPropVariables,
  query: MA_ASSETS_BY_NAME_WITH_FILTER_QUERY,
  queryVariable: 'search',
  responseCallback: (res) => res?.assets || [],
  runQueryAfterOnChange: true,
};

const COUNTRIES = {
  excelLike: true,
  groupByName: 'Region',
  groupOptionKey: 'region',
  key: FilterKeys.countries,
  mapQueryData: (data) => data?.countriesWithFilter,
  multiple: true,
  name: FilterLabels.countries,
  optionKey: 'displayName',
  propVariables: getCountriesPropVariables,
  query: COUNTRIES_WITH_FILTER_QUERY,
  queryVariable: null,
  runQueryAfterOnChange: false,
};

export const FILTER_TYPES = [COUNTRIES, EVENT_TYPES, BUYER_COMPANIES, SELLER_COMPANIES, ASSETS];
