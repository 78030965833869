import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { defaultDimensions } from '../../../../../utils/analytics';
import { LOAD_FILTER } from './LoadFilter.query';
import Button from '@material-ui/core/Button';
import { getUserId } from '../../../../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '250px',
    margin: '10px',
  },
  input: {
    width: '250px',
    margin: '10px',
  },
  button: {
    ...theme.palette.button,
    margin: '10px 0 20px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const LoadFilter = ({ setFilters, context, googleAnalyticsTag, handleClose }) => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState('');

  const { loading, data, refetch } = useQuery(LOAD_FILTER, {
    variables: { type: context },
  });

  const loadFilters = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'load_filter',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setFilters(selectedFilter.filter, selectedFilter.name);
    handleClose();
  };

  useEffect(() => {
    refetch && refetch();
  }, []);

  return (
    <div className={classes.content}>
      <FormControl className={classes.select} variant="filled">
        <InputLabel>Filters</InputLabel>
        <Select
          disableUnderline
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
        >
          {!loading && data
            ? data.userFilters.map((filter) => (
                <MenuItem key={filter.name} value={filter}>
                  {filter.name}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
      <div className={classes.buttonWrapper}>
        <Button
          disabled={!selectedFilter.filter}
          variant="contained"
          className={classes.button}
          onClick={loadFilters}
        >
          Load Filter
        </Button>
      </div>
    </div>
  );
};
