// this file will be deprecated in favor of plotly
/* eslint no-unused-vars: 0 */
import React, { useEffect, useState, useRef } from 'react';
import Dygraph from 'dygraphs';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { formatDate, formatDateYear } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  graph: {
    width: '100%',
    display: 'flex',
  },
}));

export default ({ csv, colors, labels, title, ylabel, stackedGraph }) => {
  const classes = useStyles();
  const graphEl = useRef(null);

  const [graph, setGraph] = useState(null);

  const legendFormatter = (data) => {
    if (data.x === undefined) {
      return;
    }

    const date = formatDate(data.xHTML);
    let html = `<div>${date}</div>`;
    data.series.forEach((series) => {
      const yHTML = series.yHTML === undefined ? 'Undefined' : series.yHTML;
      const labeledData = `
        <div style="padding-top:1px;">
          <div style="display:flex;line-height:20px">${series.dashHTML} ${series.labelHTML}:</div>
          <div>${yHTML}</div>
        </div>`;
      html += labeledData;
    });
    return html;
  };

  const axisLabelFormatter = (x) => {
    return formatDateYear(x);
  };

  useEffect(() => {
    const g = new Dygraph(graphEl.current, csv, {
      legend: 'follow',
      hideOverlayOnMouseOut: true,
      showRangeSelector: true,
      strokeWidth: 1,
      title,
      legendFormatter,
      axes: {
        x: {
          axisLabelFormatter,
        },
      },
      colors: colors ? colors : null,
      labels,
      ylabel,
      stackedGraph: stackedGraph ? true : false,
    });
    setGraph(g);
  }, []);

  return <Grid className={classes.graph} item xs ref={graphEl} />;
};
