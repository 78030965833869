import React, { createContext, useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CURRENT_USER_QUERY } from '../CurrentUserContext/operations';
import { useHistory } from 'react-router-dom';
import { isValidGALink } from '../../utils/analytics';
import { getUserId, isAuthenticated } from '../../utils/auth';
import {
  ANALYTICS_EVENTS,
  CCUS_PATHS,
  CCUS_SUBSCRIPTION,
  M_A_PATH,
  M_A_SUBSCRIPTION,
  PUBLIC_PATHS,
  WEB_BASIC_PATHS,
  WEB_BASIC_SUBSCRIPTION,
} from '../../utils/constants';
import SnackbarContext from '../SnackbarContext';
import { curateLocation } from '../../utils/helpers';

const CurrentUserContext = createContext();

export const CurrentUserProvider = (props) => {
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const [showDownloadLimitMessage, setShowDownloadLimitMessage] = useState(false);

  useEffect(() => {
    // need to send if url typed directly (not on route change listener)
    // i.e. oil and gas map opens in new tab
    if (isValidGALink(location.pathname)) {
      gtag('event', ANALYTICS_EVENTS.pageview, { page: location.pathname, userId: getUserId() });
    }
    // listen for route changes
    return history.listen((location) => {
      if (isValidGALink(location.pathname)) {
        gtag('event', ANALYTICS_EVENTS.pageview, { page: location.pathname, userId: getUserId() });
      }
    });
  }, [history]);

  const {
    data: userData,
    loading: userLoading,
    refetch: refetchUserData,
  } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only',
  });

  const refreshProvider = () => {
    refetchUserData();
  };

  const currentUser =
    userLoading || !userData || !userData.currentUserProfile ? {} : userData.currentUserProfile;

  if (!userLoading && userData && userData.currentUserProfile) {
    gtag('set', { userId: getUserId() });
  }

  const { downloadMessage, featuresEntitled, mapCcusTilesetSources, mapTilesetSources } =
    currentUser;

  const flashDownloadLimitMessage = () => {
    refreshProvider();
    setShowDownloadLimitMessage(true);
  };

  const isPublicPath = (location) => location === '/' || PUBLIC_PATHS.includes(location);

  const isUserEntitled = (location) => {
    if (isPublicPath(location)) return true;
    const curatedPath = curateLocation(location);
    if (WEB_BASIC_PATHS.includes(curatedPath) && featuresEntitled?.includes(WEB_BASIC_SUBSCRIPTION))
      return true;
    if (CCUS_PATHS.includes(curatedPath) && featuresEntitled?.includes(CCUS_SUBSCRIPTION))
      return true;
    if (M_A_PATH.includes(curatedPath) && featuresEntitled?.includes(M_A_SUBSCRIPTION)) return true;
    return false;
  };

  useEffect(() => {
    if (downloadMessage && showDownloadLimitMessage) {
      setSnack({ open: true, message: downloadMessage, severity: 'success' });
      setShowDownloadLimitMessage(false);
    }
  }, [userData]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        userLoading,
        flashDownloadLimitMessage,
        isUserEntitled,
        refreshProvider,
        mapCcusTilesetSources,
        mapTilesetSources,
      }}
    >
      {(!isAuthenticated() || isPublicPath(location.pathname) || featuresEntitled) &&
        props.children}
    </CurrentUserContext.Provider>
  );
};

export const CurrentUserConsumer = CurrentUserContext.Consumer;

export default CurrentUserContext;
