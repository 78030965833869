import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { SAVE_FILTER } from './SaveFilter.query';
import { defaultDimensions } from '../../../../../utils/analytics';
import SnackbarContext from '../../../../SnackbarContext';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { getUserId } from '../../../../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../../utils/constants';
import { joinGqlErrorMessage } from '../../../../../utils/gqlErrors';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '250px',
    margin: '10px',
  },
  button: {
    ...theme.palette.button,
    margin: '10px 0 20px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const SaveFilter = ({ googleAnalyticsTag, context, filters, handleClose }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const [saveFilter] = useMutation(SAVE_FILTER, {
    variables: { filter: filters, name, type: context },
    onCompleted: () => {
      setLoading(false);
      setSnack({
        open: true,
        severity: 'success',
        message: `Successfully saved filter '${name}'!`,
      });
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      setLoading(false);
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  const saveFilters = () => {
    setLoading(true);
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'save_filter',
      userId: getUserId(),
      ...defaultDimensions,
    });
    saveFilter();
  };

  return (
    <div className={classes.content}>
      <TextField
        className={classes.input}
        style={{ width: '250px', margin: '10px' }}
        label="Filter Name"
        variant="filled"
        onChange={(e) => setName(e.target.value)}
        defaultValue={name}
      />
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={saveFilters}
          disabled={loading}
        >
          Save Filter
        </Button>
      </div>
    </div>
  );
};
