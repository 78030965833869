import { FilterKeys } from '../../../../Filters';
import { MA_TRANSACTIONS_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaTransactions = (page, perPage, order, orderBy) => {
  const { filters } = useFilters();

  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.dateSlider || [];

    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      startYear: startYear,
      endYear: endYear,
    };
  }, [filters, page, perPage, order, orderBy]);

  const { data, loading, error } = useQuery(MA_TRANSACTIONS_QUERY, {
    variables: {
      page: page + 1,
      perPage,
      order,
      orderBy,
      ...filterVariables,
    },
  });

  return {
    data: data?.maTransactions?.transactions || [],
    totalCount: data?.maTransactions?.totalCount || 0,
    loading,
    error,
  };
};
