import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShinyLink } from '../../Shiny';
import clsx from 'clsx';
import { DATA_DOWNLOADS_PATH } from '../../../utils/constants';
import CurrentUserContext from '../../CurrentUserContext';
import { MenuItem } from '@material-ui/core';
import { useMenuButtonStyles } from './MenuButton.style';
import { useMemo } from 'react';
import { OpenInNewOutlined } from '@material-ui/icons';

export const MenuButton = ({ link, item, onClick, forceNewTab, className }) => {
  const classes = useMenuButtonStyles();
  const location = useLocation();
  const path = location.pathname;

  const handleMenuItemClick = (event) => {
    event.target.children[0]?.click();
  };

  const target = useMemo(() => {
    if (forceNewTab) {
      return '_blank';
    }
    return link.target;
  }, [forceNewTab, link.target]);

  const { isUserEntitled } = useContext(CurrentUserContext);

  const isSelectedLink = path.indexOf(item.link) !== -1;

  return (
    <MenuItem
      className={clsx(classes.menuItem, {
        [classes.inactiveMenuItem]: !isUserEntitled(item.link),
      })}
      key={item.title}
      onClick={handleMenuItemClick}
    >
      {item.type === 'shiny' ? (
        <ShinyLink
          link={item}
          styles={{ link: classes.link }}
          component="link"
          disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
          onClickCallback={onClick}
          className={className}
          endAdornment={<OpenInNewOutlined className={classes.iconExternal} />}
        />
      ) : (
        <Link
          onClick={onClick}
          to={isUserEntitled(item.link) ? `${item.link}` : '#'}
          target={target}
          className={clsx(classes.link, className, {
            [classes.inactiveLink]: !isUserEntitled(item.link),
            [classes.selectedLink]: isSelectedLink,
          })}
        >
          {item.title}
        </Link>
      )}
    </MenuItem>
  );
};
