import { makeStyles } from '@material-ui/core/styles';
import { MEDIA_QUERY_FIELD_ALWAYS_VISIBLE } from '../GlobalSearch.constant';

export const useSearchFieldStyles = makeStyles((theme) => ({
  searchIcon: {
    position: 'absolute',
    left: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.baseColors.deepSeaNavy.c50,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  inputWrapper: {
    position: 'fixed',
    left: '80px',
    top: '12px',
    background: 'white',
    right: '20px',
    zIndex: '1',
    [theme.breakpoints.up(MEDIA_QUERY_FIELD_ALWAYS_VISIBLE)]: {
      position: 'relative',
      left: 'unset',
      top: 'unset',
      right: 'unset',
      width: '100%',
      maxWidth: '1232px',
    },
    [theme.breakpoints.down('sm')]: {
      left: '55px',
    },
    '@media (max-width: 750px)': {
      right: '70px',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.baseColors.deepSeaNavy.c75,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.baseColors.deepSeaNavy.c50,
      paddingLeft: '1.3rem',
      transition:
        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-root.MuiFormLabel-filled': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-input:focus': {
      color: theme.palette.text.primary,
    },
  },
  closeIcon: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: '0',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  historyIcon: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: '1.8rem',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  historyPopper: {
    zIndex: 10000,
    padding: '.5rem 1.5rem',
    borderRadius: '5px',
    boxShadow: '0px 2px 5px 0px ' + theme.palette.baseColors.deepSeaNavy.c100,
  },
}));
