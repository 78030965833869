import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Treemap from '../../../Graphs/treemap';
import Modal from '../../../Modals';
import GraphContainer from '../../../Graphs/graphContainer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
}));

export default ({ context }) => {
  const classes = useStyles();
  const {
    setOpenControlsModal,
    ids,
    labels,
    loading,
    parents,
    values,
    customdata,
    hovertemplate,
    downloadCallback,
    title,
    onClick,
    zoomedNode,
    texttemplate,
    googleAnalyticsTag,
    message,
  } = useContext(context);
  const [open, setOpen] = useState(false);
  const showToggle = false;

  return (
    <>
      <GraphContainer
        open={open}
        setOpen={() => setOpen(true)}
        setOpenControlsModal={setOpenControlsModal}
        showToggle={showToggle}
        title={title}
        loading={loading}
        downloadCallback={downloadCallback}
        googleAnalyticsTag={googleAnalyticsTag}
      >
        <Treemap
          ids={ids}
          labels={labels}
          parents={parents}
          values={values}
          layout={{
            margin: { l: 5, r: 5, b: 10, t: 20 },
          }}
          hovertemplate={hovertemplate}
          customdata={customdata}
          loading={loading}
          onClick={onClick}
          level={zoomedNode}
          texttemplate={texttemplate}
          title={message}
        />
      </GraphContainer>
      <Modal
        handleClose={() => setOpen(false)}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose={true}
        styles={classes}
        disablePortal
      >
        <Grid container className={classes.root}>
          <GraphContainer
            open={open}
            setClose={() => setOpen(false)}
            setOpenControlsModal={setOpenControlsModal}
            showToggle={showToggle}
            title={title}
            loading={loading}
            downloadCallback={downloadCallback}
            googleAnalyticsTag={googleAnalyticsTag}
          >
            <Treemap
              ids={ids}
              labels={labels}
              parents={parents}
              values={values}
              layout={{
                margin: { l: 5, r: 5, b: 10, t: 20 },
              }}
              hovertemplate={hovertemplate}
              customdata={customdata}
              loading={loading}
              onClick={onClick}
              level={zoomedNode}
              texttemplate={texttemplate}
              title={message}
            />
          </GraphContainer>
        </Grid>
      </Modal>
    </>
  );
};
