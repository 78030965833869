import {
  CCUS_PATH,
  GHG_PATH,
  COUNTRY_OVERVIEWS_PATH,
  DATA_DOWNLOADS_PATH,
  ENGINEER_DEVELOPMENT_FLAG,
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_TABLE_PATH,
  OIL_AND_GAS_MAP_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  VALUATIONS_PATH,
} from '../../utils/constants';

const dataDownloads = ENGINEER_DEVELOPMENT_FLAG
  ? {
      title: 'Data downloads',
      type: 'menu',
      menuItems: [
        {
          title: 'Shiny downloads',
          page: 'download',
          type: 'shiny',
        },
        {
          title: 'Downloads alpha',
          link: DATA_DOWNLOADS_PATH,
          type: 'link',
          target: '',
        },
      ],
    }
  : {
      title: 'Data downloads',
      page: 'download',
      type: 'shiny',
    };

const mAndAPlus = [
  {
    title: 'M&A analytics',
    type: 'menu',
    menuItems: [
      {
        title: 'Overview',
        link: M_A_OVERVIEW_PATH,
        type: 'link',
      },
      {
        title: 'Transaction table',
        link: M_A_TRANSACTION_TABLE_PATH,
        type: 'link',
      },
      {
        title: 'Transaction analytics',
        link: M_A_TRANSACTION_ANALYTICS_PATH,
        type: 'link',
      },
      {
        title: 'Company analysis',
        link: M_A_COMPANY_BENCH_MARKING_PATH,
        type: 'link',
      },
    ],
  },
];

export default [
  {
    title: 'Mapping tools',
    type: 'menu',
    menuItems: [
      {
        title: 'Oil and gas map',
        link: OIL_AND_GAS_MAP_PATH,
        type: 'link',
      },
      {
        title: 'Heat maps',
        page: 'map',
        type: 'shiny',
      },
    ],
  },
  {
    title: 'Upstream assets',
    type: 'menu',
    menuItems: [
      {
        title: 'Valuations and reports',
        link: VALUATIONS_PATH,
        type: 'link',
      },
      {
        title: 'Technical analysis',
        page: 'fields',
        type: 'shiny',
      },
    ],
  },
  {
    title: 'Macro analysis',
    type: 'menu',
    menuItems: [
      {
        title: 'Country overviews',
        link: COUNTRY_OVERVIEWS_PATH,
        type: 'link',
      },
      {
        title: 'Portfolio analysis',
        link: PORTFOLIO_ANALYSIS_PATH,
        type: 'link',
      },
      {
        title: 'Greenhouse gas analytics',
        link: GHG_PATH,
        type: 'link',
      },
      {
        title: 'CCUS',
        link: CCUS_PATH,
        type: 'link',
      },
    ],
  },
  dataDownloads,
  ...mAndAPlus,
  {
    title: 'Intelligence',
    link: 'https://welligence.com/intelligence',
    type: 'href',
  },
];
