import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import OpenInNew from '@material-ui/icons/OpenInNew';
import { Button, Tooltip } from '@material-ui/core';
import { useResultCardController } from './ResultCard.controller';
import AssessmentIcon from '@material-ui/icons/Assessment';
import RoomIcon from '@material-ui/icons/Room';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DownloadIcon from '@material-ui/icons/GetApp';
import PublicIcon from '@material-ui/icons/Public';
import { generateFulltextLinkMenu } from '../../../../../helper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    '&:hover': {
      backgroundColor: '#e6ebee',
    },
  },
  linksWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  button: { padding: '0 10px' },
}));

const getPageText = (page) => `${page} in new window`;
export const getActionTooltip = (action) => {
  switch (action) {
    case 'oil-and-gas': {
      return getPageText('Oil and Gas map');
    }
    case 'oil-and-gas-lower': {
      return getPageText('Oil and Gas map');
    }
    case 'oil-and-gas-networks': {
      return getPageText('Oil and Gas map');
    }
    case 'country-overview': {
      return getPageText('Country Overviews page');
    }
    case 'valuations': {
      return getPageText('Valuations and Reports page');
    }
    // Temporary case to by-pass weirdly
    // functioning valuations page
    case 'asset-valuations': {
      return getPageText('Valuations and Reports page');
    }
    case 'portfolio-analysis': {
      return getPageText('Portfolio Analysis tool');
    }
    case 'download-asset-report':
      return 'Download asset report';
    case 'download-valuation-model':
      return 'Download asset model';
    case 'download-country-report':
      return 'Download country report';
  }
  return '';
};

export const getActionButtonIcon = (action) => {
  // Currently only once icon is used, once
  // we add download functionality for some action,
  // more icons will be used
  switch (action) {
    case 'oil-and-gas': {
      return <RoomIcon fontSize="small" />;
    }
    case 'oil-and-gas-lower': {
      return <RoomIcon fontSize="small" />;
    }
    case 'oil-and-gas-networks': {
      return <RoomIcon fontSize="small" />;
    }
    case 'country-overview': {
      return <PublicIcon fontSize="small" />;
    }
    case 'valuations': {
      return <AssessmentIcon fontSize="small" />;
    }
    case 'asset-valuations': {
      return <AssessmentIcon fontSize="small" />;
    }
    case 'portfolio-analysis': {
      return <MenuBookIcon fontSize="small" />;
    }
    case 'download-asset-report':
    case 'download-valuation-model':
    case 'download-country-report':
      return <DownloadIcon fontSize="small" />;
  }
  return <OpenInNew fontSize="small" />;
};

export const getRowDescription = (action, desc) => {
  return ' - ' + desc;
};

export const ResultCard = ({ row, onActionClick }) => {
  const classes = useStyles();
  const link = generateFulltextLinkMenu(row);
  const { download } = useResultCardController(row);

  return (
    <Box className={classes.root}>
      <Box>
        <Box>
          <Typography variant="subtitle2" component="span">
            {row.name}
          </Typography>
          <Typography variant="caption" component="span">
            {getRowDescription(row.action, row.description)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.linksWrapper}>
        {download ? (
          <Tooltip title={getActionTooltip(row.action)}>
            <Button
              className={classes.button}
              onClick={(e) => {
                onActionClick({
                  download: true,
                  row,
                });
                download(e);
              }}
              variant={'contained'}
              color="secondary"
              disabled={row.action === 'oil-and-gas' || row.action === 'oil-and-gas-lower'}
              startIcon={getActionButtonIcon(row.action)}
            >
              DOWNLOAD
            </Button>
          </Tooltip>
        ) : link ? (
          <Tooltip title={getActionTooltip(row.action)}>
            <a
              href={link.link}
              onClick={() =>
                onActionClick({
                  download: false,
                  row,
                })
              }
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className={classes.button}
                variant={'contained'}
                color="secondary"
                startIcon={getActionButtonIcon(row.action)}
              >
                OPEN
              </Button>
            </a>
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};
