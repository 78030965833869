import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';

import AssetListItem from '../../../Valuations/AssetList/assetListItem';
import { WATCH_LIST_QUERY } from './operations';
import WalkthroughContext from '../../../WalkthroughContext';
import { VALUATIONS_PATH } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 0,
  },
  layersAccordion: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
    fontWeight: 'bold',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { walkthrough } = useContext(WalkthroughContext);

  const [expanded, setExpanded] = useState(true);

  const { loading, data } = useQuery(WATCH_LIST_QUERY);

  const setAsset = (asset) => {
    history.push(`${VALUATIONS_PATH}/${asset.country.isoCode}/${asset.legacyId}`);
  };

  useEffect(() => {
    if (walkthrough) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [walkthrough]);

  return (
    <Accordion
      id="watchlist-accordion"
      square={true}
      expanded={expanded}
      onChange={(e, exp) => setExpanded(exp)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-layers">
        <ListAltIcon fontSize="small" />
        &nbsp;&nbsp;
        <Typography className={classes.label} variant="caption">
          WATCHLIST
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <div className={classes.assetListItemsContainer}>
            {data.watchList.map((asset) => (
              <AssetListItem
                key={asset.displayName}
                asset={asset}
                setAsset={setAsset}
                isSelected={false}
                isWatchList={true}
                sortType={{ filter: 'name' }}
              />
            ))}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
