
import React, { useContext, useEffect, useState } from 'react';

import Slide from '@material-ui/core/Slide';
import { Button, Typography } from '@material-ui/core';
import * as htmlToImage from 'html-to-image';
import { IN_DEVELOPMENT_FLAG } from '../../../utils/constants.js';
import Loading from '../../Loading/index.js';
import { getCsrfToken } from '../../../utils/auth.js';
import { CameraAlt, Check, Save } from '@material-ui/icons';
import { useStyles } from './ScreenshotMode.style.js';
import CurrentUserContext from '../../CurrentUserContext';

const ScreenshotModeInner = ({ item }) => {
  const [assetViewUploadSuccess, setAssetViewUploadSuccess] = useState(false);
  const [countryViewUploadSuccess, setCountryViewUploadSuccess] = useState(false);
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [assetViewImg, setAssetViewImg] = useState('');
  const [countryViewImg, setCountryViewImg] = useState('');
  const blackSquare =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/akXKAAAAABJRU5ErkJggg==';

  useEffect(() => {
    setAssetViewUploadSuccess(false);
    setCountryViewUploadSuccess(false);
  }, [item]);

  const hasPermissions = () => {
    return item?.entityType === 'asset' && IN_DEVELOPMENT_FLAG && currentUser.admin;
  };

  const createBlobImage = (imgElement) => {
    const imageData = imgElement.src;

    const base64Data = imageData.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return blob;
  };

  const saveImageToS3 = async (imageType) => {
    const pictureType = imageType === 'assetViewImg' ? 'asset_view_picture' : 'country_view_picture';
    const imgElement = document.querySelector(`#${imageType}`);
    const imgBlob = createBlobImage(imgElement);
    const formData = new FormData();
    formData.append(imageType, imgBlob, `${imageType}.png`);
    try {
      const response = await fetch(
        `${window.location.origin}/data-upload/send_image_to_s3?legacy_id=${item.legacyId}&asset_name=${item.name}&country=${item.countryIsoCode}&image_type=${pictureType}`,
        {
          method: 'POST',
          headers: {
            'X-CSRF-Token': getCsrfToken(),
          },
          body: formData,
        },
      );

      if (response.status !== 200) {
        const errorResponse = response.json();
        throw new Error(errorResponse.errors);
      }
      if (imageType === 'assetViewImg') {
        setAssetViewUploadSuccess(true);
      } else {
        setCountryViewUploadSuccess(true);
      }
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      alert('Failed to upload image to S3.');
    }
  };

  const joinImages = async () => {
    const assetViewImg = document.querySelector('#assetViewImg');
    const countryViewImg = document.querySelector('#countryViewImg');

    if (assetViewImg && countryViewImg) {
      const formData = new FormData();
      const assetViewBlob = createBlobImage(assetViewImg);
      const countryViewBlob = createBlobImage(countryViewImg);
      formData.append('assetViewImg', assetViewBlob, 'assetViewImg.png');
      formData.append('countryViewImg', countryViewBlob, 'countryViewImg.png');

      try {
        const response = await fetch(
          `${window.location.origin}/data-upload/join_images?legacy_id=${item.legacyId}&asset_name=${item.name}&country=${item.countryIsoCode}`,
          {
            method: 'POST',
            headers: {
              'X-CSRF-Token': getCsrfToken(),
            },
            body: formData,
          },
        );

        if (response.status !== 200 && response.status !== 204) {
          const errorResponse = response.json();
          throw new Error(errorResponse.errors);
        }

        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          setCountryViewImg(base64data);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error joining images: ', error);
      }
    }
  };

  const takePicture = (isAssetViewImg) => {
    var node = document.getElementById('mapContainer');
    const height = 666;
    const width = 748;

    const filter = (node) => {
      const exclusionClasses = ['mapboxgl-ctrl-bottom-right', 'mapboxgl-ctrl-bottom-left'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    };

    htmlToImage
      .toPng(node, {
        pixelRatio: 0.8,
        height: height,
        width: width,
        filter: filter,
      })
      .then((dataUrl) => {
        if (isAssetViewImg) {
          setAssetViewImg(dataUrl);
          setAssetViewUploadSuccess(false);
        } else {
          setCountryViewImg(dataUrl);
          joinImages();
          setCountryViewUploadSuccess(false);
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const imgAssetViewLoaded = () => {
    const imgElement = document.querySelector('#assetViewImg');
    return imgElement && imgElement.src && imgElement.src.split(',').length > 1;
  };

  const imgCountryViewLoaded = () => {
    const imgElement = document.querySelector('#countryViewImg');
    return (
      imgElement &&
      imgElement.src &&
      imgElement.src.split(',').length > 1 &&
      imgElement.src !== blackSquare
    );
  };

  return (
    <div>
      <div className={classes.scroll}>
        {hasPermissions() && (
          <div className={classes.modal}>
            <div>
              <div>
                <Typography className={classes.rootLabel} variant="body1" component="p">
                  <Typography className={classes.label} variant="body1" component="span">
                    Asset Name:
                  </Typography>
                  {item.name}
                </Typography>
                <Typography className={classes.rootLabel} variant="body1" component="p">
                  <Typography className={classes.label} variant="body1" component="span">
                    Country / Area:
                  </Typography>
                  {item.countryIsoCode}
                </Typography>
              </div>

              <div className={classes.imagesContainer}>
                <div>
                  <Typography className={classes.title} variant="subtitle1" component="h5">
                    Asset View
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      id="assetViewImg"
                      src={assetViewImg || blackSquare}
                      alt="Asset View Image"
                      className={classes.screenshot}
                    />
                  </div>
                  <div className={classes.buttonsBar}>
                    <div className={classes.countryViewButtons}>
                      <Button className={classes.button} onClick={() => takePicture(true)}>
                        <CameraAlt
                          fontSize="small"
                          color="primary"
                          className={classes.orangeIcon}
                        />
                        <div>{imgAssetViewLoaded() ? `Retake` : `Take Screenshot`}</div>
                      </Button>
                      {imgAssetViewLoaded() && (
                        <Button
                          className={classes.button}
                          disabled={assetViewUploadSuccess}
                          onClick={() => saveImageToS3('assetViewImg')}
                        >
                          {assetViewUploadSuccess ? (
                            <Check />
                          ) : (
                            <Save
                              fontSize="small"
                              color="primary"
                              className={classes.orangeIcon}
                            />
                          )}
                          <div>Save</div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <Typography className={classes.title} variant="subtitle1" component="h5">
                    Country View
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      id="countryViewImg"
                      src={countryViewImg || blackSquare}
                      alt="Country View Image"
                      className={classes.screenshot}
                    />
                  </div>
                  <div className={classes.buttonsBar}>
                    {imgAssetViewLoaded() && (
                      <div className={classes.countryViewButtons}>
                        <Button className={classes.button} onClick={() => takePicture(false)}>
                          <CameraAlt
                            fontSize="small"
                            color="primary"
                            className={classes.orangeIcon}
                          />
                          <div>{imgCountryViewLoaded() ? `Retake` : `Take Screenshot`}</div>
                        </Button>
                        {imgCountryViewLoaded() && (
                          <Button
                            className={classes.button}
                            disabled={countryViewUploadSuccess}
                            onClick={() => saveImageToS3('countryViewImg')}
                          >
                            {countryViewUploadSuccess ? (
                              <Check />
                            ) : (
                              <Save
                                fontSize="small"
                                color="primary"
                                className={classes.orangeIcon}
                              />
                            )}
                            <div>Save</div>
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.screenshotPreview} />
    </div>
  );
};

export const ScreenshotMode = ({ isOpen, item }) => {
  const classes = useStyles();

  return (
    <Slide
      direction="left"
      in={isOpen}
      mountOnEnter
      unmountOnExit
      className={classes.screenshotMode}
    >
      <div>
        {item ? (
          <ScreenshotModeInner item={item} />
        ) : (
          <div className={classes.loading}>
            <Loading />
          </div>
        )}
      </div>
    </Slide>
  );
};