import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loading: {
    ...theme.palette.loading,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress color="secondary" />
    </div>
  );
};
