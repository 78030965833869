import { MAP_LAYER_TYPES } from '../Map/Utils/constants';
import {
  COMMERCIAL_ASSETS_COUNTRY_SUMMARY_ITEM,
  ACTIVE_COMPANIES_COUNTRY_SUMMARY_ITEM,
  AREA_LICENSED_COUNTRY_SUMMARY_ITEM,
  LIQUID_PRODUCTION_COUNTRY_SUMMARY_ITEM,
  GAS_PRODUCTION_COUNTRY_SUMMARY_ITEM,
  REMAINING_LIQUID_RESERVES_COUNTRY_SUMMARY_ITEM,
  REMAINING_GAS_RESERVES_COUNTRY_SUMMARY_ITEM,
  NPV_COUNTRY_SUMMARY_ITEM,
  REMAINING_CAPEX_COUNTRY_SUMMARY_ITEM,
  FISCAL_REGIME_COUNTRY_SUMMARY_ITEM,
  NOTE_COUNTRY_SUMMARY_ITEM,
} from '../SummaryList/SummaryItems/country';

export const GA_PAGE = 'COUNTRY_OVERVIEW';
export const CORE_DOWNLOAD_TYPE = 'COUNTRY OVERVIEW REPORT (CORE)';

export const sortTypes = [
  {
    name: 'Name',
    value: 'displayName',
  },
  {
    name: 'NPV',
    value: 'npv',
    unit: '(US$MM)',
    getValue: (c) => c.countryStatistics.formattedTotalValuationMmusd,
  },
  {
    name: 'Reserves',
    value: 'reserves',
    unit: '(MMboe)',
    getValue: (c) => c.countryStatistics.formattedTotalRemainingReserves,
  },
];

export const summaryItems = [
  {
    label: 'Key Information',
    group: [
      COMMERCIAL_ASSETS_COUNTRY_SUMMARY_ITEM,
      ACTIVE_COMPANIES_COUNTRY_SUMMARY_ITEM,
      AREA_LICENSED_COUNTRY_SUMMARY_ITEM,
    ],
  },
  {
    label: 'Production & Reserves',
    group: [
      LIQUID_PRODUCTION_COUNTRY_SUMMARY_ITEM,
      GAS_PRODUCTION_COUNTRY_SUMMARY_ITEM,
      REMAINING_LIQUID_RESERVES_COUNTRY_SUMMARY_ITEM,
      REMAINING_GAS_RESERVES_COUNTRY_SUMMARY_ITEM,
    ],
  },
  {
    label: 'Financial Considerations',
    group: [
      NPV_COUNTRY_SUMMARY_ITEM,
      REMAINING_CAPEX_COUNTRY_SUMMARY_ITEM,
      FISCAL_REGIME_COUNTRY_SUMMARY_ITEM,
    ],
  },
  {
    label: ` `,
    group: [NOTE_COUNTRY_SUMMARY_ITEM],
  },
];

export const mapSettings = {
  enabled: true,
  includeMapStyle: true,
  includeLayers: true,
};

export const LAYERS = [MAP_LAYER_TYPES.countryBoundaries];

// TODO: Fix properly the countryStatistics bug:
// https://welligence.atlassian.net/browse/XWWP-1140
export const uniqueCountryStatistics = (countryStatistics) => {
  const seenIds = new Set();
  return countryStatistics.filter((countryStats) => {
    if (seenIds.has(countryStats.id)) {
      return false;
    }
    seenIds.add(countryStats.id);
    return true;
  });
};
