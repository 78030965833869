import { getChartOptions } from '../../helpers';

export const TRANSACTIONS_AGGREGATE_FIELDS_ENUM = {
  VALUATIONS: 'valuationsSum',
  RESERVES: 'reservesSum',
  PRODUCTIONS: 'productionsSum',
  ACQUISITION_COST: 'acquisitionCostSum',
};

export const TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS = Object.values(
  TRANSACTIONS_AGGREGATE_FIELDS_ENUM,
);

export const TRANSACTIONS_AGGREGATE_BY_TITLE = 'NPV10 Valuation (US$MM)';

export const TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM = {
  YEAR: 'year',
  COUNTRY: 'country',
  BUYER: 'buyer',
  SELLER: 'seller',
};

export const TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS = getChartOptions(
  Object.values(TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM),
);

export const TRANSACTIONS_STACK_BY_TYPE_ENUM = {
  BUYER: 'buyer',
  SELLER: 'seller',
};

export const TRANSACTIONS_STACK_BY_OPTIONS = getChartOptions(
  Object.values(TRANSACTIONS_STACK_BY_TYPE_ENUM),
);

export const TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM = {
  RESERVES: 'reserves',
  PRODUCTIONS: 'productions',
  ACQUISITION_COST: 'acquisitionCost',
};

export const TRANSACTIONS_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM = {
  [TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.RESERVES]: TRANSACTIONS_AGGREGATE_FIELDS_ENUM.RESERVES,
  [TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.PRODUCTIONS]:
    TRANSACTIONS_AGGREGATE_FIELDS_ENUM.PRODUCTIONS,
  [TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.ACQUISITION_COST]:
    TRANSACTIONS_AGGREGATE_FIELDS_ENUM.ACQUISITION_COST,
};

export const TRANSACTIONS_AGGREGATE_COMPARE_TO_TITLES_ENUM = {
  [TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.RESERVES]: 'Reserves (MMboe)',
  [TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.PRODUCTIONS]: 'Production (boe/d)',
  [TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.ACQUISITION_COST]: 'Acquisition Cost (US$/boe)',
};

export const TRANSACTIONS_AGGREGATE_COMPARE_TO_OPTIONS = Object.values(
  TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM,
).map((name) => ({
  label: TRANSACTIONS_AGGREGATE_COMPARE_TO_TITLES_ENUM[name],
  value: name,
}));
