import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import { EntityRow } from './components/EntityRow';
import { Group } from './components/Group';
import { SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE } from '../../../../../utils/constants';
import clsx from 'clsx';
import { useStyles } from './Popover.style';

const cleanAlfNumChars = (v) => v?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

const sortGroupsAsc = (a, b) =>
  a.group.displayName < b.group.displayName
    ? -1
    : a.group.displayName > b.group.displayName
      ? 1
      : 0;

const groupBy = (entities, search, sortGroupsCb, groupOptionKey) => {
  sortGroupsCb = sortGroupsCb || sortGroupsAsc;
  const searchCleared = cleanAlfNumChars(search);

  const entitiesGrouped = entities.reduce((acc, curr) => {
    // if group is a string, we need to create an object with id and displayName
    let group = curr[groupOptionKey];
    if (typeof group === 'string') {
      group = { id: group, displayName: group };
    }

    acc[group.id] = {
      group: group,
      entities: [...((acc[group.id] || {}).entities || []), curr],
    };
    return acc;
  }, {});

  const result = [];

  Object.values(entitiesGrouped).forEach(({ entities, group }) => {
    const chosenCount = entities.filter((option) => option.isChosen).length;
    const groupMetaData = {
      allChosen: chosenCount === entities.length,
      chosenCount,
      itemsCount: entities.length,
    };

    const entitiesFiltered = searchCleared
      ? entities.filter(
          (option) =>
            cleanAlfNumChars(option.displayName || option.name).indexOf(searchCleared) !== -1,
        )
      : entities;

    if (entitiesFiltered.length === 0) return;

    result.push({
      groupMetaData,
      group: group,
      entities: entitiesFiltered.sort((a, b) =>
        a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0,
      ),
    });
  });

  return result.sort(sortGroupsCb);
};

export const Popover = ({
  loading,
  options,
  setOptions,
  top,
  popoverHeightReduction = '30px',
  groupOptionKey,
  label,
  groupsOpenedByDefault,
  groupByName,
  sortGroupsCb,
}) => {
  const hasGrouping = !!groupOptionKey;

  const [search, setSearch] = useState();
  const [isGroupedBy, setIsGroupedBy] = useState(() => {
    if (!hasGrouping) return false;
    const sessionPreference = sessionStorage.getItem(
      SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE + label,
    );
    return sessionPreference !== 'false';
  });
  const classes = useStyles();

  const openGroupWhileTyping = !!search;
  const setIsGroupedByLocal = (v) => {
    setIsGroupedBy(v);
    sessionStorage.setItem(SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE + label, v);
  };

  const clearSearch = useCallback(() => setSearch(''), []);

  const updateSearch = ({ target }) => {
    setSearch(target.value);
  };

  const setOptionIsChosen = (id, { target }) =>
    setOptions(
      options.map((option) =>
        option.id === id ? { ...option, isChosen: target.checked } : option,
      ),
    );

  const setGroupIsChosen = (id, { target }) =>
    setOptions(
      options.map((option) =>
        option[groupOptionKey].id === id || option[groupOptionKey] === id
          ? { ...option, isChosen: target.checked }
          : option,
      ),
    );
  const clearAll = () => setOptions(options.map((option) => ({ ...option, isChosen: false })));
  const selectAll = () => setOptions(options.map((option) => ({ ...option, isChosen: true })));

  const OptionList = useMemo(() => {
    const NoResult = () => <div className={classes.noResult}>no result</div>;

    if (isGroupedBy) {
      const optionsGrouped = groupBy(options, search, sortGroupsCb, groupOptionKey);

      return (
        <div className={classes.optionList}>
          {optionsGrouped.length === 0 ? (
            <NoResult />
          ) : (
            optionsGrouped.map(({ group, entities, groupMetaData }) => {
              return (
                <Group
                  key={'group-' + group.id}
                  group={group}
                  groupChecked={groupMetaData}
                  setGroupIsChosen={setGroupIsChosen}
                  entitiesGrouped={entities}
                  setOptionIsChosen={setOptionIsChosen}
                  openGroupWhileTyping={openGroupWhileTyping}
                  groupsOpenedByDefault={groupsOpenedByDefault}
                />
              );
            })
          )}
        </div>
      );
    } else {
      const entitiesFiltered = search
        ? options.filter(
            (option) =>
              cleanAlfNumChars(option.displayName).indexOf(cleanAlfNumChars(search)) !== -1,
          )
        : options;

      return (
        <div className={classes.optionList}>
          {entitiesFiltered.length === 0 ? (
            <div className={classes.noResult}>no result</div>
          ) : (
            entitiesFiltered.map(({ displayName, isChosen, id }) => (
              <EntityRow
                displayName={displayName}
                isChosen={!!isChosen}
                key={id}
                setOptionIsChosen={(e) => setOptionIsChosen(id, e)}
              />
            ))
          )}
        </div>
      );
    }
  }, [isGroupedBy, options, search]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Box
        className={classes.popover}
        style={{ height: `calc(100vh - ${top}px - ${popoverHeightReduction})` }}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} className={classes.loader} />
        ) : (
          <>
            <TextField
              className={classes.input}
              inputRef={(input) => input && input.focus()}
              placeholder={'Search ' + label}
              variant="outlined"
              value={search}
              onChange={updateSearch}
              fullWidth
              InputProps={{
                endAdornment: search ? (
                  <Button className={classes.close} onClick={clearSearch}>
                    <ClearIcon />
                  </Button>
                ) : undefined,
              }}
            />
            {OptionList}
            <div className={classes.sectionBottom}>
              {hasGrouping && (
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{ root: classes.checkBox }}
                      checked={isGroupedBy}
                      onChange={({ target }) => setIsGroupedByLocal(target.checked)}
                    />
                  }
                  label={<Typography className={classes.label}>Group by {groupByName}</Typography>}
                />
              )}
              <Button
                className={clsx(classes.button, {
                  [classes.divide]: hasGrouping,
                })}
                variant="text"
                onClick={selectAll}
              >
                Select All
              </Button>
              <Button className={classes.button} variant="text" onClick={clearAll}>
                Clear All
              </Button>
            </div>
          </>
        )}
      </Box>
    </div>
  );
};
