import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
// import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50px',
    padding: '8px',
    paddingLeft: 0,
  },
  box: {
    backgroundColor: 'darkgrey',
    width: '5px',
    margin: '1px',
  },
  boxSelected: {
    backgroundColor: theme.palette.baseColors.fireOrange.c100,
  },
  pageNumber: {
    textAlign: 'center',
  },
}));

export default ({ currPage, numPages }) => {
  const classes = useStyles();
  const PageBoxes = () => {
    const pages = [];
    for (let page = 1; page <= numPages; page++) {
      pages.push(
        <div
          key={`card-list-pagination-${page}`}
          className={clsx({ [classes.box]: true, [classes.boxSelected]: currPage === page })}
          style={{ height: `calc(100% / ${numPages} - 2px)` }}
        />,
      );
    }
    return pages;
  };

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={6} direction="column" alignItems="center">
        <PageBoxes />
      </Grid>
      {/* TODO: Decide if we want to keep this in the future */}
      {/* <Grid container item xs={6} direction="column" justifyContent="space-evenly">
        <Typography variant="caption" className={classes.pageNumber}>
          {numPages ? currPage : 0}
        </Typography>
        <Divider style={{ backgroundColor: 'grey' }} />
        <Typography variant="caption" className={classes.pageNumber}>
          {numPages}
        </Typography>
      </Grid> */}
    </Grid>
  );
};
