import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN } from '../../../utils/constants';
import {
  DEFAULT_MAP_ZOOM,
  ENTITY_LAYER_NAMES,
  ENTITY_TYPE_LAYERS_WITH_IDS,
  MAP_ENTITY_TYPES,
  MAP_LAYER_IDS,
  MAP_LAYER_TYPES,
  MAP_SOURCE_IDS,
  RENDERABLE_ENTITY_LAYERS,
} from './constants';
import {
  addAdditionalLayers,
  addLayers,
  addRulerContext,
  buildHighlightLayerId,
  buildLabelsLayerId,
  layerNeedsLabels,
} from './layers';
import mapStyles, {
  getPropertyDefault,
  getPropertyHighlight,
  getStyleOptions,
  setUserPreferenceMapStyle,
} from './mapStyles';
import { TRANSPARENT_COLOR } from './colors';

// Map entity types to the mapbox layer names
const mapLayerForMatch = {
  [MAP_LAYER_TYPES.assets]: /.*_assets/g,
  [MAP_LAYER_TYPES.blocks]: /.*_blocks/g,
  [MAP_LAYER_TYPES.ccusProjects]: /.*_ccus_projects$/g,
  [MAP_LAYER_TYPES.co2Emitters]: /.*_co2_emitters$/g,
  [MAP_LAYER_TYPES.eaWells]: /.*_eawells$/g,
  [MAP_LAYER_TYPES.facilities]: /.*_facilities$/g,
  [MAP_LAYER_TYPES.fields]: /.*_fields$/g,
  [MAP_LAYER_TYPES.formations]: /.*_formations/g,
  [MAP_LAYER_TYPES.leases]: /.*_leases/g,
  [MAP_LAYER_TYPES.lng]: /.*_lng$/g,
  [MAP_LAYER_TYPES.pipelines]: /.*_pipelines$/g,
  [MAP_LAYER_TYPES.pipelineSegments]: /.*_pipeline_segments$/g,
  [MAP_LAYER_TYPES.presaltPolygons]: /.*_presalt_polygons/g,
  [MAP_LAYER_TYPES.storageSites]: /.*_storage_sites$/g,
  [MAP_LAYER_TYPES.wells]: /.*_wells$/g,
};

export const mapLayerMatch = (layer, keys) =>
  keys.some((key) => {
    const regex = mapLayerForMatch[key];
    return regex && layer?.match(regex);
  });

// Entity type can be clicked on and will render info popup
const canRenderEntity = (layerName) => {
  if (layerName === null || layerName === undefined) return false;

  return RENDERABLE_ENTITY_LAYERS.includes(layerName);
};

// Pluralize entity types (entityType key in tileset)
export const pluralizeEntityType = (entityType) => {
  let pluralizedEntityType = `${entityType}s`;
  const pluralizationExceptions = {
    // `y -> ies` cases:
    facility: MAP_LAYER_TYPES.facilities,
  };
  if (pluralizationExceptions[entityType]) {
    pluralizedEntityType = pluralizationExceptions[entityType];
  }
  return pluralizedEntityType;
};

/* MAP CALLBACK EVENTS */
// Add highlight to entity item that was clicked
const addLayerHighlight = (currentItem, prevItemRef, layer, map) => {
  if (currentItem.sourceLayer !== MAP_LAYER_IDS.basins) {
    const highlight = getPropertyHighlight(layer, currentItem.properties);
    if (highlight?.screening) {
      // Blocks, Assets, Leases
      const { colorProperty, colorValue, widthProperty, widthValue } = highlight.screening;
      map.setPaintProperty(MAP_LAYER_IDS.screening, colorProperty, colorValue);
      map.setPaintProperty(MAP_LAYER_IDS.screening, widthProperty, widthValue);
    }

    // Highlight the layer using the property and value from the highlight object, if present:
    if (highlight?.property && highlight?.value) {
      // Prioritize the layer name from the highlight object, if available:
      const layerName = highlight.layer || layer;
      map.setPaintProperty(layerName, highlight.property, highlight.value);
    }

    // Highlight the layer using the highlight layer, if present:
    const layerId = buildHighlightLayerId(layer);
    const highlightLayer = map.getLayer(layerId);
    if (highlightLayer) {
      // TODO: Cleanup the snake_case to camelCase refactor - https://welligence.atlassian.net/browse/XWWP-603
      // map.setFilter(layerId, ['==', 'legacyId', currentItem.properties.legacyId]);
      map.setFilter(layerId, [
        'any',
        ['==', ['get', 'legacyId'], currentItem.properties.legacyId],
        ['==', ['get', 'legacy_id'], currentItem.properties.legacyId],
      ]);
    }
  }
  prevItemRef.current = currentItem;
};

// Change layer style
const changeLayerStyle = (map, mapTilesetSources, layer, metric, size) => {
  const styles = getStyleOptions(layer, metric, size);
  for (const source of mapTilesetSources) {
    const layerName = source.layers.find((l) => mapLayerMatch(l, [layer]));
    styles.forEach((style) => {
      map.setPaintProperty(layerName, style.property, style.value);
    });
  }
};

// Create "clicked" entity from a selected item with GraphQL structure
const createClickedFeatureForSearch = (layerName, value) => {
  const clickedFeature = {
    id: value.id && parseInt(value.id),
    legacyId: parseInt(value.legacyId || value.id),
    countryIsoCode: value.country?.isoCode,
    name: value.name,
  };
  switch (layerName) {
    case MAP_LAYER_TYPES.assets:
      clickedFeature.entityType = MAP_ENTITY_TYPES.asset;
      clickedFeature.hydrocarbonType = value.hydrocarbonType;
      break;
    case MAP_LAYER_TYPES.blocks:
    case MAP_LAYER_TYPES.leases:
      clickedFeature.entityType = value?.leaseLegacyId ? MAP_ENTITY_TYPES.lease : value.entityType;
      if (value?.leaseLegacyId) {
        clickedFeature.legacyId = parseInt(value?.leaseLegacyId);
      }
      break;
    case MAP_LAYER_TYPES.fields:
      clickedFeature.entityType = MAP_ENTITY_TYPES.field;
      clickedFeature.hydrocarbonType = value.hydrocarbonType;
      break;
    case MAP_LAYER_TYPES.wells:
      clickedFeature.entityType = MAP_ENTITY_TYPES.well;
      clickedFeature.weaClassification = value.weaClassification;
      break;
    case MAP_LAYER_TYPES.facilities:
      clickedFeature.entityType = MAP_ENTITY_TYPES.facility;
      break;
    case 'lngProjects':
      clickedFeature.legacyId = null;
      clickedFeature.entityType = MAP_ENTITY_TYPES.lngProject;
      break;
    case 'eawells':
      clickedFeature.legacyId = null;
      clickedFeature.entityType = MAP_ENTITY_TYPES.eaWell;
      break;
    case 'pipelineNetworks':
      clickedFeature.legacyId = null;
      clickedFeature.entityType = 'pipeline_network';
  }

  return clickedFeature;
};

// Filter layer
const filterLayer = (map, mapTilesetSources, entityTypes, filter) => {
  for (const source of mapTilesetSources) {
    const layers = source.layers.filter((l) => mapLayerMatch(l, entityTypes));
    layers.forEach((layerName) => {
      if (layerNeedsLabels(layerName)) {
        map.setFilter(buildLabelsLayerId(layerName), filter);
      }
      map.setFilter(layerName, filter);
    });
  }
};

// Filter layers
const filterLayers = (map, mapTilesetSources, layerFilters) => {
  layerFilters.forEach(({ entityTypes, filter }) =>
    filterLayer(map, mapTilesetSources, entityTypes, filter),
  );
};

// Map entity type click event
const layerEvent = (map, layerClickCallback) => (e) => {
  const features = map.queryRenderedFeatures(e.point);
  const displayProperties = ['type', 'properties', 'id', 'layer', 'source', 'sourceLayer', 'state'];
  const displayFeatures = features.map((feature) => {
    const displayFeature = {};

    displayProperties.forEach((prop) => {
      displayFeature[prop] = feature[prop];
    });
    return displayFeature;
  });

  const displayFeature = displayFeatures !== 'undefined' && displayFeatures?.[0];

  if (displayFeature && MAP_SOURCE_IDS.screeningCluster !== displayFeature.source) {
    const clickedFeature = { ...displayFeature.properties };
    // Check if the entity type requires ID to be set
    if (!ENTITY_TYPE_LAYERS_WITH_IDS.includes(clickedFeature.entityType)) {
      // id is left null for now in order for permalinks to be active
      // (map ids might not be up to date)
      // until we move ALL queries over to use legacyId
      // i.e. asset reports, facility graphs, pipeline segment graphs
      // id is grabbed from the operations Get [entityType] call
      clickedFeature.id = null;
    }

    let clickedLayer;
    let layerName;
    let layerCountry;
    switch (displayFeature.source) {
      case MAP_SOURCE_IDS.eaWells:
        // E&A wells option in the screening tab:
        clickedLayer = 'screening_ea_wells';
        layerCountry = clickedFeature.countryIsoCode;
        layerName = ENTITY_LAYER_NAMES.eaWells;
        break;
      case MAP_SOURCE_IDS.lngFacilities:
        clickedLayer = 'lng_facilities';
        layerCountry = clickedFeature.countryIsoCode;
        layerName = ENTITY_LAYER_NAMES.lng;
        break;
      case MAP_SOURCE_IDS.pipelineNetworks:
        clickedLayer = 'pipeline_networks';
        layerCountry = clickedFeature.countryIsoCode;
        layerName = ENTITY_LAYER_NAMES.pipelineNetworks;
        break;
      case MAP_SOURCE_IDS.screening: {
        const {entityType} = clickedFeature;
        let pluralizedEntityType = pluralizeEntityType(entityType);

        clickedLayer = `screening_${pluralizedEntityType}`;
        layerCountry = clickedFeature.countryIsoCode;
        layerName = pluralizedEntityType;
        break;
      }
      case 'composite': // this is for when the user clicks on a country label, we can still select the country or when a user clicks a basin
        if (displayFeature.sourceLayer === MAP_LAYER_IDS.basins) {
          clickedLayer = displayFeature.sourceLayer;
          layerCountry = null;
          layerName = clickedLayer;
        } else if (
          map.enableCountryClick &&
          displayFeatures[1].source === MAP_LAYER_IDS.countryFillBoundaries
        ) {
          clickedLayer = displayFeatures[1].sourceLayer;
          layerCountry = displayFeatures[1].properties.iso_3166_1_alpha_3;
          layerName = 'countries';
        }
        break;
      case MAP_LAYER_IDS.countryFillBoundaries:
      case MAP_LAYER_IDS.countryLineBoundaries:
      case MAP_LAYER_IDS.countrySelectedBoundaries:
        if (map.enableCountryClick) {
          clickedLayer = displayFeature.sourceLayer;
          layerCountry = displayFeature.properties.iso_3166_1_alpha_3;
          layerName = 'countries';
        }
        break;
      case MAP_LAYER_IDS.customRegionFillBoundaries:
      case MAP_LAYER_IDS.customRegionLineBoundaries:
      case MAP_LAYER_IDS.customRegionSelectedBoundaries:
      case MAP_LAYER_IDS.customRegionLabels:
        if (map.enableCountryClick) {
          clickedLayer = displayFeature.sourceLayer;
          layerCountry = displayFeature.properties.iso_3166_1_alpha_3;
          layerName = 'custom_regions';
        }
        break;
      default:
        clickedLayer = displayFeature.sourceLayer;
        layerCountry = clickedLayer?.split('_')[0];
        layerName = clickedLayer?.split('_').slice(1).join('_');
    }
    if (clickedFeature && clickedLayer && canRenderEntity(layerName)) {
      layerClickCallback({
        properties: clickedFeature,
        sourceLayer: layerName,
        layerCountry: layerCountry,
        coordinates: e.lngLat,
      });
    }
  }
};

// Remove highlight from entity item that was previously clicked
const removeLayerHighlight = (prevItem, layer, map) => {
  let prevLayer;
  switch (prevItem.sourceLayer) {
    case 'countries':
      prevLayer = MAP_LAYER_IDS.countryFillBoundaries;
      break;
    case 'custom_regions':
      prevLayer = MAP_LAYER_IDS.customRegionFillBoundaries;
      break;
    default:
      prevLayer = `${prevItem.layerCountry}_${prevItem.sourceLayer}` || MAP_LAYER_IDS.screening;
  }
  const defaultStyle = getPropertyDefault(prevLayer, prevItem.properties);

  if (prevItem.sourceLayer !== MAP_LAYER_IDS.basins && prevLayer !== layer) {
    if (defaultStyle?.screening) {
      // Blocks, Assets, Leases
      const { colorProperty, colorValue, widthProperty, widthValue } = defaultStyle.screening;
      map.setPaintProperty(MAP_LAYER_IDS.screening, colorProperty, colorValue);
      map.setPaintProperty(MAP_LAYER_IDS.screening, widthProperty, widthValue);
    }

    // Reset the default style on the previous layer:
    if (defaultStyle?.property && defaultStyle?.value) {
      // Prioritize the layer name from the default style, if available:
      const prevLayerName = defaultStyle.layer || prevLayer;
      map.setPaintProperty(prevLayerName, defaultStyle.property, defaultStyle.value);
    }

    // Reset the filter on the previous highlight layer:
    const prevHighlightLayerId = buildHighlightLayerId(prevLayer);
    if (map.getLayer(prevHighlightLayerId)) {
      // TODO: Cleanup the snake_case to camelCase refactor - https://welligence.atlassian.net/browse/XWWP-603
      // map.setFilter(prevHighlightLayerId, ['==', 'legacyId', '']);
      map.setFilter(prevHighlightLayerId, [
        '==',
        ['coalesce', ['get', 'legacyId'], ['get', 'legacy_id']],
        '',
      ]);      
    }
  }
};

// Reset the map's coordinates and zoom
const resetZoom = (map) => {
  map.flyTo({
    center: [0, 0],
    zoom: 1.5,
  });
};

// The ruler event function
const rulerEvent = (map, geojson, linestring) => (e) => {
  const features = map.queryRenderedFeatures(e.point, {
    layers: [MAP_LAYER_IDS.measurePoints],
  });

  // Remove the linestring from the group
  // So we can redraw it based on the points collection
  if (geojson.features.length > 1) geojson.features.pop();

  // If a feature was clicked, remove it from the map
  if (features.length) {
    const { id } = features[0].properties;
    geojson.features = geojson.features.filter((point) => {
      return point.properties.id !== id;
    });
  } else {
    const point = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      properties: {
        id: String(new Date().getTime()),
        isDistance: false,
      },
    };

    geojson.features.push(point);
  }

  const distances = [];
  if (geojson.features.length > 1) {
    // append linestrings to geojson
    linestring.geometry.coordinates = [];
    geojson.features.forEach((point, i) => {
      linestring.geometry.coordinates.push(point.geometry.coordinates);

      // calculate distances and append to geojson
      if (i > 0) {
        const point1 = linestring.geometry.coordinates[i - 1];
        const point2 = linestring.geometry.coordinates[i];
        const x1 = point1[0];
        const y1 = point1[1];
        const x2 = point2[0];
        const y2 = point2[1];
        const dist = turf.length({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: linestring.geometry.coordinates.slice(i - 1, i + 1),
          },
        });
        const distanceBetweenPoints = {
          type: 'Feature',
          properties: {
            description: dist.toLocaleString() + ' km',
            isDistance: true,
          },
          geometry: {
            type: 'Point',
            coordinates: [(x1 + x2) / 2, (y1 + y2) / 2],
          },
        };
        const totalDist = turf.length(linestring);
        const totalDistanceUpToPoint = {
          type: 'Feature',
          properties: {
            description: totalDist.toLocaleString() + ' km',
            isDistance: true,
          },
          geometry: {
            type: 'Point',
            coordinates: point2,
          },
        };
        distances.push(distanceBetweenPoints);
        distances.push(totalDistanceUpToPoint);
      }
    });
    geojson.features.push(linestring);
  }
  map.getSource(MAP_SOURCE_IDS.geojson).setData({
    ...geojson,
    features: geojson.features.concat(distances),
  });
};

// set style for country boundaries
const setCountryBoundaryStyle = (
  map,
  style = {
    country: TRANSPARENT_COLOR,
    custom_region: TRANSPARENT_COLOR,
    selected_country: TRANSPARENT_COLOR,
    selected_custom_region: TRANSPARENT_COLOR,
  },
) => {
  map.setPaintProperty(MAP_LAYER_IDS.countryLineBoundaries, 'line-color', style.country);
  map.setPaintProperty(MAP_LAYER_IDS.customRegionLineBoundaries, 'line-color', style.custom_region);
  map.setPaintProperty(
    MAP_LAYER_IDS.countrySelectedBoundaries,
    'line-color',
    style.selected_country,
  );
  map.setPaintProperty(
    MAP_LAYER_IDS.customRegionSelectedBoundaries,
    'line-color',
    style.selected_custom_region,
  );
};

// set style for heat maps (currently only used for countries / regions)
// will need to refactor to include other layer types in the future
const setHeatMapStyle = (
  map,
  style = {
    country: TRANSPARENT_COLOR,
    custom_region: TRANSPARENT_COLOR,
    region_label: 0,
  },
) => {
  map.setPaintProperty(MAP_LAYER_IDS.countryFillBoundaries, 'fill-color', style.country);
  map.setPaintProperty(MAP_LAYER_IDS.customRegionFillBoundaries, 'fill-color', style.custom_region);
  map.setPaintProperty(MAP_LAYER_IDS.customRegionLabels, 'text-opacity', style.region_label);
};

// Set the info popup for the clicked entity item (Mapbox built in popup)
const setPopup = (infoHtml, coordinates, map) => {
  map.infoPopup.setLngLat(coordinates).setHTML(infoHtml).addTo(map);
};

// Toggle layer visibility
const toggleLayer = (map, mapTilesetSources, entityTypes, visibility) => {
  for (const source of mapTilesetSources) {
    const layers = source.layers.filter((l) => mapLayerMatch(l, entityTypes));
    layers.forEach((layerName) => {
      if (layerNeedsLabels(layerName)) {
        map.setLayoutProperty(buildLabelsLayerId(layerName), 'visibility', visibility);
      }
      map.setLayoutProperty(layerName, 'visibility', visibility);
    });
  }
};

// Toggle Ruler functionality
const toggleMapRuler = (map, rulerActive) => {
  map.on('mousemove', () => {
    map.getCanvas().style.cursor = rulerActive ? 'crosshair' : 'pointer';
  });

  if (rulerActive) {
    map.off('click', map.onClickLayerCallback);
    map.on('click', map.onClickRulerCallback);
  } else {
    map.off('click', map.onClickRulerCallback);
    map.on('click', map.onClickLayerCallback);
  }
};

// When map loads, add layers and filter as needed
const onLoad = (
  map,
  mapTilesetSources,
  layers,
  style,
  geojson,
  linestring,
  setMapLoaded,
  initialMetric,
  initialSizeType,
  hideLayers,
  layerFilters,
  ruler,
  enableEntityHighlight,
) => {
  try {
    // Add country tileset layers
    addLayers(
      map,
      mapTilesetSources,
      layers,
      initialMetric,
      initialSizeType,
      hideLayers,
      enableEntityHighlight,
    );
    // Add layers additional layers ()
    addAdditionalLayers(map, layers);
    // Create ruler
    if (ruler) {
      addRulerContext(map, geojson, style);
      map.onClickRulerCallback = rulerEvent(map, geojson, linestring);
    }
    filterLayers(map, mapTilesetSources, layerFilters);
    setMapLoaded(true);
  } catch (e) {
    console.log(e);
  }
};

// Toggle the map's style
const toggleMapStyle = (
  map,
  style,
  mapTilesetSources,
  setStyle,
  ruler,
  setRulerActive,
  geojson,
  linestring,
  layers,
  setMapLoaded,
  initialMetric,
  initialSizeType,
  hideLayers,
  filterLayers,
  enableEntityHighlight,
) => {
  geojson.features = [];
  linestring.geometry.coordinates = [];
  if (ruler) {
    map.getSource(MAP_SOURCE_IDS.geojson).setData(geojson);
    map.off('click', map.onClickRulerCallback);
  }
  map.off('style.load', map.onLoadCallback);
  map.onLoadCallback = () =>
    onLoad(
      map,
      mapTilesetSources,
      layers,
      style,
      geojson,
      linestring,
      setMapLoaded,
      initialMetric,
      initialSizeType,
      hideLayers,
      filterLayers,
      ruler,
      enableEntityHighlight,
    );

  map.on('style.load', map.onLoadCallback);
  map.on('click', map.onClickLayerCallback);
  setStyle(style);
  setUserPreferenceMapStyle(style);
  setRulerActive(false);
  map.setStyle(mapStyles[style]);
};

/* CREATE MAP */
export const createMap = ({
  callbacks,
  control,
  enableCountryClick,
  enableEntityHighlight,
  enableInfoPopup,
  geojson,
  hideLayers,
  initialMetric,
  initialSizeType,
  latLong,
  layerClickCallback,
  layerFilters,
  layers,
  linestring,
  mapContainerId,
  mapTilesetSources,
  ruler,
  setMapLoaded,
  setRulerActive,
  setStyle,
  style,
  zoom = DEFAULT_MAP_ZOOM,
}) => {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  const map = new mapboxgl.Map({
    container: mapContainerId,
    style: mapStyles[style],
    center: latLong ? JSON.parse(latLong) : [0, 0],
    zoom: latLong ? zoom : 1.5,
    attributionControl: false,
    maxPitch: 0,
    preserveDrawingBuffer: true,
  });

  map.dragRotate.disable();
  map.touchZoomRotate.disableRotation();

  // Only log the Mapbox errors in development:
  map.on('error', (e) => {
    if (process.env.NODE_ENV === 'development') {
      console.error('Mapbox error:', e);
    }
  });

  if (control) {
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    map.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');
  }

  if (enableInfoPopup) {
    map.infoPopup = new mapboxgl.Popup({ className: 'mapbox-popup' });
    map.setPopup = (infoHtml, coordinates) => {
      setPopup(infoHtml, coordinates, map);
    };
  }

  map.enableCountryClick = enableCountryClick;
  map.onLoadCallback = () =>
    onLoad(
      map,
      mapTilesetSources,
      layers,
      style,
      geojson,
      linestring,
      setMapLoaded,
      initialMetric,
      initialSizeType,
      hideLayers,
      layerFilters,
      ruler,
      enableEntityHighlight,
    );

  // Add map callbacks
  map.addLayerHighlight = (currentItem, prevItemRef, layer) => {
    addLayerHighlight(currentItem, prevItemRef, layer, map);
  };
  map.createClickedFeatureForSearch = (layerName, value) =>
    createClickedFeatureForSearch(layerName, value);
  map.onClickLayerCallback = layerEvent(map, layerClickCallback);
  map.removeLayerHighlight = (prevItem, layer) => {
    removeLayerHighlight(prevItem, layer, map);
  };
  map.resetZoom = () => resetZoom(map);
  map.toggleMapRuler = (rulerActive) => {
    toggleMapRuler(map, rulerActive);
  };
  map.toggleMapStyle = (newStyle) => {
    toggleMapStyle(
      map,
      newStyle,
      mapTilesetSources,
      setStyle,
      ruler,
      setRulerActive,
      geojson,
      linestring,
      layers,
      setMapLoaded,
      initialMetric,
      initialSizeType,
      hideLayers,
      layerFilters,
    );
  };
  // Add map layer callbacks
  map.layerCallbacks = {
    changeLayerStyle: (layer, metric, size) =>
      changeLayerStyle(map, mapTilesetSources, layer, metric, size),
    filterLayer: (layer, filter) => filterLayer(map, mapTilesetSources, layer, filter),
    filterLayers: (layerFilters) => filterLayers(map, mapTilesetSources, layerFilters),
    setCountryBoundaryStyle: (style) => setCountryBoundaryStyle(map, style),
    setHeatMapStyle: (style) => setHeatMapStyle(map, style),
    toggleLayer: (layer, visibility) => toggleLayer(map, mapTilesetSources, layer, visibility),
  };

  map.on('click', map.onClickLayerCallback);
  map.on('style.load', map.onLoadCallback);
  Object.keys(callbacks).forEach((callbackName) => {
    map.on(callbackName, callbacks[callbackName]);
  });

  return map;
};
