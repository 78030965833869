import React, { useEffect } from 'react';

import CheckList from '../../CheckList';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkListRoot: {
    padding: 0,
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  border: {
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  borderOptions: {
    flexDirection: 'row',
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  radioRowLabel: {
    fontWeight: 'bold',
  },
  radioRowOption: {
    width: 'auto',
    justifyContent: 'unset',
  },
  radioRowOptionLabel: {
    width: 'fit-content',
    paddingTop: '10px',
  },
}));

export default ({
  graphTypes,
  graphTypeChildren = {},
  handleSetState,
  includeOptions,
  itemIsDisabledParams = {},
  selectedAggregate,
  selectedGraphType,
  selectedGraphTypeOptions,
  label = 'Select Metric',
}) => {
  const classes = useStyles();

  const onChange = (e) => {
    // Find the selected graph type object from the value string
    let foundGraphType;
    // Loop through graph types
    for (let i = 0; i < graphTypes.length; i++) {
      const gt = graphTypes[i];

      // If graph type contains options, search value from options
      if (gt.options) {
        // Loop through options
        for (let j = 0; j < gt.options.length; j++) {
          const op = gt.options[j];
          if (op.value === e.target.value) {
            foundGraphType = op;
            // If graph type was found break out of loop
            break;
          }
        }
      } else {
        // If graph type does not contain options, search value from graph type
        if (gt.value === e.target.value || gt === e.target.value) {
          foundGraphType = gt;
        }
      }
      // If graph type was found break out of loop
      if (foundGraphType) break;
    }

    // If selected graph type is an object, set value to object, otherwise set value to string
    const value = typeof selectedGraphType === 'object' ? foundGraphType : foundGraphType?.value;
    // Set state with found graph type
    handleSetState(value, 'graphType');
  };

  const setGraphTypeOptions = (options = []) => handleSetState(options, 'graphTypeOptions');
  // Reset graph type options when aggregate or graph type changes
  useEffect(() => {
    const { resetGraphTypeOptions } = selectedGraphType;
    // A graph type with options is selected
    // Filter out options that aren't compatible with the aggregate
    if (resetGraphTypeOptions) {
      setGraphTypeOptions(
        resetGraphTypeOptions({
          aggregate: selectedAggregate,
          graphTypeOptions: selectedGraphTypeOptions,
        }),
      );
    }
  }, [selectedAggregate]);
  useEffect(() => {
    const { resetGraphTypeOptions } = selectedGraphType;
    // A graph type with options has been selected
    // Filter out options that don't match that graph type parent
    if (resetGraphTypeOptions) {
      setGraphTypeOptions(
        resetGraphTypeOptions({
          aggregate: selectedAggregate,
          graphType: selectedGraphType,
          graphTypeOptions: selectedGraphTypeOptions,
        }),
      );
    } else {
      // A graph type with no options has been selected
      // Reset graph type options to empty array
      setGraphTypeOptions();
    }
  }, [selectedGraphType]);

  return (
    <FormControl style={{ width: '100%' }}>
      <Typography className={classes.label} variant="overline">
        {label}
      </Typography>
      <RadioGroup
        value={typeof selectedGraphType === 'object' ? selectedGraphType.value : selectedGraphType}
        name="graph-metric-radio-buttons-group"
        onChange={onChange}
      >
        {graphTypes.map((type) => (
          <div
            className={clsx({ [classes.border]: true, [classes.borderOptions]: !!type.options })}
            key={`portfolio-analysis-graph-metric-${type.label}`}
          >
            {type.options ? (
              <>
                <Typography
                  className={clsx(classes.radioRowLabel, classes.radioRowOptionLabel)}
                  variant="body1"
                >
                  {type.label}
                </Typography>
                <div>
                  {type.options.map((option) => (
                    <FormControlLabel
                      key={`portfolio-analysis-graph-metric-${type.label}-${option.optionLabel}`}
                      className={clsx(classes.radioRow, classes.radioRowOption)}
                      value={option.value}
                      control={<Radio />}
                      disabled={type.isDisabled && type.isDisabled(itemIsDisabledParams)}
                      label={option.optionLabel}
                      labelPlacement="start"
                    />
                  ))}
                </div>
              </>
            ) : (
              <FormControlLabel
                className={classes.radioRow}
                classes={{ label: classes.radioRowLabel }}
                value={type.value}
                control={<Radio />}
                disabled={type.isDisabled && type.isDisabled(itemIsDisabledParams)}
                label={type.label}
                labelPlacement="start"
              />
            )}
            {includeOptions && graphTypeChildren[type.value] && (
              <CheckList
                styles={classes}
                callback={(value) => handleSetState(value, 'graphTypeOptions')}
                checked={selectedGraphTypeOptions || []}
                isDisabled={type.optionIsDisabled({
                  aggregate: selectedAggregate,
                  graphType: selectedGraphType,
                })}
                labelPlacement="start"
                list={graphTypeChildren[type.value]}
                isCheckedCallback={(option) =>
                  !!selectedGraphTypeOptions.find((c) => c.name === option.name)
                }
              />
            )}
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
