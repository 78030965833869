import ExploreIcon from '@material-ui/icons/Explore';
import GetAppIcon from '@material-ui/icons/GetApp';
import LaunchIcon from '@material-ui/icons/Launch';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MapIcon from '@material-ui/icons/Map';
import { getNewsPosts, replaceSpecialCharacters, WORDPRESS_COUNTRY_IDS } from '../../../WordPress';
import { hasMA, mapAndFieldException } from './exceptions';
import { formatDate, formatDateShort, getLatLongFromPoint } from '../../../../utils/helpers';
import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENTS, OIL_AND_GAS_MAP_PATH, VALUATIONS_PATH } from '../../../../utils/constants';
import { ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG } from '../../constants';
import { getUserId } from '../../../../utils/auth';

// TODO: make list the array instead of titles/headers

export default (asset, getShinyUrl) => {
  if (asset === null) return {};
  const explore = [];
  if (hasMA[asset.country.isoCode]) {
    explore.push({
      title: 'M&A Evaluation',
      page: 'participation_changes',
      type: 'shiny',
      gA: () =>
        gtag(
          'event',
          ANALYTICS_EVENTS.event, {
            'event_category': ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
            'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
            'event_label': 'ma_analytics',
            'userId': getUserId(),
            'asset': asset.name,
            'country': asset.country.name,
            'company': 'NULL',
          }
        ),
    });
  }
  if (!mapAndFieldException[asset.country.isoCode]) {
    explore.push(
      {
        title: 'FIELD TECHNICAL ANALYSIS',
        page: 'fields',
        type: 'shiny',
        gA: () =>
          gtag(
            'event',
            ANALYTICS_EVENTS.event, {
              'event_category': ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
              'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
              'event_label': 'field_analytics',
              'userId': getUserId(),
              'asset': asset.name,
              'country': asset.country.name,
              'company': 'NULL',
            },
          ),
      },
      {
        title: 'HEAT MAPS',
        page: 'map',
        type: 'shiny',
        gA: () =>
          gtag(
            'event',
            ANALYTICS_EVENTS.event, {
              'event_category': ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
              'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
              'event_label': 'heatmap_analytics',
              'userId': getUserId(),
              'asset': asset.name,
              'country': asset.country.name,
              'company': 'NULL',
            },
          ),
      },
    );
  }
  explore.push({
    title: 'OIL AND GAS MAP',
    link: OIL_AND_GAS_MAP_PATH,
    type: 'link',
    gA: () =>
      gtag(
        'event',
        ANALYTICS_EVENTS.event, {
          'event_category': ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
          'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
          'event_label': 'oil_gas_map',
          'userId': getUserId(),
          'asset': asset.name,
          'country': asset.country.name,
          'company': 'NULL',
        },
      ),
  });

  return {
    exploreMore: {
      title: 'EXPLORE MORE',
      id: 'valuations-explore-more',
      Icon: ExploreIcon,
      list: {
        Icon: LaunchIcon,
        titles: explore.map((e) => e.title),
        onClick: explore.map((e) =>
          e.type === 'shiny'
            ? () => {
                e.gA();
                getShinyUrl({
                  variables: { page: e.page, iso: asset.country.isoCode, assetName: asset.name },
                });
              }
            : () => {
                e.gA();
                window.open(`${e.link}/[${getLatLongFromPoint(asset.geom)}]`, '_blank');
              },
        ),
      },
    },
  };
};

export const createSimilarAssetsPanels = (asset, similarAssetsData) => {
  if (asset === null) return {};
  const panels = {};
  if (similarAssetsData && similarAssetsData.length > 0) {
    panels.similarAssets = {
      title: 'SIMILAR ASSETS',
      id: 'asset-page-similar-assets',
      Icon: MapIcon,
      list: {
        Icon: MapIcon,
        headers: similarAssetsData.map((a) =>
          a.reportUpdatedDate ? `UPDATED: ${formatDateShort(a.reportUpdatedDate)}` : '',
        ),
        titles: similarAssetsData.map((a) => a.displayName),
        onClick: similarAssetsData.map(
          (a) => () =>
            (window.location.href = `${VALUATIONS_PATH}/${a.country.isoCode}/${a.legacyId}`),
        ),
      },
    };
  }
  return panels;
};

export const createAssetPanels = (asset) => {
  const countryWordPressId = WORDPRESS_COUNTRY_IDS[asset?.country?.name.toLowerCase()];
  if (asset === null || !countryWordPressId) return {};

  return getNewsPosts(countryWordPressId).then((news) => {
    const panels = {};
    if (news.length > 0) {
      panels.countryNews = {
        title: 'COUNTRY NEWS',
        id: 'asset-page-country-news',
        Icon: ListAltIcon,
        list: {
          Icon: ListAltIcon,
          onClick: news.map((n) => () => {
            gtag(
              'event',
              ANALYTICS_EVENTS.event, {
                'event_category': ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
                'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
                'event_label': 'country_news',
                'userId': getUserId(),
                'asset': asset.name,
                'country': asset.country.name,
                'company': 'NULL',
              },
            ),
            window.open(n.link, '_blank');
          }),
          headers: news.map((n) => formatDate(n.date)),
          titles: news.map((n) => replaceSpecialCharacters(n.title.rendered)),
        },
      };
    }
    return panels;
  });
};

export const createCountryDownloadsPanels = (asset, titles, onClicks) => {
  if (asset === null) return {};
  return {
    countryDownloads: {
      title: `${asset.country.name.toUpperCase()} DOWNLOADS`,
      id: 'valuations-country-downloads',
      Icon: MapIcon,
      list: {
        Icon: GetAppIcon,
        titles,
        onClick: onClicks,
      },
    },
  };
};
