import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useStyles } from './GroupRow.style';

export const GroupRow = ({
  displayName,
  groupChecked,
  setOptionIsChosen,
  openCloseCb,
  isOpen,
  isClickable = true,
}) => {
  const classes = useStyles();

  const onClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className={clsx(classes.wrapperRegionRow, { [classes.wrapperRegion]: isClickable })}
      onClick={isClickable ? openCloseCb : undefined}
    >
      <div className={classes.checkAndName}>
        <Checkbox checked={groupChecked.allChosen} onChange={setOptionIsChosen} onClick={onClick} />
        <div className={classes.labelRegion}>{displayName}</div>
        {!!groupChecked.chosenCount && (
          <div className={classes.chosen}>{groupChecked.chosenCount}</div>
        )}
      </div>
      {!!isClickable && (
        <KeyboardArrowUpIcon className={clsx({ [classes.subListOpened]: !isOpen })} />
      )}
    </div>
  );
};
