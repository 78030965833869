import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.fonts.base,
    display: 'flex',
    flexDirection: 'column',
    ...theme.sizes.fullPage,
  },
  tabs: {
    margin: '0.5rem 0 1rem 0',
    alignSelf: 'center',
    borderBottom: `1px solid ${theme.palette.baseColors.gray.c100}`,

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.accentText.color,
    },

    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.accentText.color,
    },
  },
  tab: {
    '&:hover': {
      color: theme.palette.accentText.color,
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    overflow: 'auto',
  },
}));
