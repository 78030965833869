import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';

const useStylesModal = makeStyles(() => ({
  wrapperModalContentWithTabs: {
    padding: '0 1rem 1rem 1rem',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const ModalContentWithTabs = ({ tabLabels, tabPanels }) => {
  const classes = useStylesModal();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.wrapperModalContentWithTabs}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabLabels.map((tabLabel) => (
            <Tab key={tabLabel} label={tabLabel} />
          ))}
        </Tabs>
        {tabPanels.map((tabPanel, index) => (
          <TabPanel value={value} index={index} key={index}>
            {tabPanel}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};
