import React from 'react';
import { Typography, MenuList } from '@material-ui/core';
import { MenuButton } from '../MenuButton';
import { useButtonListStyles } from './ButtonList.style';

export const ButtonList = ({ link, forceNewTab, onButtonClick }) => {
  const classes = useButtonListStyles();

  return (
    <div>
      <Typography className={classes.title}>{link.title}</Typography>
      <MenuList>
        {link.menuItems.map((item) => (
          <MenuButton
            key={item.title}
            item={item}
            forceNewTab={forceNewTab}
            link={link}
            onClick={onButtonClick}
          />
        ))}
      </MenuList>
    </div>
  );
};
