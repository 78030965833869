import React, { useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { formatDate, formatDateShort, formatDateYear } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: '5px',
    width: 'calc(100% - 16px)',
    margin: '5px 8px',
    height: 'fit-content',
    overflow: 'initial',
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
  content: {
    padding: '8px 16px',
  },
  itemAccent: {
    color: 'grey',
  },
  cardListItemData: {
    display: 'flex',
    flexDirection: 'row',
  },
  data: {
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
  },
  label: {
    color: '#507180',
  },
  selected: {
    ...theme.palette.baseColors.iceBlue.c100,
  },
}));

// dateLabel - label for the date text (default - "UPDATED")
// dateFormat - format type for the date (default - "short")
// item - item to render
// getDateText - callback if need to get updated from nested structure
// onCardClick - callback for clicking on a card
// selected - if the card is the selected item
// sortType - if sort type has a getValue function and associated unit, card will render the value
//  ex. { name: 'NPV', unit: 'US$MM', getValue: (item) => item.countryStatistics.npv}
export default ({
  item,
  getDateText,
  onCardClick,
  sortType,
  selected,
  dateLabel = 'UPDATED',
  dateFormat = 'short',
}) => {
  const classes = useStyles();

  const dateText = useMemo(() => {
    const date = getDateText ? getDateText(item) : item.updatedAt;
    if (date) {
      switch (dateFormat) {
        case 'short':
          return formatDateShort(date);
        case 'long':
          return formatDate(date);
        case 'year':
          return formatDateYear(date);
        default:
          return formatDateShort(date);
      }
    }

    return '-';
  }, [getDateText, item]);

  const sortValue = useMemo(
    () => (sortType?.getValue ? sortType.getValue(item) : null),
    [sortType, item],
  );

  return (
    <Card
      className={clsx({ [classes.root]: true, [classes.selected]: selected })}
      onClick={() => onCardClick(item)}
    >
      <div className={classes.content}>
        <Typography variant="subtitle1">{item.displayName}</Typography>
        <Typography variant="overline" className={classes.itemAccent}>
          {dateLabel}: {dateText}
        </Typography>
        {sortValue && sortType.unit && (
          <Typography className={classes.data} variant="body2" component="p">
            <Typography className={classes.label} variant="body2" component="span">
              {sortType.name} {sortType.unit}:
            </Typography>
            &nbsp;{sortValue}
          </Typography>
        )}
      </div>
    </Card>
  );
};
