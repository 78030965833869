import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BASIC_FILTERS_WRAPPER_ID } from './BasicFiltersWrapper.constant';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  filterSection: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  minimized: {
    [theme.breakpoints.down('xs')]: {
      height: '50px',
      overflow: 'hidden',
    },
  },
}));

export const BasicFiltersWrapper = ({ children, minimize }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.filterSection, { [classes.minimized]: minimize })}
      id={BASIC_FILTERS_WRAPPER_ID}
    >
      {children}
    </div>
  );
};
