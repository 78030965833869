import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import SearchTab from './search';
import ScreeningTab from './screening';
import LayersTab from './layers';
import ErrorBoundary from '../../ErrorBoundary';
import { MapSearchProvider } from './searchContext';
import { MAP_SCREENING_EA_WELLS_QUERY } from './operations';
import { useQuery } from '@apollo/client';
import { getLatLongFromPoint } from '../../../utils/helpers';
import { useContext } from 'react';
import MapFilterContext from './filterContext';
import { MAP_SOURCE_IDS, MAP_SOURCE_RETRY_TIMEOUT } from '../../Map/Utils/constants';

const useStyles = makeStyles((theme) => ({
  filters: {
    ...theme.mapPanel,
    backgroundColor: 'white',
    padding: '5px',
  },
  formContainer: {
    margin: '5px',
    padding: '0 5px',
  },
  radio: {
    color: '#2a2e49',
    '&.Mui-checked': {
      color: '#2a2e49',
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  tab: {
    backgroundColor: 'rgba(0,0,0,.2)',
    border: 'solid thin rgba(0, 0, 0, .5)',
    color: 'rgba(0, 0, 0, .7)',
    minWidth: '50px',
    minHeight: '30px',
    '&.Mui-selected': {
      backgroundColor: 'rgba(236, 240, 245, 0)',
      color: '#2a2e49',
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
  },
  tabContainer: {
    margin: '0 5px',
    minHeight: '30px',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    height: '100%',
  },
}));

export default ({ isOpen, style, toggleMapStyle, map, setIsInfoOpen, setCurrentItem }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { filters, variables } = useContext(MapFilterContext);

  const {
    loading: loadingEaWells,
    data: eaWellsData,
    refetch: refetchEaWells,
  } = useQuery(MAP_SCREENING_EA_WELLS_QUERY, {
    variables,
  });

  const handleEaWellsDataUpdate = () => {
    const eaWellsSource = map?.getSource(MAP_SOURCE_IDS.eaWells);
    let timeout;
    if (!loadingEaWells) {
      if (eaWellsSource) {
        const screeningPointFeatures = [];
        eaWellsData.mapScreeningEaWells?.forEach((a) => {
          screeningPointFeatures.push({
            type: 'Feature',
            id: a.id,
            properties: {
              name: a.name,
              id: a.id,
              countryIsoCode: a.country.isoCode,
              country: a.country.name,
              entityType: a.entityType,
            },
            geometry: {
              type: 'Point',
              coordinates: getLatLongFromPoint(a.geom),
            },
          });
        });
        eaWellsSource.setData({
          type: 'FeatureCollection',
          features: screeningPointFeatures,
        });
      } else {
        // If the source doesn't exist, try again in 5 seconds:
        timeout = setTimeout(() => {
          handleEaWellsDataUpdate();
        }, MAP_SOURCE_RETRY_TIMEOUT);
      }
    }

    return timeout;
  };

  useEffect(() => {
    const timeout = handleEaWellsDataUpdate();
    return () => clearTimeout(timeout);
  }, [eaWellsData]);

  useEffect(() => {
    refetchEaWells(variables);
  }, [filters]);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    map?.on('style.load', () => {
      handleEaWellsDataUpdate();
    });
  }, [style]);

  return (
    <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit className={classes.filters}>
      <div>
        <FormControl component="fieldset" className={classes.formContainer}>
          <RadioGroup
            className={classes.radioGroup}
            aria-label="mapType"
            name="mapType"
            value={style}
            onChange={toggleMapStyle}
          >
            <FormControlLabel
              value="monochrome"
              control={<Radio className={classes.radio} />}
              label="Monochrome"
            />
            <FormControlLabel
              value="satellite"
              control={<Radio className={classes.radio} />}
              label="Satellite"
            />
          </RadioGroup>
        </FormControl>
        <Tabs
          className={classes.tabContainer}
          classes={{ flexContainer: classes.flexContainer }}
          TabIndicatorProps={{
            classes: {
              root: classes.indicator,
            },
          }}
          variant="fullWidth"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          <Tab className={classes.tab} label="Search" />
          <Tab className={classes.tab} label="Screening" />
          <Tab className={classes.tab} label="Layers" />
        </Tabs>
        <ErrorBoundary>
          <MapSearchProvider>
            <SearchTab
              map={map}
              setCurrentItem={setCurrentItem}
              setIsInfoOpen={setIsInfoOpen}
              hidden={value !== 0}
            />
          </MapSearchProvider>
          <ScreeningTab map={map} hidden={value !== 1} />
          <LayersTab style={style} map={map} hidden={value !== 2} />
        </ErrorBoundary>
      </div>
    </Slide>
  );
};
