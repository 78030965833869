// enabled countries in pre/app
export const enabledCountries = {
  AGO: 'Angola',
  ALB: 'Albania',
  ALK: 'Alaska',
  ARE: 'United Arab Emirates (UAE)',
  ARG: 'Argentina',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BGD: 'Bangladesh',
  BHR: 'Bahrain',
  BLR: 'Belarus',
  BOL: 'Bolivia',
  BRA: 'Brazil',
  BRN: 'Brunei',
  CA1: 'Canada Offshore',
  CHN: 'China',
  CIV: "Cote d'Ivoire",
  CMR: 'Cameroon',
  COD: 'Democratic Republic of Congo (DRC)',
  COG: 'Congo-Brazzaville',
  COL: 'Colombia',
  CYP: 'Cyprus',
  DEU: 'Germany',
  DNK: 'Denmark',
  DZA: 'Algeria',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  FLK: 'Falkland Islands/Islas Malvinas',
  FRA: 'France',
  GAB: 'Gabon',
  GBR: 'United Kingdom (UK)',
  GHA: 'Ghana',
  GNQ: 'Equatorial Guinea',
  GRC: 'Greece',
  GUY: 'Guyana',
  HRV: 'Croatia',
  HUN: 'Hungary',
  IDN: 'Indonesia',
  IND: 'India',
  IRL: 'Ireland',
  IRN: 'Iran',
  IRQ: 'Iraq',
  ITA: 'Italy',
  ISR: 'Israel',
  JDA: 'Malaysia-Thailand JDA',
  JOR: 'Jordan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KSZ: 'Kuwait - Saudi Arabia Partitioned Zone',
  KUR: 'Kurdistan Region of Iraq (KRI)',
  KWT: 'Kuwait',
  LBY: 'Libya',
  MAR: 'Morocco',
  MEX: 'Mexico',
  MOZ: 'Mozambique',
  MRT: 'Mauritania',
  MMR: 'Myanmar',
  MYS: 'Malaysia',
  NAM: 'Namibia',
  NER: 'Niger',
  NGA: 'Nigeria',
  NLD: 'Netherlands',
  NOR: 'Norway',
  NZL: 'New Zealand',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PER: 'Peru',
  PHL: 'Philippines',
  PNG: 'Papua New Guinea (PNG)',
  POL: 'Poland',
  ROU: 'Romania',
  RUS: 'Russia',
  QAT: 'Qatar',
  SAU: 'Saudi Arabia',
  SDN: 'Sudan',
  SEN: 'Senegal',
  SRB: 'Serbia',
  SSD: 'South Sudan',
  SUR: 'Suriname',
  SYR: 'Syria',
  TCD: 'Chad',
  THA: 'Thailand',
  TKM: 'Turkmenistan',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TZA: 'Tanzania',
  UGA: 'Uganda',
  USA: 'US Gulf of Mexico (GoM)',
  UZB: 'Uzbekistan',
  VNM: 'Vietnam',
  YEM: 'Yemen',
  ZAF: 'South Africa',
};
