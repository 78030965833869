import { makeStyles } from '@material-ui/core/styles';

export const maChartGraphNewStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 48px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 36px)',
    },
  },
  button: {
    ...theme.palette.button,
    width: '128px',
  },
  controlsContainer: {
    height: 'calc(100% - 54.8px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 48.8px)',
    },
  },
}));
