import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  defaultCheckListFilterTypes,
  defaultMinMaxFilterTypes,
  defaultCheckBoxFilterTypes,
} from './filterTypes';
import InputList from '../InputList';
import CheckListItem from './checkListItem';
import CheckBoxItem from './checkBoxItem';
import { analytics, dimensions } from '../../utils/analytics';
import { getUserId } from '../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  bottomFilters: {
    padding: '0 10px',
    height: '60%',
  },
  checkListContainer: {
    height: '100%',
  },
  minMaxContainer: {
    ...theme.palette.altScrollbarBlue,
    overflow: 'auto',
    height: '100%',
    padding: '5px 15px 0',
    flexWrap: 'nowrap',
  },
}));

export default ({
  filters,
  applyFilters,
  checkListItems,
  checkListFilterTypes = defaultCheckListFilterTypes,
  minMaxFilterTypes = defaultMinMaxFilterTypes,
  checkBoxFilterTypes = defaultCheckBoxFilterTypes,
  context,
  googleAnalyticsTag,
}) => {
  const classes = useStyles();

  const setFilter = (filter, name) => {
    const newFilters = {
      ...filters,
    };
    newFilters[name] = filter;
    gtag(
      'event',
      ANALYTICS_EVENTS.event, {
        'event_category': googleAnalyticsTag,
        'event_action': ANALYTICS_EVENT_ACTIONS.singleFilter,
        'event_label': analytics({ [name]: newFilters[name] }),
        'userId': getUserId(),
        ...dimensions(newFilters, context),
      }
    );
    applyFilters(newFilters);
  };

  return (
    <Grid className={classes.bottomFilters} container item direction="row">
      <Grid className={classes.checkListContainer} container item xs={8} justifyContent="space-evenly">
        {checkListFilterTypes.map((filter) => (
          <CheckListItem
            key={filter.name}
            checkListItems={checkListItems}
            filter={filter}
            filters={filters}
            setFilter={setFilter}
          />
        ))}
      </Grid>
      <Grid className={classes.minMaxContainer} container item xs={4} direction="column">
        <InputList values={filters} setInput={setFilter} items={minMaxFilterTypes} />
        <Divider style={{ marginTop: '10px' }} />
        {checkBoxFilterTypes.map((filter) => (
          <CheckBoxItem
            key={`${context}-modal-${filter.label}`}
            item={filter}
            onClick={setFilter}
            value={filters[filter.key]}
          />
        ))}
      </Grid>
    </Grid>
  );
};
