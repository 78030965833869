import { makeStyles } from '@material-ui/core/styles';

export const useTransactionAnalyticsStyles = makeStyles(() => ({
  graphWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1rem',
    rowGap: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    height: '100%',

    '& > .js-plotly-plot > .plotly > .svg-container > svg': {
      overflow: 'unset',
    },
  },
}));
