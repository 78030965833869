import React, { useContext, useEffect } from 'react';
import Driver from 'driver.js';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Confetti from './Confetti';
import CurrentUserContext from '../CurrentUserContext';
import LandingPageItems from './LandingPageItems';
import SideBar from './SideBar';
import WalkthroughContext from '../WalkthroughContext';
import { titleize } from '../../utils/helpers';

import startDriver from './driver';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    ...theme.sizes.fullPage,
  },
  scroll: {
    ...theme.palette.scrollbar,
    ...theme.palette.primaryBackground,
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  landingPageContainer: {
    margin: 0,
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '40px',
      paddingLeft: '35px',
      paddingRight: '35px',
    },
  },
  header: {
    padding: '30px 30px 20px 30px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const { walkthrough, setWalkthrough } = useContext(WalkthroughContext);
  const { currentUser, refreshProvider } = useContext(CurrentUserContext);

  const fullName = currentUser ? currentUser.firstName + ' ' + currentUser.lastName : null;

  const displayName = fullName
    ? fullName
        .toLowerCase()
        .split(' ')
        .map((n) => (n ? titleize(n) : ''))
        .join(' ')
    : '';

  let imgSize = { width: '86px', height: '86px' };
  const smDevice = useMediaQuery(theme.breakpoints.down('md'));
  const lgDevice = useMediaQuery(theme.breakpoints.down('lg'));
  if (smDevice) {
    imgSize = { width: '50px', height: '50px' };
  } else if (lgDevice) {
    imgSize = { width: '70px', height: '70px' };
  }

  useEffect(() => {
    if (walkthrough) {
      const driver = new Driver({ allowClose: false, onReset: () => setWalkthrough(false) });
      startDriver(driver);
    }
  }, [walkthrough]);

  // on mount after login, need to refresh user_query
  useEffect(() => {
    refreshProvider();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.scroll}>
        <Grid container spacing={1} className={classes.landingPageContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.header}>
              {displayName}, Welcome to your Welligence Dashboard
            </Typography>
            <Divider />
          </Grid>
          {LandingPageItems.map((item) => {
            const Component = item.component;
            if (item.released) {
              return (
                <Grid key={item.label} item md={6} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <img
                        id={item.label}
                        src={item.img}
                        alt={item.label}
                        width={imgSize.width}
                        height={imgSize.height}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h6">{item.label}</Typography>
                      <Divider />
                      <Typography variant="caption">{item.desc}</Typography>
                    </Grid>
                    {Component ? <Component /> : null}
                  </Grid>
                </Grid>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </div>
      <SideBar />
      {currentUser.seenUserWelcome !== undefined && !currentUser.seenUserWelcome ? (
        <Confetti setWalkthrough={setWalkthrough} refreshProvider={refreshProvider} />
      ) : null}
    </div>
  );
};
