import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InputItem from './inputItem';
import { removeDoubleNegative } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  label: {
    textTransform: 'none',
  },
}));

export default ({
  items = [],
  setInput,
  values,
  tab = false,
  showDivider = true,
  alignRight = false,
  originCashflow = false,
}) => {
  const classes = useStyles();

  return items.map((item, idx) => (
    <Grid
      key={`input-list-item-${item.label}-${idx}`}
      container
      item
      direction="column"
      style={{ padding: '5px 0' }}
    >
      <Typography className={classes.label} variant="overline">
        {item.label}
      </Typography>
      {showDivider && <Divider />}
      <Grid
        style={{ paddingTop: '5px', paddingBottom: '10px' }}
        container
        item
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {item.icon}
        {item.isMinMax ? (
          <>
            <InputItem
              id={item.id}
              inputLabel={item.min?.inputLabel}
              isMinMax
              onChange={(e) =>
                setInput(
                  e.target.value.match(/^(\d|\.|-)+$/) ? removeDoubleNegative(e.target.value) : '',
                  item.min?.key,
                )
              }
              value={values[item.min?.key]}
              tab={tab}
              alignRight={alignRight}
              originCashflow={originCashflow}
            />
            <InputItem
              id={item.id}
              inputLabel={item.max?.inputLabel}
              isMinMax
              onChange={(e) =>
                setInput(
                  e.target.value.match(/^(\d|\.|-)+$/) ? removeDoubleNegative(e.target.value) : '',
                  item.max?.key,
                )
              }
              value={values[item.max?.key]}
              tab={tab}
              alignRight={alignRight}
              originCashflow={originCashflow}
            />
          </>
        ) : (
          <InputItem
            id={item.id}
            inputLabel={item.inputLabel}
            required={item.required}
            onChange={(e) =>
              setInput(
                e.target.value.match(/^(\d|\.|-)+$/) ? removeDoubleNegative(e.target.value) : '',
                item.key,
              )
            }
            value={values[item.key]}
            tab={tab}
            alignRight={alignRight}
            originCashflow={originCashflow}
          />
        )}
      </Grid>
    </Grid>
  ));
};
