import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  checkListRoot: {
    padding: 0,
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  border: {
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  radioRowLabel: {
    fontWeight: 'bold',
  },
}));

export const MetricNew = ({ title = 'Select Metric', options, setValue, value, isDisabled }) => {
  const classes = useStyles();

  return (
    <FormControl style={{ width: '100%' }}>
      <Typography className={classes.label} variant="overline">
        {title}
      </Typography>
      <RadioGroup
        value={value}
        name="graph-metric-radio-buttons-group"
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((option, idx) => (
          <div className={classes.border} key={option + idx}>
            <FormControlLabel
              className={classes.radioRow}
              classes={{ label: classes.radioRowLabel }}
              value={option}
              control={<Radio />}
              disabled={isDisabled}
              label={option}
              labelPlacement="start"
            />
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
