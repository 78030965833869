import { FilterKeys } from './filterTypes';
import { GHG_CONTEXT } from '../GHG/constants';
// function for getting a subset of filters to send to autocomplete dropdown
// component as prop titled 'propVariables' used for dependent filters
// this will be replaced by propVariables key within each filterType object
// as seen in current CCUS filter types
// TODO: Deprecate this in favor of propVariables within filterType object
// https://trello.com/c/33GE8Ywi/2151-filters-deprecate-preparepropvariables-function
export const preparePropVariables = (filters, context) => {
  return {
    assets: filters.assets || [],
    countries: filters.countries?.map((a) => a.isoCode) || [],
    companies: filters.companies?.map((a) => a.name) || [], // TODO: This may need to change for CCUS
    basins: filters.basins?.map((a) => a.name) || [],
    facilities: filters.facilities,
    industries: filters.industries,
    operator: !!filters.operator,
    hasGhgEmissions: context === GHG_CONTEXT,
    hasMaTransactions: filters.hasMaTransactions || false,
    activeInBlocks: false,
    activeInLeases: false,
    includeHistoricalParticipants: !!filters.includeHistoricalParticipants,
    sortBy: 'displayName',
  };
};

// function for identifying what to display in the chip label
// for each value of a given filter
export const identifyChipLabel = (filter, value) => {
  const { chipOptionKey } = filter;
  const option = value[chipOptionKey];
  const { displayName, name } = value;

  return option || displayName || name || '';
};

// function to check if portfolio is empty
export const portfolioIsEmpty = ({
  portfolios = [],
  developmentStatusData = [],
  resourceThemeData = [],
  shoreStatusData = [],
}) => {
  return portfolios.every((portfolio) =>
    Object.keys(portfolio.filters).every((filter) => {
      switch (filter) {
        case FilterKeys.assets:
        case FilterKeys.countries:
        case FilterKeys.companies:
        case FilterKeys.basins:
        case FilterKeys.assetUnits:
          return portfolio.filters[filter].length === 0;
        case FilterKeys.resourceThemes:
          return (
            portfolio.filters.resourceThemes.length === 0 ||
            portfolio.filters.resourceThemes.length === resourceThemeData.resourceType.length
          );
        case FilterKeys.developmentStatuses:
          return (
            portfolio.filters.developmentStatuses.length === 0 ||
            portfolio.filters.developmentStatuses.length ===
              developmentStatusData.developmentStatus.length
          );
        case FilterKeys.shoreStatuses:
          return (
            portfolio.filters.shoreStatuses.length === 0 ||
            portfolio.filters.shoreStatuses.length === shoreStatusData.shoreStatus.length
          );
        default:
          return !portfolio.filters[filter];
      }
    }),
  );
};
