import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ResultList } from './ResultList';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc(100% - 32px)',
    overflowY: 'auto',
  },
  content: { maxWidth: '750px', marginLeft: 'auto', marginRight: 'auto' },
}));

const groupByAction = (results) => {
  return results.reduce((acc, v) => {
    v.actions.forEach((action) => {
      acc[action] = [...(acc[action] || []), { ...v, actions: [], action }];
    });
    return acc;
  }, {});
};

export const ResultListGroups = ({ rows, onActionClick }) => {
  const classes = useStyles();
  const rowsGroupedByAction = groupByAction(rows);

  return (
    <Box className={classes.root} data-testid="gs-results">
      <Box className={classes.content}>
        {Object.keys(rowsGroupedByAction).map((action) => {
          const rows = rowsGroupedByAction[action];
          return (
            <ResultList key={action} rows={rows} action={action} onActionClick={onActionClick} />
          );
        })}
      </Box>
    </Box>
  );
};
