import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2} />
      <Grid container item spacing={2} xs={8}>
        <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => window.open('https://welligence.com/intelligence', '_blank')}
          >
            <EmojiObjectsIcon fontSize="small" />
            &nbsp;explore intelligence
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
