import React, { Suspense, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../Loading';
import {
  ALTERNATE_GRID_COLOR,
  ALTERNATE_BG_COLOR,
  DEFAULT_COLOR,
  DEFAULT_GRID_COLOR,
  TRANSPARENT,
  WHITE,
  MILLION,
  DEFAULT_YAXIS_VALUES,
  getYAxisSequenceFromRange,
  getYAxisSequenceFromMax,
  getTickValuesAndText,
  MAX_NAME_LENGTH,
  BAR_CHART_CONTEXT,
  LINE_CHART_CONTEXT,
  TREE_MAP_CONTEXT,
} from './constants';
import { Grid, Typography } from '@material-ui/core';

// Allowing this large component to be lazy loaded and built into separate bundle
const Plot = React.lazy(() => import(/* webpackChunkName: "plotly" */ 'react-plotly.js')); // eslint-disable-line

const useStyles = makeStyles({
  graph: {
    width: (props) => (props.isNarrow ? 'calc(100% - 110px)' : '100%'),
    height: '100%',
  },
});

// data - object to plot chart
// layout - any addition to layout properties or overwrite default rules set here

export default ({
  config,
  data,
  layout,
  title,
  xlabel,
  ylabel,
  loading,
  onUpdate,
  onClick,
  onRelayout,
  onInitialized,
  onAfterPlot,
  onLegendDoubleClick = () => true, // returns true to keep default behavior
  maxY,
  darkTheme,
  customLegend = false,
  isNarrow = false,
  context,
}) => {
  const classes = useStyles({ isNarrow });

  const yAxisClasses = useMemo(() => {
    // default sequence
    let yAxisValues = DEFAULT_YAXIS_VALUES;

    // we only want this class if maxY is greater than a million and our zoom is also greater than a million
    if (
      maxY &&
      maxY >= MILLION &&
      (!layout.yaxis?.range || // the range doesn't exist so we don't need to worry about zoom
        layout.yaxis?.range?.length === 0 || // the range exists but has no value so we don't need to worry about zoom
        (layout.yaxis?.range?.length > 0 && // the range exists so we need to check if the upper limit is greater than a million
          (layout.yaxis.range[1] >= MILLION || layout.yaxis.range[1] === undefined))) // upper limit is greater than a million
    ) {
      // Checks if the user is zoomed and if the upper limit is greater than a million
      if (layout.yaxis?.range?.length > 0 && layout.yaxis.range[1] >= MILLION) {
        const { range } = layout.yaxis;
        yAxisValues = getYAxisSequenceFromRange(range);
      } else if (maxY && maxY >= MILLION) {
        // Otherwise base y axis off of the max value of the chart
        // Get format for y axis labels based on the max value of chartgetYAxisClassFromMax
        yAxisValues = getYAxisSequenceFromMax(maxY);
      }

      const { tickvals, ticktext } = getTickValuesAndText(yAxisValues);
      return {
        tickmode: 'array',
        tickvals,
        ticktext,
      };
    } else if (
      (maxY && maxY < 5) ||
      (layout.yaxis?.range?.length > 0 && layout.yaxis.range[1] - layout.yaxis.range[0] < 5)
    ) {
      // Start showing decimals if the max value is less than 5 or if the zoom range is less than 5
      return { tickformat: ',.2f' };
    }

    return {};
  }, [layout.yaxis?.range, maxY]);

  const { nticks, renderedData } = useMemo(() => {
    // limits the number of y axis labels if there is no data
    const nticks = data.length === 0 ? 5 : 0;

    const renderedData = customLegend
      ? data
      : data.map((d) => ({
          ...d,
          name:
            d.name?.length > MAX_NAME_LENGTH
              ? d.name.substring(0, MAX_NAME_LENGTH) + '...'
              : d.name,
        }));

    return { nticks, renderedData };
  }, [data, customLegend]);

  const emptyChart = useMemo(() => {
    switch (context) {
      case BAR_CHART_CONTEXT:
      case LINE_CHART_CONTEXT:
        return renderedData.length === 0 || (renderedData.length === 1 && !renderedData[0].name);
      case TREE_MAP_CONTEXT:
        return renderedData[0].labels.length === 0;
    }
    return false;
  }, [data]);

  return loading ? (
    <Loading />
  ) : emptyChart ? (
    <Grid container style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h6" align="center">
        No data available for the selected filters...
      </Typography>
    </Grid>
  ) : (
    <Suspense fallback={<Loading />}>
      <Plot
        className={classes.graph}
        data={renderedData}
        layout={{
          font: { family: "'Barlow', sans-serif" },
          showlegend: false,
          paper_bgcolor: darkTheme ? ALTERNATE_BG_COLOR : WHITE,
          plot_bgcolor: darkTheme ? TRANSPARENT : WHITE,
          ...layout,
          title: {
            font: {
              family: "'Barlow', sans-serif",
              size: 20,
            },
            text: title,
            ...layout.title,
          },
          xaxis: {
            title: xlabel,
            color: darkTheme ? WHITE : DEFAULT_COLOR,
            gridcolor: darkTheme ? ALTERNATE_GRID_COLOR : DEFAULT_GRID_COLOR,
            ...layout.xaxis,
          },
          yaxis: {
            title: ylabel,
            tickformat: ',d',
            nticks,
            color: darkTheme ? WHITE : DEFAULT_COLOR,
            gridcolor: darkTheme ? ALTERNATE_GRID_COLOR : DEFAULT_GRID_COLOR,
            zerolinecolor: darkTheme ? ALTERNATE_GRID_COLOR : DEFAULT_GRID_COLOR,
            ...layout.yaxis,
            ...yAxisClasses,
          },
        }}
        config={{
          displaylogo: false,
          responsive: true,
          ...config,
        }}
        onUpdate={onUpdate}
        onInitialized={onInitialized ? onInitialized : onUpdate}
        onClick={onClick}
        onRelayout={onRelayout}
        onAfterPlot={onAfterPlot}
        onLegendDoubleClick={onLegendDoubleClick}
      />
    </Suspense>
  );
};
