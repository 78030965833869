import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete } from '../../AutoComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
  },
  label: {
    ...theme.palette.secondaryAccentText,
    margin: '-3px',
  },
}));

export default ({ displayedItem, loading, data, onChange }) => {
  const classes = useStyles();

  if (!displayedItem || loading || !data || !data.asset || !(data.asset.assetModels.length > 0)) {
    return null;
  }

  const { assetModels, baseName } = data.asset;
  const assetModel = assetModels.find((assetModel) => assetModel.name === displayedItem.name);

  if (!assetModel) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AutoComplete
        getData={null}
        menuItems={assetModels}
        onChange={(value) => {
          onChange({
            ...value,
            type: 'asset',
          });
        }}
        value={assetModel}
        label={`${baseName} Models`}
        optionKey="displayName"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={false}
      />
    </div>
  );
};
