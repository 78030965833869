import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { SUBSCRIBER_DOWNLOAD_TYPES } from './constants';
import { SECURE_SUBSCRIBER_DOWNLOAD_QUERY } from './operations';
import { openDownloadUrl } from '../Downloads/helpers';
import SnackbarContext from '../SnackbarContext';
import { defaultDimensions } from '../../utils/analytics';
import { getUserId } from '../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  reportsButton: {
    ...theme.palette.button,
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
    zIndex: 1,
  },
}));

export default () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const { slug } = useParams();
  const [renderedReport, setReport] = useState(null);

  useQuery(SECURE_SUBSCRIBER_DOWNLOAD_QUERY, {
    variables: { reportType: SUBSCRIBER_DOWNLOAD_TYPES.HTML, slug: slug },
    onCompleted: (data) => {
      if (data.secureSubscriberDownload && data.secureSubscriberDownload.url) {
        const { url, downloadMessage } = data.secureSubscriberDownload;
        setSnack({
          open: true,
          message: downloadMessage,
          severity: 'success',
        });

        fetch(url)
          .then((res) => {
            if (res.ok) {
              return res.text();
            } else {
              throw new Error('Not 2xx response');
            }
          })
          .then((html) => {
            let processedHtml = html.replace(
              /#sidebar{background-color/gm,
              '#sidebar{display:none;background-color',
            );
            processedHtml = processedHtml.replace(
              /#page-container{position:absolute;top:0;left:0;/gm,
              '#page-container{',
            );

            setReport(processedHtml);
          })
          .catch(() => {
            setReport(
              'This report could not be rendered. Please try downloading this Intelligence Report to view.',
            );
          });
      } else {
        setReport('This report is not available.');
      }
    },
    onError: (error) => {
      setReport(error.message);
    },
  });
  const [getSecureDownload] = useLazyQuery(SECURE_SUBSCRIBER_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureSubscriberDownload;
      setSnack({
        open: true,
        message: `Your download should begin shortly.${downloadMessage}`,
        severity: 'success',
      });
      openDownloadUrl(url, filename);
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const getDownloadUrl = () => {
    gtag(
      'event',
      ANALYTICS_EVENTS.event, {
        'event_category': 'ProprietaryDownloads',
        'event_action': ANALYTICS_EVENT_ACTIONS.download,
        'event_label': slug,
        'userId': getUserId(),
        ...defaultDimensions,
      }
    );
    getSecureDownload({
      variables: { reportType: SUBSCRIBER_DOWNLOAD_TYPES.PDF, slug: slug },
    });
  };

  const subscriberDownloadHtml = () => {
    if (renderedReport) {
      return { __html: renderedReport };
    } else {
      return { __html: 'Loading report, please wait' };
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Fab
        onClick={() => {
          getDownloadUrl();
        }}
        disabled={renderedReport === null}
        className={classes.reportsButton}
        variant="extended"
      >
        <GetAppIcon fontSize="small" />
        &nbsp;download report
      </Fab>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        dangerouslySetInnerHTML={subscriberDownloadHtml()}
      />
    </Grid>
  );
};
