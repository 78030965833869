import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SelectableInfoItem from './selectableInfoItem';
import { getTemplate } from './template';
import { snakeToCamel } from '../../../utils/helpers';
import { MAP_LAYER_IDS } from '../../Map/Utils/constants';
import SummaryListItem from '../../SummaryList/summaryListItem';
import { getLabelUnitValueFromTemplate } from '../../SummaryList/SummaryItems/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
    margin: 0,
  },
  groupDivider: {
    border: 'none',
    padding: 0,
  },
  groupLabel: {
    fontWeight: 'bold',
    width: '130px',
    padding: '15px 3px 3px',
  },
  groupRoot: {
    ...theme.palette.mapInfoSection,
    margin: 0,
    paddingBottom: '10px',
  },
}));

const Divider = () => (
  <div
    style={{
      marginTop: '5px',
      marginBottom: '5px',
    }}
  />
);

export default ({ layerCountry, layerName, loading, item, data, tab, changeQueriedItem }) => {
  const classes = useStyles();
  const template = useMemo(
    () =>
      layerName === MAP_LAYER_IDS.basins
        ? getTemplate('basin')
        : getTemplate(item.entityType, layerCountry, tab, changeQueriedItem),
    [item, layerCountry, tab],
  );

  const getItemRow = (templateItem, itemData, key) => {
    if (!itemData) return null;
    const { accessor, getEntity, label, template, type } = templateItem;
    let extractedLabel, unit, value;

    const extractValue = (accessor) => {
      if (typeof accessor === 'function') {
        return accessor(itemData);
      }
      return itemData[accessor];
    };

    switch (type) {
      case 'graph': {
        return (
          <TableRow key={`${key}-graph`} style={{ display: 'block' }}>
            {extractValue(accessor)}
          </TableRow>
        );
      }
      case 'group': {
        const entityToExtract = getEntity ? getEntity(itemData) : itemData;
        const rows = template
          .map((t, i) => getItemRow(t, entityToExtract, `${key}-group-${i}`, i === 0))
          .filter((r) => !!r);
        return rows.length
          ? [
              <TableRow key={`${key}-group-label`}>
                {label ? (
                  <TableCell className={classes.groupLabel}>{label}</TableCell>
                ) : (
                  <TableCell className={classes.groupDivider} />
                )}
              </TableRow>,
              ...rows,
              <TableRow key={`${key}-group-divider`} className={classes.groupRoot} />,
            ]
          : null;
      }
      case 'multiGroup': {
        const groups = accessor(itemData);
        const rows = [];
        groups?.forEach((g, i) => {
          g = { ...g, index: i };
          rows.push(
            <TableHead key={`${key}-multiGroup-${i}`} className={classes.groupRoot}>
              {template.map((t, j) => getItemRow(t, g, `${key}-multiGroup-${i}-${j}`, i === 0))}
            </TableHead>,
          );
        });
        return groups?.length
          ? [
              <TableRow key={`${key}-multiGroup-label`}>
                {rows.length &&
                  (label ? (
                    <TableCell className={classes.groupLabel}>{label}</TableCell>
                  ) : (
                    <TableCell className={classes.groupDivider} />
                  ))}
              </TableRow>,
              ...rows,
            ]
          : null;
      }
      case 'break': {
        return <Divider key={key} />;
      }
      case 'selectable': {
        extractedLabel = extractValue(label);
        value = extractValue(accessor);
        return <SelectableInfoItem key={key} label={extractedLabel} valuesAndActions={value} />;
      }
      default: {
        ({
          label: extractedLabel,
          unit,
          value,
        } = getLabelUnitValueFromTemplate(templateItem, itemData));
      }
    }

    return (
      value && (
        <SummaryListItem
          variant="table"
          key={key}
          label={extractedLabel}
          unit={unit}
          value={value}
        />
      )
    );
  };

  return (
    <Table style={{ display: 'block' }}>
      <TableHead className={classes.root}>
        {layerName === MAP_LAYER_IDS.basins
          ? template.map((t, i) => getItemRow(t, item, `info-${i}`))
          : !loading &&
            item &&
            data &&
            data[snakeToCamel(item.entityType)] &&
            template.map((t, i) => getItemRow(t, Object.values(data)[0], `info-${i}`)).flat()}
      </TableHead>
    </Table>
  );
};
