import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { LineGraph } from '../../../Graphs';
import { FACILITY_GRAPH_QUERY } from './operations';
import ErrorBoundary from '../../../ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  graphsContainer: {
    ...theme.palette.mapInfoSection,
    maxWidth: '100%',
  },
  graph: {
    backgroundColor: 'white',
    margin: '5px',
    padding: '5px',
  },
}));

export default ({ facilityId }) => {
  const classes = useStyles();

  const { loading, data } = useQuery(FACILITY_GRAPH_QUERY, {
    variables: {
      id: facilityId,
    },
  });

  const liquidsColors = ['#00B359', '#82B200', '#09ADF0', '#006CBF'];
  const gasColors = ['#FF3333', '#D10808'];
  const liquidsLabels =
    data && data.graphFacilityProductions ? data.graphFacilityProductions.liquidsLabels : [];
  const gasLabels =
    data && data.graphFacilityProductions ? data.graphFacilityProductions.gasLabels : [];

  const liquidsGraph =
    !loading && data.graphFacilityProductions.liquids.length > 1 ? (
      <div className={classes.graph}>
        <LineGraph
          key={`facility-liquids-graph-${facilityId}`}
          csv={data.graphFacilityProductions.liquids}
          colors={liquidsColors}
          labels={liquidsLabels}
          title="Liquids Production"
          ylabel="Thousand bbl/d"
        />
      </div>
    ) : null;

  const gasGraph =
    !loading && data.graphFacilityProductions.gas.length > 1 ? (
      <div className={classes.graph}>
        <LineGraph
          key={`facility-gas-graph-${facilityId}`}
          csv={data.graphFacilityProductions.gas}
          colors={gasColors}
          labels={gasLabels}
          title="Gas Production"
          ylabel="MMcf/d"
        />
      </div>
    ) : null;

  return (
    <ErrorBoundary>
      {(liquidsGraph || gasGraph) && (
        <div className={classes.graphsContainer}>
          {liquidsGraph}
          {liquidsGraph && gasGraph && <div style={{ margin: '10px 0' }} />}
          {gasGraph}
        </div>
      )}
    </ErrorBoundary>
  );
};
