import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { LOAD_PRICE_DECKS } from '../operations';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '250px',
    margin: '10px',
  },
  button: {
    ...theme.palette.button,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '10px 0 20px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textArea: {
    disableUnderline: true,
    paddingTop: '2.5rem',
  },
}));

export const LoadPriceDeck = ({ setPriceDecks, handleClose }) => {
  const classes = useStyles();
  const [selectedPriceDeck, setSelectedPriceDeck] = useState('');
  const { loading, data, refetch } = useQuery(LOAD_PRICE_DECKS);

  useEffect(() => {
    refetch && refetch();
  }, []);

  const loadPriceDecks = () => {
    setPriceDecks(selectedPriceDeck.priceDeck);
    handleClose();
  };

  return (
    <div className={classes.content}>
      <FormControl className={classes.input} variant="filled">
        <InputLabel>Price Decks</InputLabel>
        <Select
          disableUnderline
          value={selectedPriceDeck}
          onChange={(e) => setSelectedPriceDeck(e.target.value)}
        >
          {!loading && data
            ? data.loadPriceDecks.priceDecks.map((priceDeck) => (
                <MenuItem key={`load-priceDeck-${priceDeck.name}`} value={priceDeck}>
                  {priceDeck.name}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
      <TextField
        className={classes.input}
        disabled
        label="Notes"
        variant="filled"
        multiline
        rows={6}
        defaultValue={selectedPriceDeck ? selectedPriceDeck.notes : ''}
        InputProps={{
          className: classes.textArea,
        }}
      />

      <div className={classes.buttonWrapper}>
        <Button
          disabled={!selectedPriceDeck.priceDeck}
          variant="contained"
          className={classes.button}
          onClick={loadPriceDecks}
        >
          Load Price Deck
        </Button>
      </div>
    </div>
  );
};
