import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  industryTrackerItemContainer: {
    border: 'solid 1px #f2f5f7',
    padding: '25px',
    cursor: 'pointer',
  },
  industryTrackerItemInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  industryTrackerItemChip: {
    ...theme.palette.accent,
  },
  industryTrackerItemChipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listIcon: {
    color: '#5f91cc',
  },
}));

export default ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.industryTrackerItemContainer} container onClick={onClick}>
      <Grid className={classes.industryTrackerItemChipContainer} item xs={3}>
        <GetAppIcon className={classes.listIcon} />
      </Grid>
      <Grid className={classes.industryTrackerItemInfo} item xs={9}>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
    </Grid>
  );
};
