import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import BasicFilters from './basicFilters';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import FilterAltIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandedFilters from './expandedFilters';
import { defaultFilterTypes } from './filterTypes';

const useStyles = makeStyles((theme) => ({
  accordion: {
    '&.MuiAccordion-root': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
  },
  alternateColor: {
    backgroundColor: '#F5F5F5',
  },
  button: {
    ...theme.palette.button,
    margin: '13px 2px',
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  deletePortfolio: {
    ...theme.palette.actionButton,
    color: 'grey',
    alignSelf: 'center',
  },
  details: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
  filterItem: {
    flexBasis: '15%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
}));

export default ({
  applyBasicFilters,
  basicFilterTypes = defaultFilterTypes,
  checkListItems,
  context,
  deletePortfolio,
  googleAnalyticsTag,
  idx,
  portfolio,
  removeable,
  inModal,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Grid
      className={clsx({ [classes.alternateColor]: idx % 2 === 1 })}
      container
      item
      style={{ padding: '10px 20px', borderBottom: 'solid thin lightgrey' }}
      justifyContent="space-between"
    >
      <BasicFilters
        applyFilters={(filters) => applyBasicFilters(filters, idx)}
        basicFilterTypes={basicFilterTypes}
        context={context}
        filterItemClass={classes.filterItem}
        filters={portfolio.filters}
        googleAnalyticsTag={googleAnalyticsTag}
        inModal={inModal}
      />
      {removeable && (
        <Tooltip title="Remove portfolio">
          <CloseIcon className={classes.deletePortfolio} onClick={() => deletePortfolio(idx)} />
        </Tooltip>
      )}
      <Button
        startIcon={<FilterAltIcon />}
        id="portfolio-analysis-advanced-filters-button"
        className={classes.button}
        variant="contained"
        onClick={() => setExpanded(!expanded)}
      >
        All Filters
      </Button>
      <Accordion square className={classes.accordion} expanded={expanded}>
        <div />
        <AccordionDetails className={classes.details}>
          <ExpandedFilters
            filters={portfolio.filters}
            applyFilters={(filters) => applyBasicFilters(filters, idx)}
            checkListItems={checkListItems}
            context={context}
            googleAnalyticsTag={googleAnalyticsTag}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
