import React, { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Map, MapProvider } from './Map';
import {
  BarGraph,
  BarGraphContextCosts,
  BarGraphContextEmissions,
  BarGraphContextProject,
  BarGraphContextStorage,
} from './BarGraphs';
import {
  CCUS_COSTS_BAR_GARPH_ID,
  CCUS_EMISSIONS_BAR_GARPH_ID,
  CCUS_MAP_ID,
  CCUS_PROJECT_BAR_GARPH_ID,
  CCUS_STORAGE_BAR_GARPH_ID,
} from '../driver';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.primaryBackground,
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  container: {
    transition: 'flex-basis .1s, max-width .1s',
  },
}));

export default ({ filterIsEmpty, mapFilters }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // When this is set to true, will trigger a function inside of the map component to re-size and fit container
  const [transitionFinished, setTransitionFinished] = useState(false);
  // This is used to track the width of the map container
  const mapWidth = useRef(0);
  // This is used to retrieve the new width of the map container
  const mapContainer = useRef(null);

  // This is used to trigger a re-render of the map and charts when the container is finished resizing.
  const onTransitionEnd = () => {
    const currMapWidth = mapWidth.current;
    const newMapWidth = mapContainer.current.offsetWidth;
    // Previously, onTransitionEnd was being called continuously on every page render whether the map changed size or not.
    // This constant re-rendering was causing odd UI behavior with the charts.
    // We only want to trigger a re-render of the components when the size of the map container actually changes.
    // Compare the currently stored value of the map width to the new value of the map width.
    if (currMapWidth !== newMapWidth) {
      mapWidth.current = newMapWidth;
      // Setting transitionFinished to true means that the map container has finished rendering with its new width.
      // This will trigger a function inside of the map component that will re-size the map to fit the container
      // as well as trigger a window resize event that will trigger the charts to re-size and fit their containers.
      setTransitionFinished(true);
    }
  };

  return (
    <Grid container className={classes.root} spacing={1} columns={{ xs: 2 }} justifyContent="space-evenly">
      <Grid
        ref={mapContainer}
        id={CCUS_MAP_ID}
        item
        className={classes.container}
        onTransitionEnd={onTransitionEnd}
        xs={open ? 10 : 3}
      >
        <MapProvider>
          <Map
            filterIsEmpty={filterIsEmpty}
            mapFilters={mapFilters}
            open={open}
            setOpen={setOpen}
            transitionFinished={transitionFinished}
            setTransitionFinished={setTransitionFinished}
          />
        </MapProvider>
      </Grid>
      <Grid
        container
        item
        className={classes.container}
        onTransitionEnd={onTransitionEnd}
        xs={open ? 2 : 9}
        spacing={2}
      >
        <Grid id={CCUS_EMISSIONS_BAR_GARPH_ID} item xs={open ? 12 : 6}>
          <BarGraph includeId context={BarGraphContextEmissions} />
        </Grid>
        <Grid id={CCUS_COSTS_BAR_GARPH_ID} item xs={open ? 12 : 6}>
          <BarGraph context={BarGraphContextCosts} excludeXAxisText />
        </Grid>
        <Grid id={CCUS_PROJECT_BAR_GARPH_ID} item xs={open ? 12 : 6}>
          <BarGraph context={BarGraphContextProject} />
        </Grid>
        <Grid id={CCUS_STORAGE_BAR_GARPH_ID} item xs={open ? 12 : 6}>
          <BarGraph context={BarGraphContextStorage} />
        </Grid>
      </Grid>
    </Grid>
  );
};
