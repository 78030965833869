import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BarGraph from '../../../Graphs/barGraph';
import Modal from '../../../Modals';
import GraphContainer from '../../../Graphs/graphContainer';
import { getTickText, getTickVals } from './utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  graph: {
    display: 'flex',
    flex: '1 1 auto',
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
}));

export default ({ context }) => {
  const classes = useStyles();
  const {
    downloadCallback,
    graphType,
    loading,
    modalRange,
    onGraphUpdate,
    onRelayout,
    range,
    setModalRange,
    setOpenControlsModal,
    setShowPlotlyLegend,
    showPlotlyLegend,
    showPlotlyLegendToggle,
    trace,
    maxY,
    googleAnalyticsTag,
    graphTitles,
    sortDirection,
  } = useContext(context);
  const [open, setOpen] = useState(false);
  const showToggle = false;

  const closeFullscreen = () => {
    setOpen(false);
    setModalRange({ x: [], y: [] });
  };

  const title = `${graphType.label} ${graphTitles[graphType.value]}`;
  const ticktext = getTickText(trace);
  const tickvals = getTickVals(trace);

  return (
    <>
      <GraphContainer
        open={open}
        setOpen={() => setOpen(true)}
        setOpenControlsModal={setOpenControlsModal}
        setShowPlotlyLegend={setShowPlotlyLegend}
        showPlotlyLegend={showPlotlyLegend}
        showPlotlyLegendToggle={showPlotlyLegendToggle}
        title={title}
        loading={loading}
        downloadCallback={downloadCallback}
        googleAnalyticsTag={googleAnalyticsTag}
      >
        <BarGraph
          data={trace}
          layout={{
            barmode: 'stack',
            margin: { l: 50, r: 30, b: 30, t: 30 },
            xaxis: {
              range: range.x,
              automargin: true,
              tickmode: 'array',
              ticktext,
              tickvals,
              categoryorder: sortDirection.plotlyCategoryOrder,
            },
            yaxis: {
              range: range.y,
            },
            showlegend: showPlotlyLegendToggle && showPlotlyLegend,
            legend: {
              x: 1,
              xanchor: 'right',
              y: 1,
              bgcolor: 'rgba(236, 240, 245, 0.8)',
            },
          }}
          loading={loading}
          onRelayout={onRelayout}
          maxY={maxY}
        />
      </GraphContainer>
      <Modal
        handleClose={closeFullscreen}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose={true}
        styles={classes}
        disablePortal
      >
        <Grid container className={classes.root}>
          <GraphContainer
            open={open}
            setClose={closeFullscreen}
            setOpenControlsModal={setOpenControlsModal}
            showToggle={showToggle}
            title={title}
            loading={loading}
            downloadCallback={downloadCallback}
            googleAnalyticsTag={googleAnalyticsTag}
          >
            <BarGraph
              data={trace}
              layout={{
                barmode: 'stack',
                margin: { l: 50, r: 30, b: 30, t: 30 },
                xaxis: {
                  range: modalRange.x,
                  automargin: true,
                  tickmode: 'array',
                  ticktext: tickvals,
                  tickvals,
                  categoryorder: sortDirection.plotlyCategoryOrder,
                },
                yaxis: {
                  range: modalRange.y,
                },
                showlegend: showPlotlyLegendToggle && showPlotlyLegend,
              }}
              loading={loading}
              onRelayout={onRelayout}
              onUpdate={onGraphUpdate}
              maxY={maxY}
            />
          </GraphContainer>
        </Grid>
      </Modal>
    </>
  );
};
