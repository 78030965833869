import React, { useContext, useState, useEffect } from 'react';
import Driver from 'driver.js';

import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import AssetList from './AssetList';
import Filters from './Filters';
import Sidebar from './Sidebar';
import Support from '../Support';
import {
  useGlobalSearchParameters,
  getFulltextResultParametersFromSearch,
} from '../../utils/globalSearch';
import ValuationReports from './ValuationReports';
import WalkthroughContext from '../WalkthroughContext';
import {
  VALUATIONS_GOOGLE_ANALYTICS_TAG,
  VALUATIONS_CONTEXT,
  COMPANY_FILTER_TYPE,
} from './constants';
import { valuationsDriver } from './driver';
import ErrorBoundary from '../ErrorBoundary';
import SnackbarContext from '../SnackbarContext';
import {
  DEVELOPMENT_STATUS_WITH_FILTER_QUERY,
  DEVELOPMENT_TYPE_QUERY,
  SHORE_STATUS_QUERY,
} from '../Filters/operations';
import { COUNTRIES_QUERY } from '../Countries/operations';
import { ASSET_QUERY } from './AssetList/operations';
import { COMPANIES_QUERY } from '../Filters/operations';
import { AdvancedFilters, FilterKeys } from '../Filters';
import { analytics, dimensions } from '../../utils/analytics';
import {
  ASSETS,
  BASINS,
  COUNTRIES,
  DEVELOPMENT_STATUSES_CHECK_LIST,
  DEVELOPMENT_TYPES_CHECK_LIST,
  HYDROCARBONS,
  NPV_MIN_MAX,
  PRODUCTION_MIN_MAX,
  RESERVES_MIN_MAX,
  RESERVOIR_MIN_MAX,
  SHORE_STATUSES_CHECK_LIST,
} from '../Filters/filterTypes';
import { getUserId } from '../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';

export const defaultFilters = {
  [FilterKeys.npvMin]: '',
  [FilterKeys.npvMax]: '',
  [FilterKeys.reservesMin]: '',
  [FilterKeys.reservesMax]: '',
  [FilterKeys.productionMin]: '',
  [FilterKeys.productionMax]: '',
  [FilterKeys.developmentStatuses]: [],
  // [FilterKeys.Formation]: [],
  [FilterKeys.developmentTypes]: [],
  [FilterKeys.depthMin]: '',
  [FilterKeys.depthMax]: '',
  [FilterKeys.assets]: [],
  [FilterKeys.countries]: [],
  [FilterKeys.companies]: [],
  [FilterKeys.basins]: [],
  [FilterKeys.shoreStatuses]: [],
  [FilterKeys.hydrocarbons]: [],
  [FilterKeys.operator]: false,
  [FilterKeys.opportunities]: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPage,
    flexWrap: 'nowrap',
  },
  valuationsMain: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 0,
    borderTop: `solid ${theme.palette.baseColors.iceBlue.c100} thin`,
  },
  valuationsCenterContainer: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    width: '60%',
    ...theme.palette.primaryBackground,
    ...theme.palette.scrollbar,
  },
  valuationsContainer: {
    flexGrow: 1,
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const getDefaultFilters = () => {
  const filters = JSON.parse(sessionStorage.getItem(VALUATIONS_CONTEXT));
  if (
    filters &&
    Object.keys(defaultFilters).every((filter) => Object.keys(filters).includes(filter))
  ) {
    return filters;
  }
  return defaultFilters;
};

export default () => {
  const classes = useStyles();
  const { walkthrough, setWalkthrough } = useContext(WalkthroughContext);
  const { setSnack } = useContext(SnackbarContext);

  const [selectedAsset, setAsset] = useState(null);
  const [filters, setFilterState] = useState(getDefaultFilters());
  const [filterChanged, setFilterChanged] = useState(true);
  const [watchList, setWatchList] = useState(false);
  const [updateWatchList, setUpdateWatchList] = useState(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(defaultFilters);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [developmentStatuses, setDevelopmentStatuses] = useState([]);

  const { data: shoreStatusData } = useQuery(SHORE_STATUS_QUERY, {
    onCompleted: (data) => {
      defaultFilters[FilterKeys.shoreStatuses] = data.shoreStatuses || [];
    },
  });

  useQuery(DEVELOPMENT_STATUS_WITH_FILTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      countries: selectedCountries,
    },
    onCompleted: (data) => {
      // Remove selected development status filters that are not in the new list:
      const developmentStatus = data.developmentStatusWithFilter || [];
      const filteredDevelopmentStatus = advancedFilters[FilterKeys.developmentStatuses].filter(
        (status) => developmentStatus.find((s) => s.id === status.id && !s.isDisabled),
      );
      setAdvancedFilters({
        ...advancedFilters,
        [FilterKeys.developmentStatuses]: filteredDevelopmentStatus,
      });
      setDevelopmentStatuses(developmentStatus);
    },
  });

  const { data: developmentTypeData } = useQuery(DEVELOPMENT_TYPE_QUERY, {
    onCompleted: (data) => {
      defaultFilters[FilterKeys.developmentTypes] = data.developmentTypes || [];
    },
  });

  const setFilters = (newFilters) => {
    sessionStorage.setItem(VALUATIONS_CONTEXT, JSON.stringify(newFilters));
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
      event_action: ANALYTICS_EVENT_ACTIONS.filter,
      event_label: analytics(newFilters),
      userId: getUserId(),
      ...dimensions(newFilters, VALUATIONS_CONTEXT),
    });
    setFilterState(newFilters);
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
  };

  const searchParams = useGlobalSearchParameters();
  const gloSearchParams = getFulltextResultParametersFromSearch(searchParams);

  const { data: countriesData } = useQuery(COUNTRIES_QUERY);
  const { data: companiesData } = useQuery(COMPANIES_QUERY);
  const { data: assetData } = useQuery(ASSET_QUERY, {
    variables: {
      legacyId: gloSearchParams.legacyId,
      countryIsoCode: gloSearchParams.isoCode,
    },
  });

  useEffect(() => {
    const country = countriesData?.countries.find((c) => c.isoCode === gloSearchParams.isoCode);
    const company = companiesData?.companies.find((c) => c.name === gloSearchParams.name);

    if (country && gloSearchParams.entity === 'COUNTRY') {
      defaultFilters[FilterKeys.countries] = [{ ...country, isChosen: true }];
    }

    if (country && assetData && gloSearchParams.entity === 'ASSET') {
      const asset = {
        country: { ...assetData.asset.country, name: country.name },
        displayName: assetData.asset.displayName,
        id: assetData.asset.id,
        legacyId: assetData.asset.legacyId,
        name: assetData.asset.name,
        __typename: assetData.asset.__typename,
      };

      defaultFilters[FilterKeys.assets] = [asset];
    }

    if (company && gloSearchParams.entity === 'COMPANY') {
      defaultFilters[FilterKeys.companies] = [company];
    }
  }, [countriesData, companiesData, assetData]);

  useEffect(() => {
    // Set the selected country iso codes, which will trigger a refetch of the development status:
    const countries = advancedFilters[FilterKeys.countries];
    if (countries?.length !== selectedCountries.length) {
      setSelectedCountries(countries.map((c) => c.isoCode));
    }
  }, [advancedFilters]);

  useEffect(() => {
    if (selectedAsset && walkthrough) {
      const startDriver = () => {
        const driver = new Driver({
          allowClose: false,
          onReset: () => setWalkthrough(false),
        });
        valuationsDriver(driver);
      };
      if (
        document.getElementById('valuations-explore-more') !== null &&
        document.getElementById('valuations-country-downloads') !== null
      ) {
        startDriver();
      } else {
        const ids = ['valuations-explore-more', 'valuations-country-downloads'];
        const rendered = [false, false];

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((m) => {
            if (m.addedNodes.length > 0) {
              m.addedNodes.forEach((node) => {
                if (node.id === ids[0]) {
                  rendered[0] = true;
                } else if (node.id === ids[1]) {
                  rendered[1] = true;
                }
              });
            } else if (m.nextSibling && m.nextSibling.id === ids[0]) {
              rendered[0] = true;
            }
          });
          if (rendered[0] && rendered[1]) {
            observer.disconnect();
            startDriver();
          }
        });

        observer.observe(document.getElementById('valuations-side-panel'), {
          childList: true,
          subtree: false,
          attributes: false,
          characterData: false,
        });
      }
    }
  }, [selectedAsset]);

  const handleAdvancedFiltersClick = () => {
    setAdvancedFilters(filters);
    setFiltersModalOpen(true);
  };

  return (
    <Grid className={classes.root} container direction="column">
      <ErrorBoundary>
        <Filters
          filters={filters}
          setFilters={setFilters}
          clearFilters={clearFilters}
          setWatchList={(bool) => {
            setWatchList(bool);
            setFilterChanged(true);
          }}
          watchList={watchList}
          openAdvancedFilters={handleAdvancedFiltersClick}
        />
      </ErrorBoundary>
      <div className={classes.valuationsMain}>
        <div className={classes.valuationsContainer}>
          <ErrorBoundary>
            <AssetList
              filters={filters}
              filterChanged={filterChanged}
              setAsset={setAsset}
              setFilterChanged={setFilterChanged}
              selectedAsset={selectedAsset}
              watchList={watchList}
              updateWatchList={updateWatchList}
              setUpdateWatchList={setUpdateWatchList}
              shoreStatusData={shoreStatusData}
            />
          </ErrorBoundary>
        </div>
        <div className={classes.valuationsCenterContainer}>
          <ValuationReports
            asset={selectedAsset}
            context={VALUATIONS_CONTEXT}
            setUpdateWatchList={setUpdateWatchList}
            setSnack={setSnack}
          />
        </div>
        <div className={classes.valuationsContainer}>
          <Sidebar
            asset={selectedAsset}
            context={VALUATIONS_CONTEXT}
            watchList={watchList}
            setSnack={setSnack}
          />
          <Support />
        </div>
      </div>
      <AdvancedFilters
        title="Advanced"
        context={VALUATIONS_CONTEXT}
        googleAnalyticsTag={VALUATIONS_GOOGLE_ANALYTICS_TAG}
        open={filtersModalOpen}
        setOpen={setFiltersModalOpen}
        defaultFilters={advancedFilters}
        clearFilters={() => setAdvancedFilters(defaultFilters)}
        apply={setFilters}
        filterTypes={[ASSETS, COUNTRIES, COMPANY_FILTER_TYPE, BASINS, HYDROCARBONS]}
        checkListFilterTypes={[
          SHORE_STATUSES_CHECK_LIST,
          DEVELOPMENT_STATUSES_CHECK_LIST,
          DEVELOPMENT_TYPES_CHECK_LIST,
        ]}
        minMaxFilterTypes={[NPV_MIN_MAX, RESERVES_MIN_MAX, PRODUCTION_MIN_MAX, RESERVOIR_MIN_MAX]}
        checkListItems={{
          developmentStatuses,
          developmentTypes: developmentTypeData?.developmentType || [],
          shoreStatuses: shoreStatusData?.shoreStatus || [],
        }}
      />
    </Grid>
  );
};
