/*eslint no-unsafe-optional-chaining: 0*/

import {
  ACCESS_TOKEN_KEY,
  CLIENT_KEY,
  TOKEN_EXPIRY_KEY,
  TOKEN_TYPE_KEY,
  UID_KEY,
  USER_ID,
} from './constants';

export const clearUserCredentials = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(CLIENT_KEY);
  localStorage.removeItem(TOKEN_EXPIRY_KEY);
  localStorage.removeItem(UID_KEY);
  localStorage.removeItem(USER_ID);
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  return accessToken;
};

export const getClient = () => {
  const client = localStorage.getItem(CLIENT_KEY);

  return client;
};

export const getCsrfToken = () => {
  const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content');
  return csrfToken;
};

export const getTokenExpiry = () => {
  const tokenExpiry = localStorage.getItem(TOKEN_EXPIRY_KEY);

  return tokenExpiry;
};

export const getTokenType = () => {
  const tokenType = localStorage.getItem(TOKEN_TYPE_KEY);

  return tokenType;
};

export const getUid = () => {
  const uid = localStorage.getItem(UID_KEY);

  return uid;
};

export const getUserId = () => {
  const userId = localStorage.getItem(USER_ID);

  return userId;
};

export const isAuthenticated = () => {
  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  return authToken ? true : false;
};

export const persistUserCredentials = (accessToken, client, expiry, tokenType, uid, userId) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

  localStorage.setItem(CLIENT_KEY, client);

  localStorage.setItem(TOKEN_EXPIRY_KEY, expiry);

  localStorage.setItem(UID_KEY, uid);

  localStorage.setItem(USER_ID, userId);
};
