import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { downloadSummaries } from '../DataDownloadSummary/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    borderTop: `solid ${theme.palette.baseColors.iceBlue.c100} thin`,
    padding: '1rem',
  },
  items: {
    flexDirection: 'row',
  },
}));

const DownloadSummary = ({ downloadType }) => {
  const { text } = downloadSummaries[downloadType];
  return (
    <Grid item xs={4}>
      <Grid>{downloadType}</Grid>
      <hr />
      <Grid>{text}</Grid>
    </Grid>
  );
};

export default () => {
  const classes = useStyles();

  return (
    <ErrorBoundary>
      <Grid className={classes.root}>
        <Grid className={classes.items} container spacing={4}>
          {Object.keys(downloadSummaries).map((downloadType) => (
            <DownloadSummary key={downloadType} downloadType={downloadType} />
          ))}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};
