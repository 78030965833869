/* eslint-disable no-use-before-define */
// ignore the above rule in favor of grouping the types together
import { BOTTOM_SORT_DIRECTION } from '../../Graphs/constants';

// GRAPH TYPES
export const ABATEMENT_COSTS = {
  label: 'Levelized Cost of CO₂ Capture and Storage',
  value: 'Levelized Cost of CO2 Capture and Storage',
  isDisabled: ({ sizeType }) => sizeType === TOTAL_ABATEMENT_COST_SIZE_TYPE.value,
};
export const BIOGENIC_CO2_EMISSIONS = {
  label: 'Biogenic CO₂ Emissions',
  value: 'Biogenic CO2 Emissions',
  isDisabled: ({ stackType }) => stackType === EMISSION_STACK_TYPE.value,
};
export const CO2_CAPACITY = { label: 'CO₂ Capacity', value: 'CO2 Capacity' };
// const EMISSIONS_INTENSITY = { label: 'Emissions Intensity', value: 'Emissions Intensity' };
const FUTURE_CO2_INJECTION_VOLUMES = {
  label: 'Future CO₂ Injection Volumes',
  value: 'Future CO2 Injection Volumes',
  isDisabled: ({ stackType }) => stackType === STORAGE_COST_STACK_TYPE.value,
};
export const INDUSTRY_TYPE = { label: 'Industry Type', value: 'Industry Type' };
const LIFECYCLE_CO2_INJECTION_VOLUMES = {
  label: 'Life-cycle CO₂ Injection Volumes',
  value: 'Lifecycle CO2 Injection Volumes',
  isDisabled: ({ stackType }) => stackType === STORAGE_COST_STACK_TYPE.value,
};
const MAX_ANNUAL_INJECTION = { label: 'Max Annual Injection', value: 'Max Annual Injection' };
const PROCESS_CO2_EMISSIONS = {
  label: 'Process CO₂ Emissions',
  value: 'Process CO2 Emissions',
  isDisabled: ({ stackType }) => stackType === EMISSION_STACK_TYPE.value,
};
const PROJECT_COUNT = {
  label: 'Project Count',
  value: 'Project Count',
  isDisabled: ({ stackType }) => stackType === FACILITY_OWNER_GROSS_STACK_TYPE.value,
};
const STATIONARY_CO2_EMISSIONS = {
  label: 'Stationary CO₂ Emissions',
  value: 'Stationary CO2 Emissions',
  isDisabled: ({ stackType }) => stackType === EMISSION_STACK_TYPE.value,
};
export const STORAGE_ACREAGE = {
  label: 'Storage Acreage',
  value: 'Storage Acreage',
  isDisabled: ({ stackType }) => stackType === STORAGE_COST_STACK_TYPE.value,
};
const STORAGE_COST = {
  label: 'Levelized Cost of Storage',
  value: 'Levelized Cost of Storage',
  isDisabled: ({ aggregate, stackType }) => {
    if (aggregate === PARTICIPANT_GROSS_AGGREGATE.name) return true;
    switch (stackType) {
      case COUNTRY_STACK_TYPE.value:
      case PARTICIPANT_GROSS_STACK_TYPE.value:
      case PARTICIPANT_NET_STACK_TYPE.value:
      case PROJECT_STATUS_STACK_TYPE.value:
      case SHORE_STATUS_STACK_TYPE.value:
      case STORAGE_STACK_TYPE.value:
        return true;
      default:
        return false;
    }
  },
};
export const TOTAL_CO2_EMISSIONS = {
  label: 'Total CO₂ Emissions',
  value: 'Total CO2 Emissions',
  isDisabled: ({ sizeType }) => sizeType === TOTAL_CO2_EMISSIONS_SIZE_TYPE.value,
};

export const projectGraphTypes = [CO2_CAPACITY, PROJECT_COUNT];
export const costGraphTypes = [ABATEMENT_COSTS];
export const emissionGraphTypes = [
  TOTAL_CO2_EMISSIONS,
  STATIONARY_CO2_EMISSIONS,
  PROCESS_CO2_EMISSIONS,
  BIOGENIC_CO2_EMISSIONS,
];
export const mapChartTypes = [
  TOTAL_CO2_EMISSIONS,
  INDUSTRY_TYPE,
  ABATEMENT_COSTS,
  // TODO: This will be in version 2.0
  // https://trello.com/c/42jZrmvw/1918-ccus-emissions-intensity-map-metric
  // EMISSIONS_INTENSITY,
];
export const storageGraphTypes = [
  LIFECYCLE_CO2_INJECTION_VOLUMES,
  FUTURE_CO2_INJECTION_VOLUMES,
  // Removing this for now, do we want to bring this back for a different version?
  // https://trello.com/c/zbzjhEvu/2050-changes-to-storage-frame
  // MAX_ANNUAL_INJECTION,
  STORAGE_COST,
  STORAGE_ACREAGE,
];

export const graphTitles = {
  [ABATEMENT_COSTS.value]: '(LCOCCS@10% US$/t)',
  [CO2_CAPACITY.value]: '(MMtpa)',
  [FUTURE_CO2_INJECTION_VOLUMES.value]: '(MMt)',
  [LIFECYCLE_CO2_INJECTION_VOLUMES.value]: '(MMt)',
  [MAX_ANNUAL_INJECTION.value]: '(MMtpa)',
  [PROCESS_CO2_EMISSIONS.value]: '(MMtpa)',
  [STATIONARY_CO2_EMISSIONS.value]: '(MMtpa)',
  [STORAGE_ACREAGE.value]: '(km²)',
  [STORAGE_COST.value]: '@10% (US$/t)',
  [TOTAL_CO2_EMISSIONS.value]: '(MMtpa)',
};

// AGGREGATE TYPES
const BASIN_AGGREGATE = {
  name: 'Basin',
  aggregate: 'Basin',
};
export const COUNTRY_AGGREGATE = {
  name: 'Country',
  options: true,
  isDisabled: ({ stackType }) => stackType === COUNTRY_STACK_TYPE.value,
};
const DEVELOPMENT_STATUS_AGGREGATE = {
  name: 'Development Status',
  aggregate: 'Development Status',
  isDisabled: ({ stackType }) => stackType === DEVELOPMENT_STATUS_STACK_TYPE.value,
};
export const FACILITY_NAME_AGGREGATE = {
  name: 'Facility Name',
  options: true,
};
// This is essentially the same aggregate as the one below but with specific defaults
export const FACILITY_NAME_ABATEMENT_AGGREGATE = {
  ...FACILITY_NAME_AGGREGATE,
  defaultLimit: '100',
  defaultSortDirection: BOTTOM_SORT_DIRECTION,
};
const FACILITY_OWNER_AGGREGATE = {
  name: 'Facility Owner',
  options: true,
  isDisabled: ({ stackType }) =>
    stackType === FACILITY_OWNER_NET_STACK_TYPE.value ||
    stackType === FACILITY_OWNER_GROSS_STACK_TYPE.value,
};
const INDUSTRY_TYPE_AGGREGATE = {
  name: 'Industry Type',
  options: true,
  isDisabled: ({ stackType }) => stackType === INDUSTRY_STACK_TYPE.value,
};
export const OPERATIONAL_YEAR_AGGREGATE = {
  name: 'Operational Year',
  aggregate: 'Operational Year',
};
const PARTICIPANT_GROSS_AGGREGATE = {
  name: 'Participant Gross',
  options: true,
  isDisabled: ({ graphType, stackType }) =>
    graphType.value === STORAGE_COST.value ||
    stackType === PARTICIPANT_GROSS_STACK_TYPE.value ||
    stackType === PARTICIPANT_NET_STACK_TYPE.value,
};
const PARTICIPANT_NET_AGGREGATE = {
  name: 'Participant Net',
  options: true,
  isDisabled: ({ stackType }) =>
    stackType === PARTICIPANT_GROSS_STACK_TYPE.value ||
    stackType === PARTICIPANT_NET_STACK_TYPE.value,
};
const PROJECT_AGGREGATE = {
  name: 'Project Name',
  options: true,
  isDisabled: ({ stackType }) => stackType === PROJECT_STACK_TYPE.value,
};
const PROJECT_STATUS_AGGREGATE = {
  name: 'Project Status',
  aggregate: 'Project Status',
  isDisabled: ({ stackType }) => stackType === PROJECT_STATUS_STACK_TYPE.value,
};
const PROJECT_TYPE_AGGREGATE = {
  name: 'Project Type',
  aggregate: 'Project Type',
};
const REGION_AGGREGATE = {
  name: 'Region',
  options: true,
  isDisabled: ({ stackType }) => stackType === REGION_STACK_TYPE.value,
};
const SHORE_STATUS_AGGREGATE = {
  name: 'Shore Status',
  aggregate: 'Shore Status',
  isDisabled: ({ stackType }) => stackType === SHORE_STATUS_STACK_TYPE.value,
};
export const STORAGE_AGGREGATE = {
  name: 'Storage Project Name',
  options: true,
};
const STORAGE_TYPE_AGGREGATE = {
  name: 'Storage Type',
  aggregate: 'Storage Type',
  isDisabled: ({ stackType }) => stackType === STORAGE_STACK_TYPE.value,
};

export const costAggregateTypes = [FACILITY_NAME_ABATEMENT_AGGREGATE];
export const projectAggregateTypes = [
  OPERATIONAL_YEAR_AGGREGATE,
  COUNTRY_AGGREGATE,
  INDUSTRY_TYPE_AGGREGATE,
  PROJECT_AGGREGATE,
  FACILITY_OWNER_AGGREGATE,
  DEVELOPMENT_STATUS_AGGREGATE,
  PROJECT_TYPE_AGGREGATE,
];
export const emissionsAggregateTypes = [
  REGION_AGGREGATE,
  COUNTRY_AGGREGATE,
  INDUSTRY_TYPE_AGGREGATE,
  FACILITY_NAME_AGGREGATE,
  FACILITY_OWNER_AGGREGATE,
];
export const storageAggregateTypes = [
  REGION_AGGREGATE,
  COUNTRY_AGGREGATE,
  PARTICIPANT_GROSS_AGGREGATE,
  PARTICIPANT_NET_AGGREGATE,
  STORAGE_AGGREGATE,
  BASIN_AGGREGATE,
  PROJECT_STATUS_AGGREGATE,
  SHORE_STATUS_AGGREGATE,
  STORAGE_TYPE_AGGREGATE,
];

// SIZE TYPES
export const TOTAL_CO2_EMISSIONS_SIZE_TYPE = {
  label: 'Total CO₂ Emissions',
  value: 'Total CO2 Emissions',
  isDisabled: ({ graphType }) => graphType.value === TOTAL_CO2_EMISSIONS.value,
};
export const TOTAL_ABATEMENT_COST_SIZE_TYPE = {
  label: 'Total Abatement Cost',
  value: 'Total Abatement Cost',
  isDisabled: ({ graphType }) => graphType.value === ABATEMENT_COSTS.value,
};
export const mapSizeTypes = [TOTAL_CO2_EMISSIONS_SIZE_TYPE, TOTAL_ABATEMENT_COST_SIZE_TYPE];

// STACK TYPES
export const ABATEMENT_COST_STACK_TYPE = {
  label: 'Abatement Cost',
  value: 'Abatement Cost',
};
const COUNTRY_STACK_TYPE = {
  label: 'Country',
  value: 'Country',
  isDisabled: ({ aggregate, graphType }) =>
    aggregate === COUNTRY_AGGREGATE.name || graphType.value === STORAGE_COST.value,
};
const DEVELOPMENT_STACK_TYPE = {
  label: 'Development Likelihood',
  value: 'Development Likelihood',
};
export const DEVELOPMENT_STATUS_STACK_TYPE = {
  label: 'Development Status',
  value: 'Development Status',
  isDisabled: ({ aggregate }) => aggregate === DEVELOPMENT_STATUS_AGGREGATE.name,
};
export const EMISSION_STACK_TYPE = {
  label: 'Emissions Type (Process / Stationary)',
  value: 'Emissions Type',
  isDisabled: ({ graphType }) =>
    graphType.value === STATIONARY_CO2_EMISSIONS.value ||
    graphType.value === PROCESS_CO2_EMISSIONS.value ||
    graphType.value === BIOGENIC_CO2_EMISSIONS.value,
  tooltip: `Can only be selected when '${TOTAL_CO2_EMISSIONS.label}' metric is selected`,
};
const FACILITY_OWNER_STACK_TYPE = {
  label: 'Facility Owner',
  value: 'Facility Owner',
};
const FACILITY_OWNER_GROSS_STACK_TYPE = {
  label: 'Gross',
  value: 'Facility Owner Gross',
  isDisabled: ({ graphType }) => graphType.value !== CO2_CAPACITY.value,
};
const FACILITY_OWNER_NET_STACK_TYPE = {
  label: 'Net',
  value: 'Facility Owner Net',
};
const FACILITY_OWNER_OPTIONS_STACK_TYPE = {
  label: 'Facility Owner',
  value: 'Facility Owner',
  options: [FACILITY_OWNER_GROSS_STACK_TYPE, FACILITY_OWNER_NET_STACK_TYPE],
  isDisabled: ({ aggregate }) => aggregate === FACILITY_OWNER_AGGREGATE.name,
};
const INDUSTRY_STACK_TYPE = {
  label: 'Industry Type',
  value: 'Industry Type',
  isDisabled: ({ aggregate }) => aggregate === INDUSTRY_TYPE_AGGREGATE.name,
};
const PARTICIPANT_GROSS_STACK_TYPE = {
  label: 'Gross',
  value: 'Participant Gross',
  isDisabled: ({ graphType }) => graphType.value === STORAGE_COST.value,
};
const PARTICIPANT_NET_STACK_TYPE = {
  label: 'Net',
  value: 'Participant Net',
};
const PARTICIPANT_STACK_TYPE = {
  label: 'Participant',
  value: 'Participant',
  options: [PARTICIPANT_GROSS_STACK_TYPE, PARTICIPANT_NET_STACK_TYPE],
  isDisabled: ({ aggregate, graphType }) =>
    aggregate === PARTICIPANT_GROSS_AGGREGATE.name ||
    aggregate === PARTICIPANT_NET_AGGREGATE.name ||
    graphType.value === STORAGE_COST.value,
};
const PROJECT_STACK_TYPE = {
  label: 'Project Name',
  value: 'Project Name',
  isDisabled: ({ aggregate }) => aggregate === PROJECT_AGGREGATE.name,
};
export const PROJECT_STATUS_STACK_TYPE = {
  label: 'Project Status',
  value: 'Project Status',
  isDisabled: ({ aggregate, graphType }) =>
    aggregate === PROJECT_STATUS_AGGREGATE.name || graphType.value === STORAGE_COST.value,
};
const SHORE_STATUS_STACK_TYPE = {
  label: 'Shore Status',
  value: 'Shore Status',
  isDisabled: ({ aggregate, graphType }) =>
    aggregate === SHORE_STATUS_AGGREGATE.name || graphType.value === STORAGE_COST.value,
};
const STORAGE_COST_STACK_TYPE = {
  label: 'Levelized Cost of Storage',
  value: 'Levelized Cost of Storage',
  isDisabled: ({ graphType }) => graphType.value !== STORAGE_COST.value,
  tooltip: `Can only be selected when '${STORAGE_COST.label}' metric is selected`,
};
const STORAGE_STACK_TYPE = {
  label: 'Storage Type',
  value: 'Storage Type',
  isDisabled: ({ aggregate, graphType }) =>
    aggregate === STORAGE_TYPE_AGGREGATE.name || graphType.value === STORAGE_COST.value,
};
const REGION_STACK_TYPE = {
  label: 'Region',
  value: 'Region',
  isDisabled: ({ aggregate }) => aggregate === REGION_AGGREGATE.name,
};

export const projectStackTypes = [
  DEVELOPMENT_STATUS_STACK_TYPE,
  DEVELOPMENT_STACK_TYPE,
  REGION_STACK_TYPE,
  COUNTRY_STACK_TYPE,
  INDUSTRY_STACK_TYPE,
  PROJECT_STACK_TYPE,
  FACILITY_OWNER_OPTIONS_STACK_TYPE,
];
export const emissionsStackTypes = [
  EMISSION_STACK_TYPE,
  REGION_STACK_TYPE,
  COUNTRY_STACK_TYPE,
  INDUSTRY_STACK_TYPE,
  FACILITY_OWNER_STACK_TYPE,
];
export const storageStackTypes = [
  STORAGE_COST_STACK_TYPE,
  COUNTRY_STACK_TYPE,
  PARTICIPANT_STACK_TYPE,
  PROJECT_STATUS_STACK_TYPE,
  SHORE_STATUS_STACK_TYPE,
  STORAGE_STACK_TYPE,
];
