import { getDisplayedValue, getLabelUnit } from './SummaryItems/utils';

import Grid from '@material-ui/core/Grid';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dottedLabel: {
    ...theme.palette.baseColors.iceBlue.c100,
    alignSelf: 'center',
  },
  dottedSpace: {
    flex: '1 1 auto',
    borderBottom: 'dotted rgba(255, 255, 255, 0.1) 2px',
    height: '60%',
  },
  tableValue: {
    ...theme.palette.text,
    width: '100%',
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
    verticalAlign: 'top',
    opacity: 0.8,
    border: '1px solid #999',
    backgroundColor: '#CDD7DE',
    padding: '3px',
  },
  tableLabel: {
    minWidth: '130px',
    textAlign: 'right',
    verticalAlign: 'top',
    borderBottom: '1px solid #CDD7DE',
    padding: '3px',
  },
  tableSpace: {
    padding: '3px',
    border: 'none',
  },
  root: {
    margin: '5px 0 5px',
    ...theme.palette.text,
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
  },
  label: {
    color: '#507180',
  },
}));

export default ({
  variant,
  Icon,
  label,
  value,
  unit,
  labelUnitSeparator = ' ',
  labelValueSeparator = ' ',
  tooltip,
}) => {
  const classes = useStyles();
  const labelUnit = getLabelUnit(label, unit, labelUnitSeparator);
  const displayedValue = getDisplayedValue(label, value, Icon);

  switch (variant) {
    case 'dotted':
      return (
        <Grid container>
          <Typography className={classes.dottedLabel} variant="body2">
            {labelUnit}
          </Typography>
          <div className={classes.dottedSpace} />
          <Typography variant="subtitle1">{value}</Typography>
        </Grid>
      );
    case 'table':
      return (
        <TableRow style={{ margin: '3px' }}>
          {labelUnit && (
            <TableCell className={classes.tableLabel} align="right">
              {labelUnit}
              {labelValueSeparator}
            </TableCell>
          )}
          <TableCell className={classes.tableSpace} />
          <TableCell className={classes.tableValue}>{displayedValue}</TableCell>
        </TableRow>
      );
    default:
      return (
        // Tooltip is only displayed on hover if a tooltip is not empty
        <Tooltip title={tooltip || ''} placement="top-start">
          <Typography className={classes.root} variant="body1" component="p">
            {labelUnit && (
              <Typography className={classes.label} variant="body1" component="span">
                {labelUnit}
                {labelValueSeparator}
              </Typography>
            )}
            {displayedValue}
          </Typography>
        </Tooltip>
      );
  }
};
