import { makeStyles } from '@material-ui/core/styles';
import { MEDIA_QUERY_FIELD_ALWAYS_VISIBLE } from '../GlobalSearch.constant';

export const useSearchModalStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '50px',
    bottom: '50px',
    right: '20px',
    left: '80px',
    zIndex: 1030,
    backgroundColor: 'white',
    padding: '1rem',
    border: 'solid lightgrey 1px',
    borderTop: 'none',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',

    [theme.breakpoints.up(MEDIA_QUERY_FIELD_ALWAYS_VISIBLE)]: {
      left: 'unset',
      borderTop: 'solid lightgrey 1px',
      borderRadius: '5px',
      width: '85%',
    },

    [theme.breakpoints.up('xxl')]: {
      right: '250px',
      maxWidth: '1200px',
    },

    [theme.breakpoints.down('sm')]: {
      left: '55px',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '20px',
      right: '20px',
      height: '1px',
      backgroundColor: 'lightgrey',

      [theme.breakpoints.up(MEDIA_QUERY_FIELD_ALWAYS_VISIBLE)]: {
        display: 'none',
      },
    },
  },
  content: {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
  },
  pills: {
    display: 'flex',
    gap: '.3rem',
  },
  overlay: {
    position: 'fixed',
    backgroundColor: 'white',
    opacity: 0.7,
    top: '64px',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1030,
  },
  loading: {
    ...theme.palette.loading,
    backgroundColor: 'none',
  },
  results: {
    flexBasis: 'calc(50% - 2rem)',
    height: '100%',
    overflowY: 'auto',
  },
  lastSearch: {
    marginBottom: '1rem',
    paddingLeft: '.5rem',
  },
}));
