import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import { LEAD_ANALYST_QUERY } from '../operations';
import { ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG } from '../../../constants';
import { getUserId } from '../../../../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  assetListHeader: {
    ...theme.palette.secondaryBackground,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '30px',
    zIndex: 1,
    fontSize: theme.fontSizes.smaller2,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
    },
  },
  assetListHeaderText: {
    padding: '12px 20px',
  },
  email: {
    overflowWrap: 'break-word',
  },
  panelItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  panelItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0',
  },
  avatar: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactInfo: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontWeight: 'bold',
  },
  button: {
    width: '80%',
    ...theme.palette.button,
    fontSize: theme.fontSizes.smallest,
  },
  contactLead: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '35px',
  },
}));

export default ({ asset, setSnack }) => {
  const classes = useStyles();
  const dimensions = asset
    ? { asset: asset.name, country: asset.country.name, company: 'NULL' }
    : {};
  const isoCode = asset ? asset.country.isoCode : null;

  const { loading, data } = useQuery(LEAD_ANALYST_QUERY, {
    variables: {
      countryIsoCode: isoCode,
    },
  });

  const copyEmail = () => {
    const el = document.createElement('input');
    el.value = data.leadAnalyst.email;
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
    setSnack({
      open: true,
      severity: 'success',
      message: 'Copied URL to clipboard!',
    });
  };

  return (
    <div id="valuations-contact-analyst">
      <AppBar className={classes.assetListHeader} position="static">
        <PersonIcon />
        <Typography className={classes.assetListHeaderText} variant="caption">
          LEAD ANALYST
        </Typography>
      </AppBar>
      <div className={classes.panelItemsContainer}>
        <div className={classes.panelItem}>
          <div className={classes.avatar}>
            <AccountCircleIcon fontSize="large" />
          </div>
          {!loading && data.leadAnalyst !== undefined ? (
            <div className={classes.contactInfo}>
              <Typography display="inline" color="textPrimary" className={classes.name}>
                {data.leadAnalyst.name}
              </Typography>
              <Typography className={classes.email} color="textPrimary">
                {data.leadAnalyst.email}
              </Typography>
              {/* <Typography color="textPrimary">(555)-555-5555</Typography> */}
            </div>
          ) : null}
        </div>
        {!loading && data.leadAnalyst !== undefined ? (
          <div className={classes.contactLead}>
            <Tooltip title="Open in Email" aria-label="open email">
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  gtag(
                    'event',
                    ANALYTICS_EVENTS.event, {
                      'event_category': ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
                      'event_action': ANALYTICS_EVENT_ACTIONS.buttonClick,
                      'event_label': 'contact_analyst',
                      'userId': getUserId(),
                      ...dimensions,
                    },
                  ),
                  window.location.href = `mailto:${data.leadAnalyst.email}`;
                }}
              >
                Contact
              </Button>
            </Tooltip>
            <Tooltip title="Copy Email to Clipboard" aria-label="copy email">
              <IconButton onClick={copyEmail}>
                <FileCopyIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  );
};
